import React from 'react'
import { Button, Tooltip } from 'antd'
import Icon from '@mdi/react'

export default function ButtonForm({ onClick, icon, label }) {
  return (
    <>
      <Tooltip title={label}>
        <Button
          onClick={() => onClick()}
          shape="circle"
          icon={<Icon path={icon} size={1} style={{ verticalAlign: 'middle' }} />}
        />
      </Tooltip>
    </>
  )
}
