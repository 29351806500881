import FormBuilder from 'components/FormBuilder'
import Moment, { fmt } from 'components/Moment'
import { Translate } from 'lang'

function form(setActionsDialog, controller, plot) {
  return [
    {
      type: 'alert',
      message:
        'Certifique-se de que você está selecionando o piquete correto, caso contrário os sistema ira reenviar notificações frequentemente.',
      alertType: 'warning',
    },
    {
      //TODO
      type: 'select',
      name: 'lot_id',
      required: true,
      label: Translate({
        messageKey: 'move_batch_to_lot',
        items: {
          piquete: plot,
        },
      }),
      placeholder: Translate({
        messageKey: 'select',
        gender: 'male',
        items: {
          item: Translate({ messageKey: 'batch', howMany: 'one' }),
        },
      }),
      controller: controller.list,
      filters: {
        has_lot: true,
      },
      manipulateValues: values => values?.map(v => ({ value: v.id, text: v.name })),
    },
    {
      type: 'footer',
      position: 'end',
      onClose: () => setActionsDialog({ visible: false }),
      buttons: [
        {
          type: 'button-cancel',
          label: Translate({ messageKey: 'cancel' }),
        },
        {
          type: 'button-confirm',
          label: Translate({ messageKey: 'confirm' }),
        },
      ],
    },
  ]
}

export default function MovementBatchForm(props) {
  const { controller, notification_id, setActionsDialog, refetch, plot } = props

  return (
    <FormBuilder
      name={'notification-movement-lots-form'}
      controller={controller.batch}
      data={form(setActionsDialog, controller, plot)}
      method={'PUT'}
      manipulateValues={values => {
        values.by_notification = true
        values.notification_id = notification_id
        values.date_moved = Moment().format(fmt.api)
        return values
      }}
      afterOk={() => {
        setActionsDialog({ visible: false })
        refetch()
      }}
    />
  )
}
