import { Form, Col, Select, Row } from 'antd'
import { Translate } from 'lang'
import { useEffect, useState } from 'react'
import useRequest from 'store/hooks/useRequest'

const ApiUrl = process.env.REACT_APP_CSC_API_URL
const ApiKey = process.env.REACT_APP_CSC_API_KEY

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}
const defaultPlaceholder = label =>
  Translate({
    messageKey: 'inform',
    gender: 'male',
    items: {
      item: Translate({ messageKey: label, lower: true }),
    },
  })

function handleFilter(input, option) {
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export const LocalizationForm = ({ value, form, initialValues }) => {
  const { name, required, cols = { xs: 24 } } = value
  const [url, setUrl] = useState()
  const [response, setResponse] = useState()
  const [data, setData] = useState(null)
  const [getInitial, setGetInitial] = useState(!!initialValues)

  const [ciso, setCiso] = useState()

  const { data: requestData } = useRequest({
    controller: url?.endpoint,
    options: {
      headers: {
        'X-CSCAPI-KEY': ApiKey,
      },
    },
  })

  useEffect(() => {
    setData(requestData)
  }, [requestData])

  useEffect(() => {
    if (!url) {
      setUrl({
        type: 'countries',
        endpoint: `${ApiUrl}/countries/`,
      })
    }
  }, [])

  useEffect(() => {
    if (url?.type && data) {
      setResponse(prevState => ({
        ...prevState,
        [url.type]: data,
      }))
      setData(null)
    }
  }, [url, data])

  function handleOnChange(type, value) {
    value = value.split('-')[0]
    const endpoint =
      type === 'state'
        ? `${ApiUrl}/countries/${value}/states`
        : `${ApiUrl}/countries/${ciso}/states/${value}/cities`
    setUrl({
      type,
      endpoint,
    })
  }

  useEffect(() => {
    if (initialValues && getInitial) {
      if (response?.countries) {
        /* COUNTRY > STATE */
        setCiso(initialValues.country?.split('-')[0])
        handleOnChange('state', initialValues.country)
      }
      if (response?.state) {
        /* STATE > CITY */
        handleOnChange('city', initialValues.state)
        setGetInitial(false)
      }
    }
    return () => {}
  }, [initialValues, response])

  const selects = [
    {
      name: [name, 'country'],
      label: defaultPlaceholder('country'),
      options: response?.countries,
      onChange: value => {
        setResponse(prevState => ({
          countries: prevState.countries,
          state: null,
        }))
        setCiso(value.split('-')[0])
        handleOnChange('state', value)
        form.setFieldsValue({ [name]: { city: null, state: null } })
      },
    },
    {
      name: [name, 'state'],
      label: defaultPlaceholder('state'),
      options: response?.state,
      onChange: value => {
        setResponse(prevState => ({
          countries: prevState.countries,
          state: prevState.state,
          city: null,
        }))
        handleOnChange('city', value)
        form.setFieldsValue({ [name]: { city: null } })
      },
    },
    {
      name: [name, 'city'],
      label: defaultPlaceholder('city'),
      options: response?.city,
      onChange: null,
    },
  ]
  return (
    <Col {...cols}>
      <Row gutter={16}>
        {selects.map(s => {
          return (
            <Col xs={8} key={s.name}>
              <Form.Item
                name={s.name}
                style={style.form}
                label={s.label}
                hasFeedback
                rules={[{ required }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) => handleFilter(input, option)}
                  placeholder={s.label}
                  disabled={!s.options}
                  loading={s.options === null}
                  onChange={s.onChange}
                >
                  {s.options?.map(o => (
                    <Select.Option key={`key-${o.name}`} value={`${o.iso2 ?? o.id}-${o.name}`}>
                      {o.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )
        })}
      </Row>
    </Col>
  )
}
