import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Menu, Tooltip } from 'antd'
import { Colors } from 'constants/Colors'
import { Translate } from 'lang'
import { mdiBell } from '@mdi/js'
import Icon from '@mdi/react'
import { endpoint } from 'store/services/endpoints'
import { getCurrentFarm } from 'redux/reducers/Farm'
import { useSelector } from 'react-redux'
import useAxios from 'store/hooks/useAxios'
import DrawerNotification from 'components/Notifications/Drawer/NavNotification'
import { isEqual } from 'lodash'

const NavNotification = () => {
  const { farm, property } = useSelector(getCurrentFarm, isEqual)
  const [open, setOpen] = useState(false)

  const controller = useMemo(
    () => endpoint.farm.notifications.unread({ farm, property }),
    [farm, property],
  )

  const { data, loading, refetch } = useAxios({ controller })

  const showDrawer = () => {
    setOpen(true)
  }

  useEffect(() => {
    // Adiciona o listener ao localStorage
    window.addEventListener('new-alert', refetch)

    // Retorna uma função de limpeza para remover o listener quando o componente for desmontado
    return () => {
      window.removeEventListener('new-alert', refetch)
    }
  }, [])

  /**
   * Cria um intervalo para carregar mais dados
   */
  useEffect(() => {
    const timer = setInterval(() => refetch(), 1000 * 60)
    return () => clearInterval(timer)
  }, [])

  const items = [
    {
      key: 'alert',
      label: (
        <Tooltip title={Translate({ messageKey: 'notifications' })}>
          <Badge count={data} offset={[5, 25]} color={Colors.primary}>
            <Icon path={mdiBell} size={1} style={{ verticalAlign: 'middle' }} />
          </Badge>
        </Tooltip>
      ),
      disabled: loading,
    },
  ]

  return (
    <>
      <Menu
        className="d-flex align-item-center"
        mode="horizontal"
        onClick={showDrawer}
        selectedKeys={null}
        items={items}
      />
      <DrawerNotification open={open} setOpen={setOpen} refetch={refetch} />
    </>
  )
}
export default NavNotification
