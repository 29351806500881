import { Form, Input, Col } from 'antd'
import { validateField } from 'components/FormBuilder/assets/validate'
import { Translate } from 'lang'
import toLower from 'lodash/toLower'
import { useEffect, useState } from 'react'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

export const EmailForm = ({ value, allValues }) => {
  const { name, label, disabled, placeholder, cols = { xs: 24 }, condition = () => true } = value

  const [enabled, setEnabled] = useState(true)
  const [required, setRequired] = useState(false)

  useEffect(() => {
    const isEnabled = !disabled && condition(allValues)
    const isRequired = validateField(allValues, value.required)
    setEnabled(isEnabled)
    setRequired(isRequired && isEnabled)
  }, [allValues, setEnabled, setRequired])

  const defaultPlaceholder = Translate({
    messageKey: 'inform',
    gender: 'male',
    items: {
      item: toLower(label),
    },
  })

  return (
    <Col {...cols}>
      <Form.Item
        name={name}
        label={label}
        style={style.form}
        hasFeedback
        rules={[
          {
            type: 'email',
            message: Translate({ messageKey: 'email_invalid' }),
          },
          {
            required,
            message: Translate({ messageKey: 'email_required' }),
          },
        ]}
      >
        <Input placeholder={placeholder ?? defaultPlaceholder} disabled={!enabled} />
      </Form.Item>
    </Col>
  )
}
