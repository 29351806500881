import React from 'react'
import { SIDE_NAV_COLLAPSED_WIDTH, LOGO, LOGO_V } from 'constants/ThemeConstant'
import { APP_NAME } from 'configs/AppConfig'
import { connect, useSelector } from 'react-redux'
import utils from 'utils'
import { Grid } from 'antd'
import { getIsAdmin } from 'redux/reducers/App'
import { isEqual } from 'lodash'

const { useBreakpoint } = Grid

const getLogoWidthGutter = (isMobile, isAdmin) => {
  return !isAdmin ? 'auto' : isMobile ? 0 : `${SIDE_NAV_COLLAPSED_WIDTH}px`
}

const getLogoDisplay = isMobile => (isMobile ? 'd-none' : 'logo')

export const Logo = ({ navCollapsed }) => {
  const isAdmin = useSelector(getIsAdmin, isEqual)
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')

  return (
    <div
      className={getLogoDisplay(isMobile)}
      style={{ width: `${getLogoWidthGutter(isMobile, isAdmin)}` }}
    >
      <img
        style={{ marginLeft: 5.5 }}
        src={isAdmin || !navCollapsed ? LOGO_V : LOGO}
        alt={`${APP_NAME} logo`}
      />
    </div>
  )
}

export const LogoInsideMobile = () => {
  return (
    <div className={'logo'}>
      <img style={{ marginLeft: 5.5 }} src={LOGO_V} alt={`${APP_NAME} logo`} />
    </div>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme
  return { navCollapsed }
}

export default connect(mapStateToProps)(Logo)
