import { Form, Col, Button, Row } from 'antd'

const style = {
  form: {
    marginTop: 10,
  },
  row: {
    gap: 10,
  },
}

export const FooterForm = ({ value }) => {
  const { buttons, position, cols = { xs: 24 }, onClose, onSubmit } = value

  function getButton(button) {
    switch (button.type) {
      case 'button-confirm': {
        return (
          <Button type={'primary'} htmlType="submit" block>
            {button.label}
          </Button>
        )
      }
      case 'button-submit': {
        return (
          <Button type={'primary'} onClick={onSubmit}>
            {button.label}
          </Button>
        )
      }
      default: {
        return <Button onClick={onClose}>{button.label}</Button>
      }
    }
  }

  return (
    <Col {...cols}>
      <Row justify={position} style={style.row}>
        {buttons.map(button => {
          return (
            <Form.Item style={style.form} key={`button-${button.label}`}>
              {getButton(button)}
            </Form.Item>
          )
        })}
      </Row>
    </Col>
  )
}
