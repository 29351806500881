import { Col, Row } from 'antd'
import FormatCell from 'components/FormatCell'

const style = {
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '5%',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px',
    width: '100%',
  },
}

export const ItemList = ({ value }) => {
  return (
    <div style={style.form}>
      <Row style={style.row} gutter={[10, 10]}>
        {value.itens.map((item, index) => (
          <Col key={index} span={11} style={{ display: 'flex', justifyContent: 'center' }}>
            <FormatCell
              type={item.subType}
              label={item.label}
              value={item.value}
              subType={item.iconType}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}
