import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Grid } from 'antd'
import Icon from '@mdi/react'
import { connect } from 'react-redux'
import { NAV_TYPE_SIDE, PRIMARY_COLOR } from 'constants/ThemeConstant'
import utils from 'utils'
import { onMobileNavToggle } from 'redux/actions/Theme'
import { mdiChevronRight } from '@mdi/js'
import styled from 'styled-components'
import MenuItems from './MenuItems'

const style = {
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItemTitle: {
    marginLeft: 5,
  },
  icon: {
    marginBottom: -5,
  },
  topNav: {
    background: 'transparent',
  },
  sideNav: {
    height: '100%',
    paddingTop: 10,
    borderRight: 0,
    overflowX: 'hidden',
    backgroundColor: PRIMARY_COLOR,
    color: 'white',
  },
}

const StyledMenu = styled(Menu)`
  .ant-menu-item,
  .ant-menu-submenu-arrow,
  .ant-menu-submenu,
  .ant-menu-item-group-title {
    color: white;
  }
  .ant-menu-item-selected {
    color: white;
    background-color: rgb(247 232 62 / 20%) !important;
  }
  .ant-menu-item:hover {
    background-color: rgb(247 232 62 / 20%) !important;
  }
  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover > * {
    color: white !important;
  }
  .ant-menu-submenu-title:not(:hover) > * {
    color: white !important;
  }
  .ant-menu-submenu-vertical.ant-menu-submenu-selected {
    background-color: rgb(247 232 62 / 20%) !important;
  }
`

const { useBreakpoint } = Grid

const getItem = (title, icon, path) => {
  return title ? (
    <div style={style.menuItem}>
      {icon ? <Icon path={icon} size={0.8} /> : null}
      <span style={style.menuItemTitle}>{title}</span>
      {path ? <Link to={path} /> : null}
    </div>
  ) : (
    <>
      {icon ? <Icon path={icon} size={0.8} style={style.icon} /> : null}
      {path ? <Link to={path} /> : null}
    </>
  )
}
const TopNavIcon = icon => (
  <div style={{ float: 'left', marginTop: '2.5px' }}>
    <Icon path={icon ?? mdiChevronRight} size={1} />
  </div>
)
const SideNavContent = props => {
  const location = useLocation()
  const { theme, onMobileNavToggle, MENUS } = props
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }

  const transformMenusToArray = (menus, isFirst) => {
    const transformedMenus = []

    menus.forEach(menu => {
      const hasChildren = menu.submenu && menu.submenu.length > 0

      const transformedMenu = {
        label: menu.path ? (
          <>
            <span>{menu.title}</span>
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          </>
        ) : (
          menu.title
        ),
        key: menu.key,
        icon: menu.icon ? TopNavIcon(menu.icon) : null,
        type: isFirst ? (menu.title[0] === 'Home' ? null : 'group') : null,
        disabled: !menu.enabled && !hasChildren,
      }

      transformedMenus.push(transformedMenu)

      if (hasChildren) {
        transformedMenu.children = transformMenusToArray(menu.submenu, false)
      }
    })

    return transformedMenus
  }

  const transformedMenusArray = transformMenusToArray(MENUS, true)

  return (
    <StyledMenu
      theme={theme}
      mode="inline"
      style={style.sideNav}
      defaultSelectedKeys={'home'}
      selectedKeys={[location.pathname]}
      items={transformedMenusArray}
    />
  )
}

const TopNavContent = ({ MENUS }) => {
  const transformMenusToArray = menus => {
    const transformedMenus = []

    menus.forEach(menu => {
      const hasChildren = menu.submenu && menu.submenu.length > 0

      const transformedMenu = {
        label: getItem(menu.title, menu.icon, menu.path),
        key: menu.key,
      }

      transformedMenus.push(transformedMenu)

      if (hasChildren) {
        transformedMenu.children = transformMenusToArray(menu.submenu)
      }
    })
    return transformedMenus
  }

  const transformedMenusArray = transformMenusToArray(MENUS)
  return <Menu mode="horizontal" style={style.topNav} items={transformedMenusArray} />
}

const MenuContent = props => {
  const MENUS = MenuItems()

  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} MENUS={MENUS} />
  ) : (
    <TopNavContent {...props} MENUS={MENUS} />
  )
}

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme } = theme
  return { sideNavTheme }
}

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent)
