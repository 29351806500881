import Icon from '@mdi/react'
import selectIcon from 'utils/selectIcon'

const styles = {
  iconPlusThick: isSick => ({
    color: '#fff',
    backgroundColor: isSick ? '#D64A4A' : '#86BC6F',
    borderRadius: 60,
  }),
}

const HealthStatus = ({ value }) => {
  if (!value) return '-'
  const keys = Object.keys(value)
  return (
    <span style={{ alignItems: 'center', display: 'flex', gap: 10 }}>
      {keys?.map(item => {
        return (
          <span style={{ alignItems: 'center', display: 'flex' }}>
            {item === 'is_pregnant' ? (
              <>
                {value[item] && (
                  <img
                    src={selectIcon(item)}
                    alt="custom icon"
                    style={{
                      ...styles.icon,
                    }}
                  />
                )}
              </>
            ) : (
              <Icon path={selectIcon(item)} size={1} style={styles.iconPlusThick(value[item])} />
            )}
          </span>
        )
      })}
    </span>
  )
}
export default HealthStatus
