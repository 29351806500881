import React from 'react'

import Icon from '@mdi/react'
import { Link } from 'react-router-dom'
import { Colors } from 'constants/Colors'
import selectIcon from 'utils/selectIcon'

const styles = {
  icon: {
    padding: 5,
    border: '1px solid',
    borderRadius: 200,
    verticalAlign: 'middle',
  },
}

const Wrapper = ({ permission, path, children }) => {
  return permission ? <Link to={path}>{children}</Link> : children
}

const Avatar = ({
  subtype,
  title,
  subtitle,
  path,
  has_device,
  isDeleted,
  pathNotDevice,
  iconStyle,
}) => {
  const iconStyleDevice = {
    borderColor: Colors.primary,
    color: Colors.primary,
  }
  const defaultStyle = { borderColor: Colors.gray_light, color: Colors.gray_light }

  if (path || pathNotDevice) {
    return (
      <Wrapper path={path ?? pathNotDevice} permission>
        <div style={{ display: 'flex', gap: 10 }}>
          <Icon
            path={selectIcon(subtype)}
            size={1.8}
            style={{
              ...styles.icon,
              ...(path
                ? iconStyleDevice
                : { borderColor: Colors.gray_light, color: Colors.gray_light }),
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <span
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: !path ? '#575D60' : null,
                ...(isDeleted && { textDecorationLine: 'line-through' }),
              }}
            >
              {title}
            </span>
            {subtitle && (
              <p
                style={{
                  margin: 0,
                  fontSize: 12,
                }}
              >
                {subtitle}
              </p>
            )}
          </div>
        </div>
      </Wrapper>
    )
  }

  return (
    <div style={{ display: 'flex', gap: 10 }}>
      {subtype === 'plot_alert' ? (
        <div
          style={{
            ...styles.icon,
            borderColor: Colors.primary,
            backgroundColor: Colors.primary,
            maxWidth: '44px',
            maxHeight: '44px',
          }}
        >
          <img
            src={selectIcon(subtype)}
            alt="custom icon"
            style={{ width: '33px', height: '33px' }}
          />
        </div>
      ) : (
        <Icon
          path={selectIcon(subtype)}
          size={1.8}
          style={{
            ...styles.icon,
            ...(has_device ? iconStyleDevice : iconStyle ?? defaultStyle),
          }}
        />
      )}

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <span
          style={{
            fontSize: 16,
            fontWeight: 700,
            color: subtype === 'bulb' ? Colors.primary : null,
            ...(isDeleted && { textDecorationLine: 'line-through' }),
          }}
        >
          {title}
        </span>
        {subtitle && <p style={{ margin: 0 }}>{subtitle}</p>}
      </div>
    </div>
  )
}

export default Avatar
