import Icon from '@mdi/react'
import { mdiLock, mdiLockOpen } from '@mdi/js'
import styled from 'styled-components'

const Wrapper = styled.div`
  border-radius: 8px;
  margin: 10px 60px 10px 10px;
  height: 40px;
  width: 40px;
  background: white;
  cursor: pointer;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  &:hover {
    color: black;
  }
`

export default function LockMap({ lock, setPolygonEdit = () => {} }) {
  return (
    <Wrapper onClick={() => setPolygonEdit(prevState => !prevState)}>
      <Icon path={lock ? mdiLockOpen : mdiLock} size={1} style={{ margin: 'auto' }} />
    </Wrapper>
  )
}
