import React from 'react'
import { WarningOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import AlertColor from 'components/Notifications/Manipulate/AlertColor'
import Icon from '@mdi/react'
import { mdiPlusThick, mdiWifi, mdiWifiOff } from '@mdi/js'
import { MdOutlineDoNotStep } from 'react-icons/md'
import PregnantStatusWhite from 'assets/svgs/pregnancy-status-white.svg'

export default function AlertIcons({ type = 'warning', size = 'large' }) {
  const color = AlertColor(type)

  /**
   * FontSize = 40, icone exibido no drawer
   * FontSize = 30, icone exibido no notification(foregroundMessage),
   */
  const font = { large: 40, small: 30 }
  const fontSize = font[size]

  const icon = {
    plot_alert: <WarningOutlined style={{ fontSize, color }} />,
    internal_alert: <WarningOutlined style={{ fontSize, color }} />,
    external_alert: <WarningOutlined style={{ fontSize, color }} />,
    warning: <WarningOutlined style={{ fontSize, color }} />,
    error: <CloseCircleOutlined style={{ fontSize, color }} />,
    info: <InfoCircleOutlined style={{ fontSize, color }} />,
    connection_alert: <Icon path={mdiWifi} size={1.5} style={{ color }} />,
    disconnect_alert: <Icon path={mdiWifiOff} size={1.5} style={{ color }} />,
    zero_steps_alert: <MdOutlineDoNotStep style={{ fontSize, color }} />,
    possible_illness: (
      <Icon
        path={mdiPlusThick}
        size={1.5}
        style={{
          color: '#fff',
          backgroundColor: '#D64A4A',
          borderRadius: 60,
        }}
      />
    ),
    possible_birth: (
      <img
        src={PregnantStatusWhite}
        alt=""
        style={{
          ...styles.possible_birth_icon,
          width: fontSize,
          height: fontSize,
          borderColor: color,
          backgroundColor: color,
        }}
      />
    ),
  }
  return icon[type]
}

const styles = {
  possible_birth_icon: {
    padding: 5,

    border: '1px solid',
    borderRadius: 200,
    verticalAlign: 'middle',
  },
}
