import LocalizedStrings from 'localized-strings'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import trimStart from 'lodash/trimStart'
import trimEnd from 'lodash/trimEnd'
import { Typography } from 'antd'
import CurrentLanguage from 'utils/currentLanguage'
import { pt_BR } from './locales/pt'
import { en_US } from './locales/en'

const { Text } = Typography

const strings = new LocalizedStrings(
  {
    'en-US': en_US,
    'pt-BR': pt_BR,
  },
  {
    logsEnabled: false,
    customLanguageInterface: () => CurrentLanguage.get(),
  },
)

function manipulateKey(key) {
  let messageKey = key.split(':')[0]
  messageKey = messageKey.replace(/ /g, '_')
  return messageKey
}

function getString(messageKey, howMany = null, gender = null) {
  let word = strings[manipulateKey(messageKey)]
  if (!word) {
    return messageKey
  }
  if (typeof word === 'object') {
    /**
     * Pega valor referente ao howMany, se a chave possuir
     * em alguns casos o en não possui pois não é necessário
     */
    if (howMany && 'howMany' in word) {
      word = word.howMany[howMany === 'one' ? 'one' : 'many']
    }
    /**
     * Pega valor referente ao gender, se a chave possuir
     * em alguns casos o en não possui pois não é necessário
     */
    if (gender && 'gender' in word) {
      word = word.gender[gender]
    }
  }
  /**
   * Testa novamente se conseguiu traduzir, caso não ou remota a palavra ou
   * retorna a primeira opção de um howmany ou gender
   * caso tenha esquecido de adicionar, evita quebrar o front
   */
  if (typeof word === 'string') {
    return word
  }
  /**
   * Remonta word caso em uma das linguagens não tenha o howMany, gender, etc
   * */
  const keyList = Object.keys(word)
  if (!('howMany' in word || 'gender' in word)) {
    return keyList.map(value => (!!Number(value) || value === '0' ? word[value] : null)).join('')
    /**
     * Retorna a primeira opção contida na tradução, evita quebrar o front
     */
  }
  if ('howMany' in word) {
    return word.howMany.one
  }
  if ('gender' in word) {
    return word.gender.male
  }

  return messageKey
}

function manipulatePhrase(phrase, lower, upper, trim) {
  if (trim) {
    phrase = trimStart(phrase)
    phrase = trimEnd(phrase)
  }
  if (lower) {
    phrase = toLower(phrase)
  }
  if (upper) {
    phrase = toUpper(phrase)
  }
  if (!lower && !upper) {
    phrase = upperFirst(phrase)
  }
  return phrase
}

function splitPhrase(phrase) {
  const partes = phrase.split('**')
  const resultado = []

  for (let i = 0; i < partes.length; i++) {
    if (i % 2 === 0) {
      resultado.push(partes[i])
    } else {
      resultado.push(`*${partes[i]}*`)
    }
  }

  return resultado.filter(Boolean)
}

/**
 * Utilizado para o bold das traduções
 * Pode se adicionar no proprio arquivo ou no item
 * Basta adicionar 2 asteriscos em volta da palavra/frase
 * */
function checkBold(phrase) {
  phrase = splitPhrase(phrase)
  return phrase.map(word => {
    if (word.indexOf('*') === 0) {
      const wordWithoutAsterisk = word.substring(1, word.length - 1)
      return (
        <Text key={word} strong>
          {wordWithoutAsterisk}
        </Text>
      )
    }
    return word
  })
}

function insertCapsule(message, capsule = false) {
  switch (capsule) {
    case '()':
      return `(${message})`
    case '[]':
      return `[${message}]`
    case '{}':
      return `{${message}}`

    default:
      return message
  }
}

function TranslateArray(props) {
  const {
    messageKey,
    lower,
    upper,
    params,
    separator = ': ',
    translate = true,
    capsule = false,
    ...rest
  } = props

  const formattedMessages = []
  for (let i = 0; i < messageKey.length; i++) {
    if (translate[i] === false) {
      if (capsule[i]) {
        formattedMessages.push(insertCapsule(messageKey[i], capsule[i]))
      } else {
        formattedMessages.push(messageKey[i])
      }
    } else {
      const message = Translate({
        messageKey: messageKey[i],
        upper: upper ? upper[i] : null,
        lower: lower ? lower[i] : null,
        ...(params ? { params: params[i] } : null),
        ...rest,
      })
      if (capsule[i]) {
        formattedMessages.push(insertCapsule(message, capsule[i]))
      } else {
        formattedMessages.push(message)
      }
    }
  }

  return formattedMessages.join(separator)
}

export const Translate = props => {
  const {
    messageKey,
    items = {},
    howMany,
    gender,
    lower,
    upper,
    trim = true,
    prefix,
    suffix,
  } = props
  if (!messageKey) return null

  if (Array.isArray(messageKey)) {
    return TranslateArray(props)
  }
  //Force messageKey to be string
  const message = String(messageKey)
  const wordsBlackList = ['properties', 'devices']

  let phrase = getString(message, howMany, gender)
  if (!wordsBlackList.includes(message)) {
    phrase = strings.formatString(phrase, items)
  }
  phrase = manipulatePhrase(phrase, lower, upper, trim)

  if (prefix) {
    phrase = prefix + phrase
  }
  if (suffix) {
    phrase += suffix
  }

  return checkBold(phrase)
}
