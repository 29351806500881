import { Polygon } from '@react-google-maps/api'
import { Colors } from 'constants/Colors'
import { uniqueId } from 'lodash'

export const DrawingDefaultOptions = {
  drawingControlOptions: {
    drawingModes: ['polygon'],
  },
  polygonOptions: {
    editable: false,
  },
}
export const PolygonDefaultOptions = {
  fillOpacity: 0.2,
  strokeOpacity: 1,
  strokeWeight: 2,
  strokeColor: '#fff',
  clickable: true,
}
export const PolylineDefaultOptions = {
  strokeOpacity: 0,
  clickable: false,
  icons: [
    {
      icon: {
        strokeColor: Colors.waiting,
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 2,
      },
      zIndex: 1,
      offset: '1',
      repeat: '10px',
    },
  ],
}

export const PolygonOptions = (PolygonDefaultOptions, color) => {
  PolygonDefaultOptions.fillColor = color
  return PolygonDefaultOptions
}

export const DrawingOptions = (DrawingDefaultOptions, color) => {
  DrawingDefaultOptions.polygonOptions.strokeColor = color
  return DrawingDefaultOptions
}

export const calculateCenter = points => {
  if (!points) return null
  let longitudes = points.map(i => Number(i.lng)).sort()
  let latitudes = points.map(i => Number(i.lat)).sort()

  let lowLat = latitudes[0]
  let highLat = latitudes[latitudes.length - 1]

  let lowLng = longitudes[0]
  let highLng = longitudes[longitudes.length - 1]

  let centerX = lowLat + (highLat - lowLat) / 2
  let centerY = lowLng + (highLng - lowLng) / 2

  return { lat: centerX, lng: centerY }
}

export const pathStringToInt = path => {
  return path?.map(({ lat, lng, ...rest }) => ({
    lat: Number(lat),
    lng: Number(lng),
    ...rest,
  }))
}

export const getCenterArea = path => {
  return path[0]?.latitude
    ? { lat: Number(path[0]?.latitude), lng: Number(path[0]?.longitude) }
    : null
}

export const getPaths = polygon => {
  let paths = []
  const polyArray = polygon.getPath().getArray()

  polyArray.forEach(path => paths.push({ lat: path.lat(), lng: path.lng() }))
  return paths
}

export const Polygons = ({ lots, onLoad = () => {}, options, onDblClick }) => {
  if (!lots) return null
  return lots?.map(lot => {
    const { name, coordinates } = lot
    return (
      <Polygon
        key={uniqueId(name)}
        onLoad={polygon => onLoad(polygon, lot)}
        options={options(lot)}
        path={pathStringToInt(coordinates)}
        onDblClick={onDblClick ?? onDblClick}
      />
    )
  })
}
