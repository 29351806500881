export const validateField = (allValues, required) => {
  let isRequired = false
  if (required) {
    if (typeof required === 'function') {
      isRequired = required(allValues)
    } else {
      isRequired = required
    }
  }
  return isRequired
}
