import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import StoreProvider from 'store/provider'
import { THEME } from 'constants/ThemeConstant'
import Views from './views/routes'
import { store, persistor } from './redux/store'

const themes = {
  dark: `/css/dark-theme.css`,
  light: `/css/light-theme.css`,
}

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME}>
            <Router>
              <StoreProvider>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </StoreProvider>
            </Router>
          </ThemeSwitcherProvider>
        </PersistGate>
      </Provider>
    </div>
  )
}

export default App
