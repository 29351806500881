import Icon from '@mdi/react'
import { Col, Row, Typography } from 'antd'
import selectIcon from 'utils/selectIcon'

const styles = {
  icon: {
    padding: 2,
    border: '1px solid',
    borderRadius: 200,
    verticalAlign: 'middle',
  },
  ellipsist_ext: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 120,
    display: 'inline-block',
  },
}

const AnimalStatus = ({ value, label, color, subType }) => {
  return (
    <Row style={{ margin: '6px 0' }} key={`key-${value}`}>
      <Col sx={6}>
        <Icon
          path={selectIcon(subType)}
          size={1}
          style={{
            ...styles.icon,
            borderColor: color,
            backgroundColor: color,
            color: '#fff',
          }}
        />
      </Col>
      <Col sx={18} className="margin-left">
        <Typography.Text ellipsis>{label}</Typography.Text>
        <br />
        <Typography.Text style={styles.ellipsist_ext} ellipsis strong>
          {value}
        </Typography.Text>
      </Col>
    </Row>
  )
}
export default AnimalStatus
