/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import HttpService from 'store/services/requestApi'
import { endpoint } from 'store/services/endpoints'
import UserManager from 'utils/userManager'
import Storage from 'utils/storage'
import { onSwitchTheme } from 'redux/actions'
import { resetPermissions } from 'redux/reducers/Permissions'
import { resetContext, setContext, setCurrentLanguage } from './App'
import { loadCurrentFarm, resetCurrentFarm } from './Farm'

const defaultState = {
  login: {
    status: null,
    message: null,
    error: null,
  },
  logout: {
    status: null,
    error: null,
  },
  user: null,
}

export const logout = createAsyncThunk('auth/logout', async ({ cb }, thunkAPI) => {
  try {
    const url = endpoint.auth.logout()
    HttpService.post(url)

    // Firebase remove token
    const fcmRegistrationToken = Storage.get('fcmRegistrationToken')
    HttpService.delete(endpoint.firebase.register({ token: fcmRegistrationToken }))

    localStorage.clear()

    // Reset redux
    thunkAPI.dispatch(resetCurrentFarm())
    thunkAPI.dispatch(resetContext())
    thunkAPI.dispatch(resetPermissions())
    thunkAPI.dispatch(resetLogin())
    thunkAPI.dispatch(resetPermissions())

    setTimeout(() => cb && cb(), 200)
  } catch (e) {
    return thunkAPI.rejectWithValue({ message: e.message })
  }
})

export const login = createAsyncThunk('auth/login', async ({ email, password, cb }, thunkAPI) => {
  const Login = endpoint.auth.login()
  const { data, status } = await HttpService.post(Login, {
    email,
    password,
  })

  if (status === 'success') {
    Storage.set('token', data.token)
    if (Storage.get('token')) {
      /* Get user info */
      const controller = endpoint.user()
      const {
        data: { user },
      } = await HttpService.get(controller)

      /* Set user localStorage */
      UserManager.set(null, user)

      /* Set user color theme */
      thunkAPI.dispatch(onSwitchTheme(user.theme))

      /* Set user language */
      thunkAPI.dispatch(setCurrentLanguage({ language: user.locale }))

      /* Set Context */
      thunkAPI.dispatch(setContext(user.context))

      /* Loading Userfarm */
      if (user?.context === 'farm') {
        thunkAPI.dispatch(loadCurrentFarm({ slug: user?.farm })).then(response => {
          window.location.replace(`/farm/${user?.farm}/property/${response.payload.property.slug}`)
        })
      }

      return { user }
    }
  } else {
    // TODO GET REAL MESSAGE
    const message = 'Credenciais inválidas'

    // Set message to login state
    cb(message)

    // redux message
    return thunkAPI.rejectWithValue({ message })
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    resetLogin(state) {
      state.user = defaultState.user
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.login.status = 'started'
      })
      .addCase(login.fulfilled, (state, action) => {
        state.login.status = 'done'
        state.user = action.payload?.user
      })
      .addCase(login.rejected, (state, action) => {
        state.login.status = 'failed'
        state.login.error = action.payload?.message
      })
      .addCase(logout.pending, state => {
        state.logout.status = 'started'
      })
      .addCase(logout.fulfilled, state => {
        state.login = { ...defaultState.login }
        state.logout = { ...defaultState.logout }
        state.user = { ...defaultState.user }
      })
      .addCase(logout.rejected, (state, action) => {
        state.logout.status = 'failed'
        state.logout.error = action.payload?.message
      })
  },
})

export const { resetLogin } = authSlice.actions

// State selectors
export const getUserInfo = state => state?.auth?.user ?? null
export const getIsSystemUser = state => state?.auth?.user?.context === 'root'
export const getUserTheme = state => state?.auth?.user?.theme ?? 'light'

export default authSlice.reducer
