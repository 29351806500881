import { Form, Col } from 'antd'
import { validateField } from 'components/FormBuilder/assets/validate'
import Moment from 'components/Moment'
import { DatePicker } from 'components/Pickers'
import { Translate } from 'lang'
import { useEffect, useState } from 'react'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
  date: {
    width: '100%',
  },
}

export const DateForm = ({ value, allValues }) => {
  const {
    name,
    label,
    disabled,
    rules = [],
    showTime = false,
    cols = { xs: 24 },
    condition = () => true,
  } = value

  const [enabled, setEnabled] = useState(true)
  const [required, setRequired] = useState(false)

  useEffect(() => {
    const isEnabled = !disabled && condition(allValues)
    const isRequired = validateField(allValues, value.required)
    setEnabled(isEnabled)
    setRequired(isRequired && isEnabled)
  }, [allValues, setEnabled, setRequired])

  const format = showTime
    ? Translate({ messageKey: 'datetimeFormat' })
    : Translate({ messageKey: 'dateFormat' })

  return (
    <Col {...cols}>
      <Form.Item
        name={name}
        label={label}
        rules={[...rules, { required }]}
        style={style.form}
        getValueProps={e => (e ? { value: Moment(e) } : null)}
      >
        <DatePicker
          style={style.date}
          showTime={showTime ? { format: 'HH:mm' } : false}
          format={format}
          disabled={!enabled}
          allowClear
        />
      </Form.Item>
    </Col>
  )
}
