import { mdiCow, mdiHome, mdiMap, mdiCellphoneMarker } from '@mdi/js'
import { Translate } from 'lang'

export const Farm = ({ prefix, permissions }) => {
  return [
    {
      key: 'home',
      title: Translate({ messageKey: 'home' }),
      path: `${prefix}`,
      enabled: permissions.systemFarmReader,
      icon: mdiHome,
    },
    {
      key: 'animals',
      title: Translate({ messageKey: 'animal', howMany: 'many' }),
      submenu: [
        {
          key: 'animals-herd',
          title: Translate({ messageKey: 'herd' }),
          icon: mdiCow,
          submenu: [
            {
              key: 'animals-animals',
              title: Translate({ messageKey: 'animal', howMany: 'many' }),
              path: `${prefix}/herd/animals`,
              enabled: permissions.gestaoDeRebanhoReader,
            },
            {
              key: 'animals-batch',
              title: Translate({ messageKey: 'batch', howMany: 'many' }),
              path: `${prefix}/herd/batches`,
              enabled: permissions.gestaoDeRebanhoReader,
            },
            {
              key: 'animals-lots-movement',
              title: Translate({
                messageKey: 'change_of',
                items: {
                  item: Translate({ messageKey: 'lots', howMany: 'one' }),
                },
              }),
              path: `${prefix}/herd/movement`,
              enabled: permissions.gestaoDeRebanhoReader,
            },
          ],
        },
        {
          key: 'animals-lots',
          title: Translate({ messageKey: 'lots', howMany: 'many' }),
          icon: mdiMap,
          submenu: [
            {
              title: Translate({
                messageKey: 'register_item',
                items: {
                  item: Translate({ messageKey: 'lots', howMany: 'many' }),
                },
              }),
              key: 'animals-lots-register',
              path: `${prefix}/lots`,
              enabled: permissions.gestaoDePiquetesReader,
            },
            {
              title: Translate({
                messageKey: 'register_item',
                items: {
                  item: Translate({ messageKey: 'structure_many' }),
                },
              }),
              key: 'animals-structures-register',
              path: `${prefix}/lots/structures`,
              enabled: permissions.gestaoDePiquetesReader,
            },
            {
              title: Translate({ messageKey: 'property_boundaries' }),
              key: 'farm-property-boundaries',
              path: `${prefix}/lots/boundaries`,
              enabled: permissions.gestaoDePiquetesReader,
            },
          ],
        },
      ],
    },
    {
      key: 'hardware',
      title: 'Hardware',
      submenu: [
        {
          key: 'hardware-devices',
          title: Translate({ messageKey: 'devices', howMany: 'many' }),
          icon: mdiCellphoneMarker,
          path: `${prefix}/devices`,
          enabled: permissions.gestaoDeDispositivosReader,
        },
      ],
    },
  ]
}
