import Icon from '@mdi/react'
import { Col, Row, Typography } from 'antd'
import FormatCell from 'components/FormatCell'
import { Colors } from 'constants/Colors'
import selectIcon from 'utils/selectIcon'

const styles = {
  icon: {
    verticalAlign: 'middle',
  },
}

const CardInfo = ({ value, label, subType, color, props = {} }) => {
  const {
    steps_average_percentage,
    steps_is_positive,
    meters_average_percentage,
    meters_is_positive,
    weight_is_positive,
    weight_difference,
  } = props

  const valueDiference = steps_average_percentage ?? meters_average_percentage ?? weight_difference
  const valuePositive = steps_is_positive ?? meters_is_positive ?? weight_is_positive

  return (
    <Row style={{ margin: '6px 0' }} key={`key-${value}`}>
      <Col sx={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {subType === 'plot' || subType === 'grow_phase' ? (
          <img
            src={selectIcon(subType, color)}
            alt="custom icon"
            style={{
              width: '24px',
              height: '24px',
              ...styles.icon,
            }}
          />
        ) : (
          <Icon
            path={selectIcon(subType)}
            size={1}
            style={{
              ...styles.icon,
              color: Colors.primary,
            }}
          />
        )}
      </Col>
      <Col sx={18} className="margin-left">
        <Typography.Text>{label}</Typography.Text>
        {['meters', 'steps', 'weight'].includes(subType) ? (
          <FormatCell
            type={'numeric-differencer'}
            value={value}
            differencer={valueDiference}
            isPositive={valuePositive}
          />
        ) : (
          <>
            <br />
            <Typography.Text strong>{value}</Typography.Text>
          </>
        )}
      </Col>
    </Row>
  )
}
export default CardInfo
