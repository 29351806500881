import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Input, List, Card } from 'antd'
import { Translate } from 'lang'
import { debounce, isEqual } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Utils from 'utils'
import { endpoint } from 'store/services/endpoints'
import { getFarm } from 'redux/reducers/Farm'
import { useSelector } from 'react-redux'
import axiosInstance from 'store/services/axiosConfig'
import LinkString from 'components/FormatCell/Types/Link'
import { useHistory } from 'react-router-dom'
import { Colors } from 'constants/Colors'
import FormatCell from 'components/FormatCell'

const useDebouncedSearch = (initialValue, delay, controller, setShow) => {
  const [searchTerm, setSearchTerm] = useState(initialValue)
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSearchResults([])
  }, [initialValue])

  useEffect(() => {
    const fetchData = async value => {
      try {
        setIsLoading(true)
        const response = await axiosInstance.get(controller, {
          params: {
            search: value,
          },
        })
        setSearchResults(response.data)
        setIsLoading(false)
        setShow(true)
      } catch (error) {
        console.error(error)
      }
    }

    const handler = debounce(value => {
      if (value.trim() !== '') {
        fetchData(value)
      }
    }, delay)

    handler(searchTerm)

    return () => {
      handler.cancel()
    }
  }, [searchTerm, delay])

  const handleChange = event => {
    const { value } = event.target
    setSearchTerm(value)
    if (value.trim() === '') {
      setShow(false)
    }
  }

  return [searchTerm, setSearchTerm, searchResults, isLoading, handleChange]
}

const GlobalSearch = () => {
  const history = useHistory()
  const { slug: farm, lastProperty: property } = useSelector(getFarm, isEqual)
  const screens = Utils.getBreakPoint(useBreakpoint())
  const isMobile = !screens.includes('md')

  const [show, setShow] = useState(false)
  const [hover, setHover] = useState(false)
  const controller = useMemo(() => endpoint.farm.search({ farm, property }), [farm, property])
  const [searchTerm, setSearchTerm, searchResults, isLoading, handleChange] = useDebouncedSearch(
    '',
    1000,
    controller,
    setShow,
  )

  const resultsRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setShow(false)
        setSearchTerm('')
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [searchTerm])

  function handleLinkClick(link) {
    const handleClick = () => {
      history.push(link)
      setShow(false)
      setSearchTerm('')
    }

    return handleClick
  }

  const SearchList = () => {
    const { animals } = searchResults
    return (
      <Card style={{ overflow: 'hidden', marginTop: -16 }} bodyStyle={{ padding: 0 }}>
        <List
          itemLayout="horizontal"
          dataSource={animals?.results}
          renderItem={item => {
            const animalLink = LinkString.string({ to: 'animal', farm, property, id: item.id })
            return (
              <List.Item
                onClick={handleLinkClick(animalLink)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={
                  hover ? { backgroundColor: Colors.primary_light_hover, cursor: 'pointer' } : null
                }
              >
                <FormatCell
                  type="avatar"
                  subtype="animal"
                  title={item.earring}
                  subtitle={item.name}
                  path={animalLink}
                />
              </List.Item>
            )
          }}
        />
        {animals?.total ? (
          <span style={{ textAlign: 'center', display: 'block' }}>
            {Translate({ messageKey: 'results_found', items: { item: animals.total } })}
          </span>
        ) : null}
      </Card>
    )
  }

  return (
    <div style={!isMobile ? { width: 300 } : { display: 'none' }} ref={resultsRef}>
      <Input
        allowClear
        value={searchTerm}
        placeholder={Translate({ messageKey: 'search' })}
        onChange={handleChange}
        suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
      />
      {show && !isLoading ? <SearchList /> : null}
    </div>
  )
}

export default GlobalSearch
