import { Col, Form } from 'antd'
import { Translate } from 'lang'
import { sample } from 'lodash'
import { useEffect, useState } from 'react'
import { CirclePicker } from 'react-color'

const styles = {
  color: {
    width: '36px',
    height: '100%',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
    marginRight: 10,
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  result: {
    cursor: 'default',
    color: '#716c6c',
  },
  resultRow: {
    display: 'inline-flex',
    width: '100%',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  form: {
    display: 'inline-block',
    width: '100%',
  },
}
const colors = [
  '#DBDF00',
  '#A4DD00',
  '#32CD32',
  '#68BC00',
  '#B0BC00',
  '#808900',
  '#2E8B57',
  '#194D33',
  '#0C797D',
  '#0062B1',
  '#73D8FF',
  '#40E0D0',
  '#00CED1',
  '#009CE0',
  '#68CCCA',
  '#FFD700',
  '#FCC400',
  '#FE9200',
  '#FF8C00',
  '#FF4500',
  '#8B4513',
  '#C45100',
  '#F44E3B',
  '#FF6347',
  '#D33115',
  '#9F0500',
  '#F78DA7',
  '#FDA1FF',
  '#FA28FF',
  '#AB149E',
  '#8B008B',
  '#9932CC',
  '#8A2BE2',
  '#673ab7',
  '#7B64FF',
  '#AEA1FF',
]

export const ColorForm = ({ value, form, initialValues }) => {
  const [color, setColor] = useState(initialValues ?? sample(colors))

  useEffect(() => {
    if (color) {
      form.setFieldsValue({ color })
    }
  }, [color])

  const label = Translate({
    messageKey: 'select',
    gender: 'female',
    items: {
      item: Translate({ messageKey: 'color', lower: true }),
    },
  })

  const handleChange = color => {
    setColor(color.hex)
  }

  const { required, cols = { xs: 24 } } = value
  return (
    <Col {...cols}>
      <Form.Item name={'color'} label={label} style={styles.form} required={required}>
        <CirclePicker
          circleSize={24}
          onChangeComplete={handleChange}
          color={color}
          colors={colors}
        />
      </Form.Item>
    </Col>
  )
}
