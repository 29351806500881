import { Translate } from 'lang'
import { mdiBarn, mdiOfficeBuildingCog, mdiViewDashboard, mdiWrench } from '@mdi/js'

const PREFIX = '/admin'

export const Root = ({ permissions }) => {
  return [
    {
      key: 'dashboard',
      title: Translate({ messageKey: 'dashboard' }),
      path: '/',
      icon: mdiViewDashboard,
      enabled: permissions.systemReader,
    },
    {
      key: 'farms',
      title: 'Farm',
      icon: mdiBarn,
      submenu: [
        {
          key: 'acconts-farm',
          title: Translate({
            messageKey: 'account',
            howMany: 'many',
            items: {
              item: 'Farm',
            },
          }),
          path: `${PREFIX}/farm/user`,
          enabled: permissions.gerenciamentoRootReader,
        },
        {
          key: 'roles-farm',
          title: Translate({
            messageKey: 'role',
            howMany: 'many',
            items: {
              item: 'Farm',
            },
          }),
          path: `${PREFIX}/farm/roles`,
          enabled: permissions.gerenciamentoRootReader,
        },
        {
          key: 'planos-farm',
          title: Translate({
            messageKey: 'plan',
            howMany: 'many',
            items: {
              item: 'Farm',
            },
          }),
          path: `${PREFIX}/farm/plans`,
          enabled: permissions.gerenciamentoRootReader,
        },
      ],
    },
    {
      key: 'root',
      title: Translate({ messageKey: 'root' }),
      icon: mdiOfficeBuildingCog,
      submenu: [
        {
          key: 'root_users',
          title: Translate({
            messageKey: 'user',
            howMany: 'many',
            items: {
              item: Translate({ messageKey: 'root' }),
            },
          }),
          path: `${PREFIX}/root/users`,
          enabled: permissions.gestaoDeContasFarmReader,
        },
        {
          key: 'roles',
          title: Translate({
            messageKey: 'role',
            howMany: 'many',
            items: {
              item: Translate({ messageKey: 'root' }),
            },
          }),
          path: `${PREFIX}/root/roles`,
          enabled: permissions.gestaoDeContasFarmReader,
        },
        {
          key: 'functionalities',
          title: Translate({ messageKey: 'functionality', howMany: 'many' }),
          path: `${PREFIX}/root/functionalities`,
          enabled: permissions.gestaoDeContasFarmReader,
        },
      ],
    },
    {
      key: 'hardware',
      title: 'Hardware',
      icon: mdiWrench,
      submenu: [
        {
          key: 'hardware_devices',
          title: Translate({ messageKey: 'devices', howMany: 'many' }),
          path: `${PREFIX}/hardware/devices`,
          enabled: permissions.gestaoDeHardwareReader,
        },
        {
          key: 'hardware_gateway',
          title: Translate({ messageKey: 'gateway', howMany: 'many' }),
          path: `${PREFIX}/hardware/gateways`,
          enabled: permissions.gestaoDeHardwareReader,
        },
      ],
    },
  ]
}
