import React, { useState } from 'react'
import { connect } from 'react-redux'
import { LockOutlined } from '@ant-design/icons'
import { Row, Button, Form, Input, Alert, Result, Divider } from 'antd'
import { Link } from 'react-router-dom'
import { endpoint } from 'store/services/endpoints'
import HttpService from 'store/services/requestApi'

const rules = {
  password: [
    {
      required: true,
      message: 'Insira sua senha',
    },
  ],
  confirm: [
    {
      required: true,
      message: 'Insira novamente sua senha',
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve()
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('as senhas não coincidem')
      },
    }),
  ],
}

export const NewPasswordForm = props => {
  const { loading, message, showMessage, topMessage } = props
  const [data, setData] = useState()
  const [code, setCode] = useState()
  const [form] = Form.useForm()
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')

  const onSubmit = () => {
    form
      .validateFields()
      .then(async values => {
        const controller = endpoint.auth.reset()
        const { data, code } = await HttpService.post(controller, {
          ...values,
          token,
        })
        setData(data)
        setCode(code)
      })
      .catch(info => {
        console.error('Validate Failed:', info)
      })
  }
  const result = {
    success: {
      status: 'success',
      title: 'Sucesso',
      subTitle: 'Sua nova senha já está salva, basta realizar o login.',
    },
    error: {
      status: 'error',
      title: 'Erro',
      subTitle: 'Aconteceu um erro na sua solicitação, tente novamente.',
    },
  }

  return data ? (
    <Row justify="center ">
      <Result
        {...(code === 200 ? result.success : result.error)}
        extra={[
          <Link to="/login" key="login-link">
            <Button key="login-button" type="primary">
              Ir para Login
            </Button>
          </Link>,
        ]}
      />
    </Row>
  ) : (
    <>
      <div className="text-center">
        <p>{topMessage}</p>
      </div>

      {showMessage && <Alert type="error" showIcon message={message} />}
      <Form form={form} layout="vertical" name="new-password-form" onFinish={onSubmit}>
        <Form.Item name="password" label="Nova senha" rules={rules.password} hasFeedback>
          <Input.Password
            placeholder="Informe sua senha"
            prefix={<LockOutlined className="text-primary" />}
          />
        </Form.Item>
        <Form.Item
          name="password_confirmation"
          label="Repetir nova senha"
          rules={rules.confirm}
          hasFeedback
        >
          <Input.Password
            placeholder="Informe novamente sua senha"
            prefix={<LockOutlined className="text-primary" />}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Registrar nova senha
            {/* TODO */}
          </Button>
        </Form.Item>
      </Form>
      <div className="d-flex justify-content-center">
        <div>
          <Divider
            style={{
              width: 100,
              minWidth: 'unset',
              margin: '0 auto 24px',
            }}
          />
          <span>Já tem uma conta?</span>
          <a className="ml-3" href="/login">
            Entrar
          </a>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ auth }) => {
  const { loading, message, showMessage, token, redirect } = auth
  return { loading, message, showMessage, token, redirect }
}

export default connect(mapStateToProps)(NewPasswordForm)
