import React, { useEffect, useMemo, useState } from 'react'
import { Divider, Dropdown, Menu, Tag, Typography } from 'antd'
import { isEqual } from 'lodash'
import { Translate } from 'lang'
import Icon from '@mdi/react'
import { mdiWifi, mdiWifiOff } from '@mdi/js'
import CardDropdown from 'components/CardDropdown'
import { useSelector } from 'react-redux'
import { endpoint } from 'store/services/endpoints'
import useAxios from 'store/hooks/useAxios'
import { getCurrentFarm } from 'redux/reducers/Farm'

const { Text, Title } = Typography

function FarmConnectionStatus() {
  const { farm, property } = useSelector(getCurrentFarm, isEqual)
  const [farmStatus, setFarmStatus] = useState()
  const controller = useMemo(() => endpoint.farm.status.show({ farm, property }), [farm, property])
  const { data, refetch } = useAxios({ controller })

  useEffect(() => {
    if (!data) {
      refetch()
    } else {
      setFarmStatus(data)
    }
  }, [data])

  useEffect(() => {
    const timer = setInterval(() => refetch(), 1000 * 60)
    return () => clearInterval(timer)
  }, [])

  const connectionStatus = farmStatus?.status ? 'Online' : 'Offline'
  const statusColor = farmStatus?.status ? '#54DA62' : '#ef3638'

  const items = [
    {
      label: (
        <div style={{ flex: 1, minWidth: 250 }}>
          <Title level={3} style={{ margin: 0 }}>
            {Translate({ messageKey: 'status' })}
          </Title>
          <Divider style={{ margin: '5px 0', width: '100%' }} />
        </div>
      ),
      separator: '',
    },
    {
      label: (
        <>
          <Text>{Translate({ messageKey: 'data_reception' })}</Text>
        </>
      ),
      value: (
        <Tag color={`${statusColor}11`} style={{ border: `1px solid ${statusColor}` }}>
          <Icon
            path={connectionStatus === 'Online' ? mdiWifi : mdiWifiOff}
            size={0.8}
            style={{ verticalAlign: 'middle' }}
            color={statusColor}
          />
          <Text style={{ color: statusColor, marginLeft: 2 }}>{connectionStatus}</Text>
        </Tag>
      ),
    },
    {
      label: Translate({ messageKey: 'last_update' }),
      value: (
        <Text strong style={{ display: 'inline-block' }}>
          {farmStatus?.last_package}
        </Text>
      ),
    },
  ]

  const DropdownItens = [
    {
      key: 'status-dashboard',
      type: 'group',
      label: <CardDropdown items={items} />,
    },
  ]

  const MenuItens = [
    {
      key: 'item',
      label: (
        <Icon
          path={farmStatus?.status ? mdiWifi : mdiWifiOff}
          size={1}
          style={{ verticalAlign: 'middle' }}
          color={statusColor}
        />
      ),
    },
  ]

  return (
    <Dropdown
      menu={{
        items: DropdownItens,
      }}
      placement="bottomLeft"
    >
      <Menu
        className="d-flex align-item-center"
        mode="horizontal"
        selectedKeys={null}
        items={MenuItens}
      />
    </Dropdown>
  )
}

export default FarmConnectionStatus
