import React, { useEffect, useState } from 'react'
import { DrawingManager, Polygon } from '@react-google-maps/api'
import { PRIMARY_COLOR } from 'constants/ThemeConstant'
import { useSelector } from 'react-redux'
import { getCurrentProperty, getFarmMeasure } from 'redux/reducers/Farm'
import { renderToString } from 'react-dom/server'
import Bottom from 'components/Maps/ManipulateMap/Bottom'
import { Translate } from 'lang'
import manipulateArea from 'utils/area'
import { isEqual } from 'lodash'
import InfoMap from '../ManipulateMap/Info'
import LockMap from '../ManipulateMap/lock'
import {
  DrawingDefaultOptions,
  DrawingOptions,
  getPaths,
  pathStringToInt,
  PolygonDefaultOptions,
  PolygonOptions,
  Polygons,
} from '../ManipulateMap/utils'
import Maps from '..'
import Polylines from '../Polyline'

export default function DrawingMap(props) {
  const {
    paths,
    color,
    setCoordinates,
    additional_lots,
    name,
    bottom,
    property_limits,
    height,
    zoom = 16,
    center,
  } = props

  /** INFO */
  let infoWindow = null
  const property = useSelector(getCurrentProperty, isEqual)
  const { area_unit } = useSelector(getFarmMeasure, isEqual)

  /**POLYGON */
  const [polygon, setPolygon] = useState(null)
  const [polygonRef, setPolygonRef] = useState(null)
  /**LOCK ICON */
  const [polygonEdit, setPolygonEdit] = useState(false)

  useEffect(() => {
    if (paths) {
      setPolygon({
        path: pathStringToInt(paths),
        options: PolygonOptions(PolygonDefaultOptions, color),
      })
    }
  }, [paths])

  useEffect(() => {
    if (polygonRef) {
      polygonRef.setOptions({ fillColor: color, strokeColor: '#fff' })
    }
  }, [color])

  // Set the ref
  const onPolygonLoad = polygon => {
    onLoad(polygon, name)
    setPolygonRef(polygon)
  }

  // Update the polygon
  const onPolygonMouseUp = () => {
    onPolygonComplete(polygonRef)
  }

  // When first drawing is complete and edit has been made, this function is run
  const onPolygonComplete = (polygon, destroy = false) => {
    const { google } = window
    const paths = getPaths(polygon)
    setCoordinates(paths)
    setPolygon({
      path: paths,
      options: PolygonOptions(PolygonDefaultOptions, color),
      area: google.maps.geometry.spherical.computeArea(paths),
    })

    // Destroys the polygon that has been drawn by the manager.
    if (destroy) {
      polygon.setMap(null)
    }
  }

  const centerMap = map => {
    if (!center) {
      // Cria um novo objeto LatLngBounds para conter os limites do mapa
      const bounds = new window.google.maps.LatLngBounds()

      // Verifica se há um array 'paths' definido
      if (paths) {
        // Se 'paths' estiver definido, estende os limites do mapa para incluir cada ponto
        paths.forEach(({ lat, lng }) => bounds.extend({ lat, lng }))
      } else {
        // Se não houver 'paths', estende os limites do mapa para incluir o ponto 'property'
        bounds.extend(property)

        // Adiciona pontos adicionais para melhor ajuste dos limites
        const additionalPoints = [
          { lat: property.lat + 0.001, lng: property.lng + 0.001 },
          { lat: property.lat - 0.001, lng: property.lng - 0.001 },
        ]

        // Estende os limites do mapa para incluir os pontos adicionais
        additionalPoints.forEach(point => bounds.extend(point))
      }

      // Ajusta o mapa para exibir todos os limites calculados
      map.fitBounds(bounds)
    }
  }

  /**
   * Adiciona listener para quando clicar exibir o 'componente' recebido do content
   * O content converte a prop content para string, pois o infoWindow não aceita
   * um componente diretamente.
   * */
  function onLoad(map, id) {
    const { google } = window
    google.maps.event.addListener(map, 'click', mapsMouseEvent => {
      const content = <InfoMap data={{ title: id }} />
      if (infoWindow) {
        infoWindow.close()
      }
      // Create a new InfoWindow.
      infoWindow = new google.maps.InfoWindow({
        position: mapsMouseEvent.latLng,
        content: renderToString(content),
      })
      infoWindow.open(map)
    })
  }

  const options = () => ({
    ...PolygonDefaultOptions,
    fillColor: 'red',
    strokeColor: 'yellow',
  })

  const LockPolygons = React.useMemo(
    () => <Polygons lots={additional_lots} onLoad={onLoad} options={options} />,
    [additional_lots],
  )

  const PropertyLimits = React.useMemo(
    () => <Polylines key={'limits'} paths={property_limits} />,
    [property_limits],
  )

  return (
    <Maps
      style={{ height: height ?? '70vh' }}
      handleOnLoad={map => centerMap(map)}
      zoom={zoom}
      center={center}
    >
      {/* Lock Polygons */}
      {LockPolygons}
      {PropertyLimits}
      {/* Editable polygon */}
      {polygon ? (
        <>
          {/* Lock Icon */}
          <LockMap lock={polygonEdit} setPolygonEdit={setPolygonEdit} />
          {/* Polygon */}
          <Polygon
            {...polygon}
            editable={polygonEdit}
            onLoad={onPolygonLoad}
            onMouseUp={onPolygonMouseUp}
          />
          {polygon.area && (
            <Bottom>
              {Translate({
                messageKey: bottom?.area?.messageKey,
                items: {
                  area: manipulateArea(polygon.area, area_unit ?? 'ha'),
                  measure: area_unit ?? 'ha',
                },
              })}
            </Bottom>
          )}
        </>
      ) : null}
      {/* Drawer polygon */}
      <DrawingManager
        options={DrawingOptions(DrawingDefaultOptions, color)}
        onPolygonComplete={polygon => onPolygonComplete(polygon, true)}
      />
    </Maps>
  )
}

DrawingMap.defaultProps = {
  color: PRIMARY_COLOR,
}
