import { Col, Row, Typography } from 'antd'
import Icon from '@mdi/react'
import { Colors } from 'constants/Colors'
import { mdiArrowDown, mdiArrowUp } from '@mdi/js'

const { Text } = Typography

const NumericDifferencer = ({ value, differencer, isPositive, size, porcent }) => {
  return (
    <Row gutter={[10, 0]} justify="start" align="middle">
      <Col>
        <Text strong style={{ fontSize: size ?? '14px' }}>
          {value}
        </Text>
      </Col>
      {differencer &&
        differencer !== '0' &&
        differencer !== 'NaN%' &&
        differencer !== '0%' &&
        differencer !== 'Infinity%' && (
          <Col align="middle" style={{ display: 'flex', alignItems: 'center' }}>
            {isPositive ? (
              <Icon path={mdiArrowUp} color={Colors.green} size={0.8} />
            ) : (
              <Icon path={mdiArrowDown} color={Colors.danger} size={0.8} />
            )}
            <Text style={{ color: isPositive ? Colors.green : Colors.danger, fontSize: '12px' }}>
              {differencer}
              {porcent && <span style={{ fontSize: 11 }}> ({porcent})</span>}
            </Text>
          </Col>
        )}
    </Row>
  )
}

export default NumericDifferencer
