import React, { useContext, Suspense, lazy } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import AppLayout from 'views/Layout'
import { ConfigProvider } from 'antd'
import { StoreToken } from 'store/context'
import Loading from 'components/Loading'
import CurrentLanguage from 'utils/currentLanguage'
import pt_BR from 'antd/lib/locale/pt_BR'
import en_US from 'antd/lib/locale/en_US'
import Firebase from 'store/services/Firebase'
import Authentication from './Login/View'

const Logout = lazy(() => import(`./Logout`))

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  const locale = {
    'pt-BR': pt_BR,
    'en-US': en_US,
  }
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          <Firebase>
            <ConfigProvider locale={locale[CurrentLanguage.get()]}>{children}</ConfigProvider>
          </Firebase>
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  )
}

function LoginCheck({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => (!isAuthenticated ? children : <Redirect to={{ pathname: '/' }} />)}
    />
  )
}

export const Views = () => {
  const { token } = useContext(StoreToken)
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route component={Logout} path={`/logout`} name={'logout'} exact />
        {/* Login page */}
        <LoginCheck exact path={'/login'} isAuthenticated={token}>
          <Authentication type={'login'} />
        </LoginCheck>
        {/* Forgot Password page */}
        <Route exact path={'/forgot'}>
          <Authentication type={'forgot'} />
        </Route>
        {/* Register New Password page */}
        <Route exact path={'/reset'}>
          <Authentication type={'reset'} />
        </Route>
        {/* Interceptor FARM context */}
        <RouteInterceptor path={'/farm'} isAuthenticated={token}>
          <AppLayout />
        </RouteInterceptor>
        {/* Interceptor ROOT context */}
        <RouteInterceptor path={'/'} isAuthenticated={token}>
          <AppLayout />
        </RouteInterceptor>
      </Switch>
    </Suspense>
  )
}

export default withRouter(Views)
