import dayjs from 'dayjs'

/**
 * Dayjs Plugins
 * https://day.js.org/docs/en/plugin/plugin
 */
const utc = require('dayjs/plugin/utc')

dayjs.extend(utc)

const timezone = require('dayjs/plugin/timezone')

dayjs.extend(timezone)

const localizedFormat = require('dayjs/plugin/localizedFormat')

dayjs.extend(localizedFormat)

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')

dayjs.extend(isSameOrBefore)

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

dayjs.extend(isSameOrAfter)

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

const isBetween = require('dayjs/plugin/isBetween')

dayjs.extend(isBetween)

const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)

const duration = require('dayjs/plugin/duration')

dayjs.extend(duration)

dayjs.tz.setDefault('America/Sao_Paulo')

/**
 * It takes a timestamp as a prop, and returns a formatted date string
 * @param props - The props passed to the component.
 * @returns The dayjs function is being returned.
 */
const Moment = props => dayjs(props)

/**
 * https://day.js.org/docs/en/display/difference
 * Return the difference between two dates in a given measurement
 * @param [later=now] - The date to start the diff from. Defaults to now.
 * @param previous - The date to compare against.
 * @param measurement - The measurement to use. Can be one of the following: d,M,y,h,m,s,ms
 * @returns The difference between two dates in a given measurement.
 */
const diff = (later = 'now', previous, measurement = 'ms') => {
  const start = later === 'now' ? dayjs() : dayjs(later)

  const prev = dayjs(previous, 'DD/MM/YYYY HH:mm')
  return start.diff(prev, measurement)
}

/**
 * It returns the number of days between the current date and the date passed in
 * @returns The difference in days between the current date and the date passed in.
 */
const diffInDays = date => {
  const compare = dayjs(date).startOf('d')
  return dayjs().startOf('d').diff(compare, 'd')
}

/**
 * It takes a date string, and returns a formatted date string
 * @returns A function that takes a date as an argument and returns a string.
 */
const view = date => {
  if (!date || date.trim() === '-') {
    return '-'
  }
  return Moment(date).format('L HH:mm')
}

/**
 * It takes a date string, and returns a humanized age string
 * @returns A function that takes a date as an argument and returns a string.
 */
function age(value) {
  if (!dayjs(value).isValid()) {
    return 'Data de nascimento inválida'
  }

  const now = dayjs()
  const birthDate = dayjs(value)
  const years = now.diff(birthDate, 'year')
  const totalMonths = now.diff(birthDate, 'month')
  const months = totalMonths - years * 12

  const yearText = years === 1 ? 'ano' : 'anos'
  const monthText = months === 1 ? 'mês' : 'meses'

  if (months === 0 && years === 0) {
    const days = now.diff(birthDate, 'day') - totalMonths * 30
    const dayText = days === 1 ? 'dia' : 'dias'
    return `${days} ${dayText}`
  }
  if (years === 0) {
    return `${months} ${monthText}`
  }
  if (months === 0) {
    return `${years} ${yearText}`
  }
  return `${years} ${yearText} e ${months} ${monthText}`
}

/**
 * Be careful with the pure function
 * It doesn't return the data in the current timezone. you will need to manually change
 */
Moment.pure = dayjs
Moment.view = view
Moment.diff = diff
Moment.diffInDays = diffInDays
Moment.age = age
export default Moment

/**
 * Custom formats to api
 * if need formats use this link: https://day.js.org/docs/en/display/format#list-of-localized-formats
 */
export const fmt = {
  api: 'YYYY-MM-DD HH:mm:ss',
  iso: 'YYYY-MM-DDTHH:mm:ssZ',
  hour: 'HH:mm',
  date: 'YYYY-MM-DD',
}
