/**
 * It takes a value, formats it, and returns it
 * @returns A React component that displays the area of a room.
 */
const Area = ({ value, unit }) => {
  if (!value) return '-'
  const area = value.toFixed(2)

  switch (unit) {
    case 'm2':
      unit = 'm²'
      break

    default:
      break
  }
  return `${area} ${unit}`
}

export default Area
Area.defaultProps = {
  unit: '',
}
