import { Form, Input, Col, Select } from 'antd'
import { Translate } from 'lang'
import toLower from 'lodash/toLower'
import { DDIList } from '../assets/DDI'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

const prefixSelector = (name, disabled) => (
  <Form.Item name={[name, 'ddi']} noStyle>
    <Select
      showSearch
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{ minWidth: 100, maxWidth: 200 }}
      disabled={disabled}
      tabIndex={-1}
    >
      {DDIList.map(option => (
        <Select.Option key={`key-${option.value}`} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
)

export const PhoneForm = ({ value }) => {
  const { name, label, disabled, rules = [], required, placeholder, cols = { xs: 24 } } = value
  const defaultPlaceholder = Translate({
    messageKey: 'inform',
    gender: 'male',
    items: {
      item: toLower(label),
    },
  })
  return (
    <Col {...cols}>
      <Form.Item
        name={[name, 'number']}
        label={label}
        rules={[
          { required },
          {
            pattern: new RegExp(/^.{10,20}$/),
            message: Translate({ messageKey: 'minLength', items: { item: 10 } }),
          },
          ...rules,
        ]}
        style={style.form}
      >
        <Input
          type="number"
          addonBefore={prefixSelector(name, disabled)}
          placeholder={placeholder ?? defaultPlaceholder}
          disabled={disabled}
        />
      </Form.Item>
    </Col>
  )
}
