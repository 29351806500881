import React, { useMemo } from 'react';
import useStorage from 'utils/useStorage';
import { withRouter } from 'react-router-dom';
import  { User,  StoreToken } from '../context';


const StoreProvider = ({ children }) => {
  const [token, setToken] = useStorage('token');
  const [user, setUser] = useStorage('user');
  const StoreTokenValue = useMemo(() => ({ token, setToken }), [token, setToken]);
  const UserValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <StoreToken.Provider value={StoreTokenValue} >
    <User.Provider value={UserValue} >
      {children}
    </User.Provider>
    </StoreToken.Provider>
  )
}

export default withRouter(StoreProvider);