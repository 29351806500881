import { Alert } from 'antd'
import Moment from 'components/Moment'
import { Translate } from 'lang'
import { uniqueId } from 'lodash'
import React from 'react'

export default function NotificationMessage({ infos }) {
  return (
    <div>
      {infos
        ?.filter(i => i.type === 'message')
        .map(({ message }) => (
          <Alert
            key={uniqueId()}
            description={
              <>
                {Translate({
                  messageKey: 'batch_exchange',
                  items: {
                    item: message?.actualLot,
                    time: Moment(message?.resolvedDate).format('DD/MM/YYYY HH:mm'),
                  },
                })}
              </>
            }
            type="warning"
          />
        ))}
    </div>
  )
}
