import Storage from 'utils/storage'

class UserManager {
  static get(key) {
    const user = Storage.get('user')
    if (user) {
      return key ? user[key] : user
    }
    return null
  }

  static getFarm() {
    const user = Storage.get('user')
    if (user) {
      return user.farm
    }
    return null
  }

  static set(key, value) {
    if (key) {
      const user = Storage.get('user')
      Storage.set('user', { ...user, [key]: value })
    } else {
      Storage.set('user', value)
    }
  }
}

export default UserManager
