/**
 * It takes a value and a unit, and returns the value converted to the specified unit
 * @param value - The value to be converted.
 * @param to - The unit to convert to.
 * @returns the value of the area in either km or ha.
 */
export default function manipulateArea(value, to) {
  switch (to) {
    case 'km':
      return parseFloat(value / 1000).toFixed(2)

    case 'm2':
      return value.toFixed(2)

    default:
      return parseFloat(value / 10000).toFixed(2)
  }
}
