import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Divider, Drawer, List, Row, Typography, message } from 'antd'
import { Translate } from 'lang'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isEqual, unionBy, uniqueId } from 'lodash'
import { endpoint } from 'store/services/endpoints'
import { getCurrentFarm } from 'redux/reducers/Farm'
import { useSelector } from 'react-redux'
import axiosInstance from 'store/services/axiosConfig'
import qs from 'qs'
import NotificationDetails from 'components/Notifications/Details'
import { ScrollableContent } from 'components/Scroll'
import { Colors } from 'constants/Colors'
import Icon from '@mdi/react'
import { mdiEmailOpenOutline } from '@mdi/js'
import NotificationAlert from '../Manipulate/Alert'

const DrawerNotification = ({ open, setOpen, refetch }) => {
  const { farm, property } = useSelector(getCurrentFarm, isEqual)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [meta, setMeta] = useState({})
  const [page, setPage] = useState(1)
  const [details, setDetails] = useState(null)
  const [checked, setChecked] = useState([])
  const [checkedAll, setCheckedAll] = useState(false)

  const controller = endpoint.farm.notifications.show({ farm, property })

  const controllerRead = {
    list: endpoint.farm.notifications.read.list({ farm, property }),
    all: endpoint.farm.notifications.read.all({ farm, property }),
  }

  /**
   * Primeiro carregamento, quando acessa uma propriedade
   */
  const loadData = () => {
    if (loading || !farm || !property) return
    setLoading(true)

    axiosInstance
      .get(controller)
      .then(({ data }) => {
        setData(data.data)
        setMeta(data.meta)
        setPage(2)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (open) {
      loadData()
    }
  }, [open, farm, property])

  /**
   * Outros carregamentos, gerenciamento de páginas
   */
  const loadMoreData = () => {
    if (!open || loading || !farm || !property) return
    setLoading(true)

    axiosInstance
      .get(`${controller}?${qs.stringify({ page })}`)
      .then(({ data }) => {
        setData(prevState => unionBy(prevState, data?.data, 'id'))
        setMeta(data.meta)
        setPage(prevState => prevState + 1)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    async function fetchData() {
      if (checkedAll === true) {
        data.forEach(data => {
          if (!checked.includes(data.id)) {
            setChecked(lastChecked => [...lastChecked, data.id])
          }
        })
      }
    }
    fetchData()
  }, [data])

  const handleDetails = id => {
    setData(prevState =>
      prevState?.map(notification =>
        notification.id === id ? { ...notification, is_read: true } : notification,
      ),
    )
    // Define o ID da notificação atualmente selecionada
    setDetails(id)
  }

  const handleCloseDetails = () => {
    // Reseta id
    setDetails(null)
    // Atualiza badge
    refetch()
  }

  const updateIsReadStatus = (stateArray, idsToUpdate) => {
    if (checkedAll) {
      return stateArray.map(item => ({ ...item, is_read: true }))
    }
    return stateArray.map(item => ({
      ...item,
      is_read: idsToUpdate.includes(item.id) ? true : item.is_read,
    }))
  }

  const toggleCheckbox = id => {
    setChecked(prevChecked => {
      if (prevChecked.includes(id)) {
        return prevChecked.filter(item => item !== id)
      }
      return [...prevChecked, id]
    })
    setCheckedAll(false)
  }

  const toggleSelectAll = () => {
    if (checkedAll || data.length === checked.length) {
      setChecked([])
      setCheckedAll(false)
    } else {
      const allIds = data.map(item => item.id)
      setChecked(allIds)
      setCheckedAll(true)
    }
  }

  const markAsRead = () => {
    if (checked.length > 0 || checkedAll) {
      const endpointRead = checkedAll ? controllerRead.all : controllerRead.list
      const requestData = checkedAll ? null : { notification_ids: checked }

      axiosInstance.put(endpointRead, requestData).then(() => {
        setData(prevData => updateIsReadStatus(prevData, checked))
        setChecked([])
        setCheckedAll(false)
        refetch()
        message.success('Todas as notificações selecionadas foram marcadas como lidas.')
      })
    }
  }

  return (
    <>
      <Drawer
        title={Translate({ messageKey: 'notifications' })}
        onClose={() => setOpen(false)}
        open={open}
        width={500}
      >
        <Row justify="space-around" align="middle" style={{ marginBottom: '10px' }}>
          <Col span={20}>
            <Checkbox
              checked={checkedAll}
              onChange={() => toggleSelectAll()}
              style={{ marginRight: '2%' }}
            />
            <Typography.Text>{Translate({ messageKey: 'check_all' })}</Typography.Text>
          </Col>
          <Col span={1}>
            <div style={{ cursor: 'pointer' }} onClick={() => markAsRead()}>
              <Icon path={mdiEmailOpenOutline} color={Colors.primary} size={1} />
            </div>
          </Col>
        </Row>
        <ScrollableContent id="scrollableDiv" style={{ overflow: 'hidden' }}>
          <InfiniteScroll
            dataLength={data?.length}
            next={loadMoreData}
            hasMore={data?.length < meta?.total}
            height={'85vh'}
            endMessage={data?.length ? <Divider plain>É tudo, nada mais...</Divider> : null}
            scrollableTarget="scrollableDiv"
          >
            <List
              loading={loading}
              split={false}
              dataSource={data}
              size={'small'}
              renderItem={item => {
                return (
                  <List.Item
                    key={uniqueId(item.title)}
                    style={{ backgroundColor: checked.includes(item.id) ? '#F5F5F5' : 'none' }}
                  >
                    <Checkbox
                      checked={checked.includes(item.id)}
                      style={{ marginRight: '2%' }}
                      onChange={() => toggleCheckbox(item.id)}
                    />
                    <NotificationAlert {...item} onClick={() => handleDetails(item.id)} />
                  </List.Item>
                )
              }}
            />
          </InfiniteScroll>
        </ScrollableContent>
      </Drawer>
      <NotificationDetails key={details} notification={details} onClose={handleCloseDetails} />
    </>
  )
}
export default DrawerNotification
