import { Col, Typography } from 'antd'

const { Title } = Typography
const styles = {
  label: { margin: '20px 0px 10px' },
}
/**
 * @function TitleForm - Form Radio
 * @param {string} title - texto
 * @param {boolean} disabled
 * * */
export const TitleForm = ({ value }) => {
  const { label, cols = { xs: 24 }, level = 4, style, description } = value
  return (
    <Col {...cols}>
      <div style={style || styles.label}>
        <Title level={level} strong>
          {label}
        </Title>
        {description && <p>{description}</p>}
      </div>
    </Col>
  )
}
