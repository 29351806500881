import React from 'react'
import styled from 'styled-components'
import Icon from '@mdi/react'
import { Colors } from 'constants/Colors'
import selectIcon from 'utils/selectIcon'

const IconWrapper = styled.div`
  padding: 5px;
  border: 1px solid ${props => props.borderColor || Colors.gray_light};
  border-radius: 200px;
  vertical-align: middle;
  max-width: 44px;
  max-height: 44px;
`

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.color || '#000'};
`

const InfoIcon = ({ subtype, title, subtitle, style = {}, onClick }) => {
  const iconProps = {
    path: selectIcon(subtype),
    size: 1.8,
    style: {
      ...style.icon,
      borderColor: Colors.gray_light,
      color: '#fff',
    },
  }

  return (
    <div
      style={{ display: 'flex', gap: 10, cursor: onClick ? 'pointer' : 'default' }}
      onClick={onClick ?? null}
    >
      {[
        'plot_alert',
        'possible_birth',
        'external_alert',
        'internal_alert',
        'fall_device_alert',
      ].includes(subtype) ? (
        <IconWrapper style={style.icon}>
          <img
            src={selectIcon(subtype)}
            alt="custom icon"
            style={{ width: '33px', height: '33px' }}
          />
        </IconWrapper>
      ) : (
        <Icon {...iconProps} />
      )}

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Title style={style.text}>{title}</Title>
        {subtitle && <p style={{ margin: 0 }}>{subtitle}</p>}
      </div>
    </div>
  )
}

InfoIcon.defaultProps = {
  subtitle: '',
  style: {},
  color: Colors.gray_light,
}

export default React.memo(InfoIcon)
