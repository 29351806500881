import React from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import { Colors } from 'constants/Colors'

/**
 * It returns an Avatar component with a background color of green if the value is true, and red if the
 * value is false
 * @returns A React component
 */
export default function Boolean({ value }) {
  if (!value) return '-'
  const backgroundColor = value ? Colors.success : Colors.danger
  const Icon = value ? <CheckOutlined /> : <CloseOutlined />

  return <Avatar style={{ backgroundColor }} size={25} icon={Icon} />
}
