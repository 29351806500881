import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import HttpService from 'store/services/requestApi'
import { endpoint } from 'store/services/endpoints'
import { setContext } from './App'

const defaultState = {
  currentFarm: {
    status: null,
    slug: null,
    name: null,
    error: null,
    lastProperty: null,
    mainProperty: null,
    measures: null,
    properties: [],
  },
}
export const loadFarmProperties = createAsyncThunk(
  'farm/loadFarmProperties',
  async ({ slug }, thunkAPI) => {
    const state = thunkAPI.getState()
    const farm = state?.farm?.current?.slug || slug

    if (farm) {
      try {
        const controller = endpoint.farm.properties.show({ farm })
        const { data: properties } = await HttpService.get(controller)

        return {
          properties: properties?.map(({ lat, lng, ...rest }) => ({
            ...rest,
            lat: lat ? Number(lat) : lat,
            lng: lng ? Number(lng) : lng,
          })),
        }
      } catch (e) {
        return thunkAPI.rejectWithValue({ message: e.message })
      }
    } else {
      return thunkAPI.rejectWithValue({ message: 'missing farm slug' })
    }
  },
)
export const loadFarmMeasure = createAsyncThunk(
  'farm/loadFarmMeasure',
  async ({ slug }, thunkAPI) => {
    const state = thunkAPI.getState()
    const farm = state?.farm?.current?.slug || slug

    try {
      const controller = endpoint.farm.settings.measure({ farm })
      const { data: measures } = await HttpService.get(controller)

      return { measures }
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message })
    }
  },
)

export const loadCurrentFarm = createAsyncThunk(
  'farm/loadCurrentFarm',
  async (payload, thunkAPI) => {
    const { slug } = payload

    if (slug) {
      try {
        const controller = endpoint.farm.properties.show({ farm: slug })
        const { data, status } = await HttpService.get(controller)

        if (status === 'error') {
          return thunkAPI.rejectWithValue({ slug, message: data })
        }

        let property = Array.isArray(data) ? data.find(prop => prop.is_main) ?? data[0] : {}

        /* Set Properties */
        thunkAPI.dispatch(loadFarmProperties({ slug }))

        /* Set Properties */
        thunkAPI.dispatch(loadFarmMeasure({ slug }))

        /* Set Context */
        thunkAPI.dispatch(setContext('farm'))

        return { data, property }
      } catch (e) {
        return thunkAPI.rejectWithValue({ slug, message: e.message })
      }
    }
    return thunkAPI.rejectWithValue({ slug, message: 'Missing slug' })
  },
)

export const farmSlice = createSlice({
  name: 'farm',
  initialState: defaultState,
  reducers: {
    setLastProperty(state, action) {
      state.currentFarm.lastProperty = action?.payload
    },
    resetLastProperty(state) {
      state.currentFarm.lastProperty = defaultState.currentFarm.lastProperty
    },
    setCurrentFarm(state, action) {
      state.currentFarm.data = action?.payload
    },
    resetCurrentFarm(state) {
      state.currentFarm = defaultState.currentFarm
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadFarmProperties.fulfilled, (state, action) => {
        state.currentFarm.properties = action.payload?.properties
      })
      .addCase(loadFarmMeasure.fulfilled, (state, action) => {
        state.currentFarm.measures = action.payload?.measures
      })
      .addCase(loadCurrentFarm.pending, state => {
        state.currentFarm.status = 'started'
      })
      .addCase(loadCurrentFarm.fulfilled, (state, action) => {
        state.currentFarm = {
          ...defaultState.currentFarm,
          status: 'done',
          slug: action.meta.arg.slug,
          name: action.meta.arg.name,
          properties: action.payload.data,
          mainProperty: action.payload.property,
          lastProperty: action.payload.property.slug,
        }
      })
      .addCase(loadCurrentFarm.rejected, (state, action) => {
        state.currentFarm = {
          ...defaultState.currentFarm,
          status: 'failed',
          slug: action.meta.arg.slug,
          error: action.payload.message,
        }
      })
  },
})

export default farmSlice.reducer

export const { setCurrentFarm, resetCurrentFarm, setLastProperty, resetLastProperty, resetFarm } =
  farmSlice.actions

// State Selectors
export const getCurrentFarm = state =>
  state?.farm?.currentFarm
    ? {
        farm: state?.farm?.currentFarm.slug,
        property: state?.farm?.currentFarm?.lastProperty,
      }
    : {}

export const getFarm = state => state?.farm?.currentFarm

export const getProperties = state => state?.farm?.currentFarm?.properties

export const getFarmMeasure = state => state?.farm?.currentFarm?.measures ?? {}

export const getCurrentFarmSlug = state => state?.farm?.currentFarm?.slug ?? null

export const getFarmStatus = state => ({
  status: state?.farm?.currentFarm?.status,
  error: state?.farm?.currentFarm?.error,
  farm: state?.farm?.currentFarm,
})

export const getMainProperty = state => state?.farm?.currentFarm?.mainProperty ?? null

export const getMainPropertySlug = state => state?.farm?.currentFarm?.mainProperty?.slug ?? null

export const getCurrentProperty = state => {
  const { lastProperty, properties, name, slug } = state?.farm?.currentFarm
  const property = properties?.filter(p => p.slug === lastProperty)
  return property ? { ...property[0], farmName: name, farmSlug: slug } : null
}
