import { Form, Select, Col } from 'antd'
import { Translate } from 'lang'
import toLower from 'lodash/toLower'
import { useState } from 'react'
import qs from 'qs'
import axios from 'store/services/axiosConfig'
import { isEmpty } from 'utils'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

let timeout
let currentValue

const fetch = (value, controller, manipulateValues, callback) => {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }

  currentValue = value

  const request = () => {
    const str = qs.stringify({ q: value })

    axios({ url: `${controller}?${str}` }).then(res => {
      const { data } = res
      if (isEmpty(data)) {
        callback([
          {
            disabled: true,
            text: Translate({ messageKey: 'no_matching_results_found' }),
          },
        ])
      } else if (currentValue === value) {
        callback(manipulateValues(data))
      }
    })
  }
  timeout = setTimeout(request, 300)
}

/**
 * @function SearchForm - Form Radio
 * @param {string} title - texto
 * @param {boolean} disabled
 * * */
export const SearchForm = ({ value }) => {
  const {
    label,
    name,
    placeholder,
    defaultValue,
    disabled = false,
    controller,
    rules = [],
    required,
    multiple,
    cols = { xs: 24 },
    manipulateValues,
  } = value

  const [data, setData] = useState([])
  const [search, setSearch] = useState()

  const handleSearch = newValue => {
    if (newValue) {
      fetch(newValue, controller, manipulateValues, setData)
    } else {
      setData([])
    }
  }

  const handleChange = newValue => {
    setSearch(newValue)
  }

  const formatedRules = [
    {
      ...rules,
      ...(multiple ? { message: 'Por favor selecione uma opção', type: 'array' } : {}),
      required,
    },
  ]

  const defaultPlaceholder = Translate({
    messageKey: 'select',
    gender: 'male',
    items: {
      item: toLower(label),
    },
  })

  return (
    <Col {...cols}>
      <Form.Item name={name} label={label} rules={formatedRules} style={style.form} hasFeedback>
        <Select
          value={search}
          showSearch
          placeholder={placeholder ?? defaultPlaceholder}
          defaultValue={defaultValue}
          disabled={disabled}
          mode={multiple ? 'multiple' : null}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
          filterOption={false}
          defaultActiveFirstOption={false}
          showArrow={false}
        >
          {data.map(d => (
            <Select.Option key={`key-${d.value}`} value={d.value} disabled={d.disabled}>
              {d.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  )
}
