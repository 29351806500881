import PropTypes from 'prop-types'
import { Form, Col, Checkbox, Alert } from 'antd'
import { useEffect, useState } from 'react'
import { validateField } from 'components/FormBuilder/assets/validate'

/**
 * @function CheckboxForm - Form Checkbox
 * @param {string} label - texto para o label
 * @param {string} name - name para o formulário
 * @param {string} description - description para o checkbox
 * @param {string} description_type - icone para alerta abaixo do checkbox
 * @param {string} defaultValue - valor referente ao value do options
 * @param {boolean} disabled
 * * */
export const CheckboxForm = ({ value, allValues }) => {
  const {
    title,
    label,
    name,
    description,
    description_type,
    disabled = false,
    rules = [],
    condition = () => true,
    cols = { xs: 24 },
  } = value

  const [enabled, setEnabled] = useState(true)
  const [required, setRequired] = useState(false)

  useEffect(() => {
    const isEnabled = !disabled && condition(allValues)
    const isRequired = validateField(allValues, value.required)
    setEnabled(isEnabled)
    setRequired(isRequired && isEnabled)
  }, [allValues, setEnabled, setRequired])

  return (
    <Col {...cols}>
      <Form.Item name={name} label={title} rules={[...rules, { required }]} valuePropName="checked">
        <Checkbox disabled={!enabled} className="mb-1">
          {label}
        </Checkbox>
      </Form.Item>
      {description_type ? (
        <Alert description={description} type={description_type} showIcon />
      ) : (
        description && <p>{description}</p>
      )}
    </Col>
  )
}

CheckboxForm.propTypes = {
  value: PropTypes.object.isRequired,
}
