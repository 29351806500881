import { Form, Col, TimePicker } from 'antd'
import { validateField } from 'components/FormBuilder/assets/validate'
import { useEffect, useState } from 'react'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
  date: {
    width: '100%',
  },
}

export const TimeForm = ({ value, allValues }) => {
  const { name, label, disabled, rules = [], cols = { xs: 24 }, condition = () => true } = value

  const [enabled, setEnabled] = useState(true)
  const [required, setRequired] = useState(false)

  useEffect(() => {
    const isEnabled = !disabled && condition(allValues)
    const isRequired = validateField(allValues, value.required)
    setEnabled(isEnabled)
    setRequired(isRequired && isEnabled)
  }, [allValues, setEnabled, setRequired])

  const format = 'HH:mm'
  return (
    <Col {...cols}>
      <Form.Item name={name} label={label} rules={[...rules, { required }]} style={style.form}>
        <TimePicker style={style.date} disabled={!enabled} format={format} />
      </Form.Item>
    </Col>
  )
}
