import React from 'react'
import { Col } from 'antd'
import { MapsForm } from './maps'
import { DrawingForm } from './drawing'

const MapDrawingForm = ({ value, form, initialValues, allValues }) => {
  const type = form.getFieldValue('type') ?? value.markerType
  const { cols = { xs: 24 } } = value

  return (
    <Col {...cols}>
      {type === 'lake-dam-weir' || type === 'shelter-shadow' ? (
        <DrawingForm
          value={value}
          form={form}
          initialValues={{
            ...value,
            additional_lots: value?.limits,
            coordinates: initialValues?.polygon,
          }}
          zoom={15}
          height={50}
          name={'polygon'}
          center={value.center}
        />
      ) : (
        <MapsForm value={value} form={form} initialValues={initialValues} allValues={allValues} />
      )}
    </Col>
  )
}

export default MapDrawingForm
