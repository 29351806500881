import React from 'react'
import { Polyline } from '@react-google-maps/api'
import { uniqueId } from 'lodash'
import { PolylineDefaultOptions } from 'components/Maps/ManipulateMap/utils'

/**
 * It takes in an array of paths, and returns a Polyline component for each path
 * @returns An array of Polyline components.
 * Polygons didn't accept dots, so I had to use Polylines, which accepts icons.
 * But they would be a line and not a "closed square" to generate this
 * I needed to repeat the first position, so it closes the whole path
 */
export default function Polylines({ paths, options, onLoad = () => {} }) {
  if (!paths) return null

  return paths?.map(path => {
    const { name, coordinates } = path
    return (
      <Polyline
        key={uniqueId(name)}
        onLoad={map => onLoad(map, path)}
        /* Adding the first coordinate to the end of the array, so it closes the path. */
        path={[...coordinates, coordinates[0]]}
        options={{ ...PolylineDefaultOptions, options }}
      />
    )
  })
}
