import { uniqueId } from 'lodash'
import React from 'react'

export default function NotificationTitle({ infos }) {
  return (
    <div>
      {infos
        ?.filter(i => i.type === 'title')
        .map(({ text }) => (
          <h4 style={{ margin: 0 }} key={uniqueId()}>
            {text}
          </h4>
        ))}
    </div>
  )
}
