import React from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import Utils from 'utils'
import { Colors } from 'constants/Colors'

const styles = {
  male: {
    backgroundColor: Colors.male,
    verticalAlign: 'middle',
  },
  female: {
    backgroundColor: Colors.female,
    verticalAlign: 'middle',
  },
}

const AnimalGender = ({ value }) => {
  if (!value) return '-'
  return (
    <Avatar size={32} style={styles[value]} icon={value ? null : <UserOutlined />}>
      {Utils.getNameInitial(value)}
    </Avatar>
  )
}

export default AnimalGender
