import React from 'react'
import { Tooltip } from 'antd'
import Icon from '@mdi/react'
import {
  mdiBatteryOutline as mdiBattery0,
  mdiBattery30,
  mdiBattery50,
  mdiBattery80,
  mdiBattery as mdiBattery100,
} from '@mdi/js'
import { Translate } from 'lang'
import { Colors } from 'constants/Colors'

const IconsList = {
  0: {
    icon: mdiBattery0,
    color: Colors.danger,
  },
  1: {
    icon: mdiBattery30,
    color: Colors.danger,
  },
  2: {
    icon: mdiBattery50,
    color: Colors.waiting,
  },
  3: {
    icon: mdiBattery80,
    color: Colors.success,
  },
  4: {
    icon: mdiBattery100,
    color: Colors.success,
  },
}

const BatteryLevel = ({ value, style }) => {
  if (typeof value !== 'number') return '-'
  return (
    <div>
      <Tooltip title={Translate({ messageKey: ['batt_level', value] })}>
        <Icon path={IconsList[value].icon} color={IconsList[value].color} size={1} style={style} />
      </Tooltip>
    </div>
  )
}

export default BatteryLevel
