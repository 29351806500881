import React, { useEffect, useState } from 'react'
import { Grid, Modal as Dialog, Col, Row } from 'antd'
import Utils from 'utils'
import modalProps from 'components/Modal/ModalProps'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Colors } from 'constants/Colors'

const { useBreakpoint } = Grid

const Modal = ({ actionsDialog, cancel }) => {
  const { visible, title, width = 'md', onOk, content, onClose } = actionsDialog
  const [isModalVisible, setIsModalVisible] = useState(visible)
  const screens = Utils.getBreakPoint(useBreakpoint())

  let modalWidth
  let bodyStyle = {}
  switch (width) {
    case 'xs':
      modalWidth = '20%'
      break
    case 'sm':
      modalWidth = '35%'
      break
    case 'md':
      modalWidth = '50%'
      break
    case 'lg':
      modalWidth = '70%'
      break
    case 'xl':
      modalWidth = '80%'
      break
    case 'xxl':
      modalWidth = '90%'
      break
    case 'full':
      modalWidth = '95%'
      bodyStyle = { minHeight: '90vh' }
      break

    default:
      break
  }

  if (!screens.includes('md')) {
    modalWidth = '95%'
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    onClose()
    cancel && cancel()
  }
  useEffect(() => {
    setIsModalVisible(visible)
  }, [visible])

  const modalType = actionsDialog.type === 'delete' ? modalProps.delete : modalProps(title)
  return (
    <>
      <Dialog
        bodyStyle={bodyStyle}
        open={isModalVisible}
        onCancel={handleCancel}
        afterClose={onClose}
        width={modalWidth}
        transitionName=""
        destroyOnClose
        {...(onOk ? {} : { footer: null })}
        onOk={onOk ?? null}
        {...modalType}
      >
        {actionsDialog.type === 'delete' ? (
          <Row>
            <Col xs={2}>
              <ExclamationCircleFilled style={{ fontSize: '30px', color: Colors.danger }} />
            </Col>
            <Col xs={22} style={{ alignItems: 'center', display: 'flex' }}>
              <div>{content}</div>
            </Col>
          </Row>
        ) : (
          content
        )}
      </Dialog>
    </>
  )
}

export default Modal
