import React, { useCallback, useState } from 'react'
import { Form, Input, Button, Divider, Alert } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import HttpService from 'store/services/requestApi'
import { endpoint } from 'store/services/endpoints'
import { Colors } from 'constants/Colors'

const ForgotForm = ({ topMessage }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [send, setSend] = useState(false)
  const [message, setMessage] = useState()

  const onSend = useCallback(async values => {
    setLoading(true)
    const controller = endpoint.auth.forgot()
    await HttpService.post(controller, values)
    const timer = setTimeout(() => {
      setLoading(false)
      setMessage('Você receberá um e-mail com orientações para recuperar a sua senha.')
      setSend(true)
    }, 1500)

    setInterval(() => {
      setSend(false)
    }, 20000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div className="text-center">
        <p>{topMessage}</p>
      </div>
      {message && <Alert type="success" showIcon message={message} />}
      <Form form={form} layout="vertical" name="forget-password" onFinish={onSend}>
        <Form.Item
          name="email"
          label=" Informe seu e-mail"
          rules={[
            {
              required: true,
              message: 'Please input your email address',
            },
            {
              type: 'email',
              message: 'Please enter a validate email!',
            },
          ]}
        >
          <Input placeholder="Email Address" prefix={<MailOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            block
            disabled={send}
            style={send ? { backgroundColor: Colors.primary_hover } : null}
          >
            {loading ? 'Enviando' : send ? 'Enviado' : 'Recuperar senha'}
          </Button>
        </Form.Item>
      </Form>
      <div className="d-flex justify-content-center">
        <div>
          <Divider
            style={{
              width: 100,
              minWidth: 'unset',
              margin: '0 auto 24px',
            }}
          />
          <span>Já tem uma conta?</span>
          <a className="ml-3" href="/login">
            Entrar
          </a>
        </div>
      </div>
    </>
  )
}

export default ForgotForm
