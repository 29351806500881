import FormBuilder from 'components/FormBuilder'

const { Modal } = require('antd')
const { Translate } = require('lang')

const ModalForm = ({ handleSubmit, handleCancel, open, title, data, controller, refresh }) => {
  const { inputs, answers } = data
  if (!inputs) {
    return null
  }

  const filterInputs = inputs?.map(input => {
    if (input?.name === 'date') {
      return {
        ...input,
        required: true,
        condition: ({ reason }) => reason === 'illness' || reason === 'gave_birth',
      }
    }
    if (input?.type === 'radio') {
      return {
        ...input,
        direction: 'column',
      }
    }
    return input
  })

  const form = [
    ...filterInputs,
    {
      type: 'footer',
      position: 'end',
      onClose: () => handleCancel(),
      buttons: [
        {
          type: 'button-cancel',
          label: Translate({ messageKey: 'cancel' }),
        },
        {
          type: 'button-confirm',
          label: Translate({ messageKey: 'confirm' }),
        },
      ],
    },
  ]
  return (
    <Modal title={title} open={open} onOk={handleSubmit} onCancel={handleCancel} footer={null}>
      <FormBuilder
        name={'notification-alert-zero-steps'}
        data={form}
        rowGap={20}
        controller={controller}
        initialValues={answers}
        method={'PUT'}
        afterOk={() => {
          refresh(true)
          handleCancel()
        }}
      />
    </Modal>
  )
}

export default ModalForm
