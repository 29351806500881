import React from 'react'
import { Tag } from 'antd'

const styles = {
  tag: {
    borderRadius: 100,
    textTransform: 'capitalize',
  },
}

const defaultColors = {
  black: '0,0,0',
  white: '255,255,255',
  red: '255,0,0',
  lime: '0,255,0',
  blue: '0,0,255',
  yellow: '255,255,0',
  cyan: '0,255,255',
  magenta: '255,0,255',
  silver: '192,192,192',
  gray: '128,128,128',
  maroon: '128,0,0',
  olive: '128,128,0',
  green: '0,128,0',
  purple: '128,0,128',
  teal: '0,128,128',
  navy: '0,0,128',
  orange: '247,144,9',
}

/**
 * It returns a Tag component with a color and value prop
 * @returns A Tag component with a color and value prop.
 */
const Badge = ({ color, value, text }) => {
  if (!value) return '-'

  const border = defaultColors[color] ? `1px solid rgba(${defaultColors[color]}, 0.3)` : null

  return (
    <Tag color={color || 'default'} style={{ border, ...styles.tag, color: text }}>
      {value}
    </Tag>
  )
}

export default Badge
