import isEmpty from 'lodash/isEmpty'
import { withRouter } from 'react-router'
import getUrlBase from 'utils/getUrlBase'
import Storage from 'utils/storage'
import UserManager from 'utils/userManager'

class HttpService {
  /**
   * @function get - GET to an URL
   * @param {string} endpoint - api endpoint e.g api/login
   * @param {object} data - data object to send to API
   * @param {object} options - options parameters to fetch javascript function
   * * */
  static async get(endpoint, data, options) {
    return HttpService.request('GET', endpoint, undefined, options)
  }

  /**
   * @function post - POST to an URL
   * @param {string} endpoint - api endpoint e.g api/login
   * @param {object} data - data object to send to API
   * @param {object} options - options parameters to fetch javascript function
   * * */
  static async post(endpoint, data, options) {
    return HttpService.request('POST', endpoint, data, options)
  }

  /**
   * @function put - PUT to an URL
   * @param {string} endpoint - api endpoint e.g api/login
   * @param {object} data - data object to send to API
   * @param {object} options - options parameters to fetch javascript function
   * * */
  static async put(endpoint, data, options) {
    return HttpService.request('PUT', endpoint, data, options)
  }

  /**
   * @function delete - DELETE to an URL
   * @param {string} endpoint - api endpoint e.g api/login
   * @param {object} data - data object to send to API
   * @param {object} options - options parameters to fetch javascript function
   * * */
  static async delete(endpoint, data, options) {
    return HttpService.request('DELETE', endpoint, data, options)
  }

  /**
   * @function request - Ajax to given URL
   * @param {string} method - GET/PUT/POST/DELETE
   * @param {string} endpoint - api endpoint e.g api/login
   * @param {object} data - data object to send to API
   * @param {object} options - options parameters to fetch javascript function
   * * */
  static async request(method, endpoint, data, options = {}) {
    const isInstabovApi = !endpoint.startsWith('http')
    const urlPrefix = process.env.REACT_APP_API_URL

    const controller = isInstabovApi ? `${urlPrefix}${endpoint}` : endpoint

    const config = HttpService.getConfig(method, data, options)

    const response = await fetch(controller, config)

    if (endpoint === 'auth/logout') return null

    let responseBody = await response.json()

    if (response.ok) {
      return {
        data: responseBody,
        code: response?.status,
        status: 'success',
      }
    }
    if (response?.status) {
      if (!isEmpty(window)) {
        switch (response?.status) {
          case 400:
            if (responseBody.message === 'user does not Root') {
              window.location.replace(getUrlBase())
            }
            return {
              data: responseBody.errors ?? responseBody.errors[0].message,
              status: 'error',
              code: 400,
            }
          case 401:
            if (isInstabovApi) {
              Storage.clear()
              window.location.replace('/')
            } else {
              return {
                data: responseBody.errors ?? responseBody.error,
                status: 'error',
                code: 401,
              }
            }
            break
          case 422: {
            return {
              data: responseBody.error ?? responseBody.errors,
              status: 'error',
              code: response?.status,
            }
          }
          case 404:
            return {
              data: responseBody.errors[0].message,
              status: 'error',
              code: 404,
            }
          default:
            return {
              data: responseBody?.errors ?? 'Unknown Error',
              status: 'error',
              code: response?.status,
            }
        }
      } else {
        console.error(responseBody.error)
      }
    }

    throw new Error('Unknown error')
  }

  static getConfig(method, params, options) {
    const customHeaders = options.headers || {}
    const token = JSON.parse(localStorage.getItem('instabov_token'))

    let config = {
      method,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept-Language': UserManager.get('locale') ?? 'pt-BR',
        ...customHeaders,
      },
      body: undefined,
    }

    if (!isEmpty(params)) {
      config.body = JSON.stringify(params)
    }

    return config
  }
}

export default withRouter(HttpService)
