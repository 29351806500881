import { useEffect, useState } from 'react'
import HttpService from 'store/services/requestApi'

export default function useRequest(props) {
  const { method = 'get', controller, params, options, then } = props
  const [response, setResponse] = useState({ data: null })
  useEffect(() => {
    if (controller) {
      HttpService[method](controller, params, options).then(response => {
        if (response) setResponse(response)
        if (then) then()
      })
    }
  }, [controller])

  return response
}
