import { Form, Input, Col } from 'antd'
import { Translate } from 'lang'
import toLower from 'lodash/toLower'
import { cpf, cnpj } from 'cpf-cnpj-validator'
import '../assets/cpf-cnpj.css'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

export const CpfCnpjForm = ({ value }) => {
  const { name, label, disabled, rules = [], required, placeholder, cols = { xs: 24 } } = value
  const defaultPlaceholder = Translate({
    messageKey: 'inform',
    gender: 'male',
    items: {
      item: toLower(label),
    },
  })
  return (
    <Col {...cols}>
      <Form.Item
        name={name}
        label={label}
        className="input-cpf"
        hasFeedback
        rules={[
          { required },
          {
            validator: (_, value) =>
              value && (cpf.isValid(value) || cnpj.isValid(value))
                ? Promise.resolve()
                : Promise.reject('CPF/CNPJ inválido'),
          },
          ...rules,
        ]}
        style={style.form}
      >
        <Input type="number" placeholder={placeholder ?? defaultPlaceholder} disabled={disabled} />
      </Form.Item>
    </Col>
  )
}
