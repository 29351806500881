import React from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/Colors'
import { CheckOutlined } from '@ant-design/icons'

import AlertColor from 'components/Notifications/Manipulate/AlertColor'
import { Row } from 'antd'
import selectIcon from 'utils/selectIcon'
import Icon from '@mdi/react'

const StyledDescription = styled(`p`)`
  color: #455560;
  margin-bottom: 5px;
  line-height: 20px !important;
`
const StyledTitle = styled(`p`)`
  color: #333333 !important;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 20px !important;
  margin-right: 0.5rem;
`
const StyledTimestamp = styled(`p`)`
  color: #999999 !important;
  margin: 0;
  font-size: 12px;
`
const Box = styled(`div`)`
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  padding: 15px 20px;
  width: 100%;
  cursor: pointer;
`
const IconBox = styled(`div`)`
  display: flex;
`

export default function NotificationAlert({
  title,
  body,
  time,
  is_read,
  type = 'warning',
  withBackground = true,
  onClick,
  is_answered,
}) {
  const color = AlertColor(type)

  const alertStyle = {
    border: `1px solid ${is_read ? Colors.gray_medium : color}99`,
    backgroundColor: `${is_read ? Colors.gray_medium : color}1a`,
  }

  const IconWrapper = styled.div`
    padding: 5px;
    border: 1px solid ${props => props.borderColor || Colors.gray_light};
    border-radius: 200px;
    vertical-align: middle;
    max-width: 44px;
    max-height: 44px;
  `
  const style = {
    icon: {
      borderColor: is_read ? '#A2A2A2' : color,
      backgroundColor: is_read ? '#A2A2A2' : color,
      color: '#fff',
      borderRadius: 200,
      padding: 5,
    },
    text: {
      color: is_read ? '#A2A2A2' : color,
    },
  }

  const iconProps = {
    path: selectIcon(type),
    size: 1.8,
    style: {
      ...style.icon,
      borderColor: color,
      color: '#fff',
    },
  }

  return (
    <>
      <Box style={withBackground ? alertStyle : { padding: 0 }} onClick={onClick}>
        <IconBox>
          {/* <AlertIcons type={type} /> */}
          {[
            'plot_alert',
            'possible_birth',
            'external_alert',
            'internal_alert',
            'fall_device_alert',
          ].includes(type) ? (
            <IconWrapper style={style.icon}>
              <img
                src={selectIcon(type)}
                alt="custom icon"
                style={{ width: '33px', height: '33px' }}
              />
            </IconWrapper>
          ) : (
            <Icon {...iconProps} />
          )}
        </IconBox>
        <div>
          <Row>
            <StyledTitle>{title}</StyledTitle>
            {is_answered ? <CheckOutlined style={{ color: Colors.green }} /> : null}
          </Row>
          <StyledDescription>{body}</StyledDescription>
          <StyledTimestamp>{time}</StyledTimestamp>
        </div>
      </Box>
    </>
  )
}
