import React from 'react'
import { createRoot } from 'react-dom/client'

import tryCache from 'utils/trycache'
import App from './App'
import './index.css'

/* A function that removes expired items from the browser's local storage. */
tryCache.clearExpired()

const root = createRoot(document.getElementById('root'))
root.render(<App />)
