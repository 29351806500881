import React from 'react'
import { NAV_TYPE_TOP, PRIMARY_COLOR } from 'constants/ThemeConstant'
import utils from 'utils'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import MenuContent from './MenuContent'

const styles = {
  light: {
    overflow: 'hidden',
  },
  dark: {
    borderTop: `1px solid ${PRIMARY_COLOR}`,
    overflow: 'hidden',
  },
}

export const TopNav = () => {
  const { currentTheme } = useSelector(state => state.theme, isEqual)
  return (
    <div
      className={`top-nav ${utils.getColorContrast(PRIMARY_COLOR)}`}
      style={styles[currentTheme]}
    >
      <div className="top-nav-wrapper">
        <MenuContent type={NAV_TYPE_TOP} />
      </div>
    </div>
  )
}

export default TopNav
