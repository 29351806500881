import { Form, Select } from 'antd'
import { useHistory } from 'react-router-dom'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Utils from 'utils'

import { useDispatch, useSelector } from 'react-redux'
import { getCurrentFarm, getProperties, setLastProperty } from 'redux/reducers/Farm'
import { isEqual } from 'lodash'
import getUrlBase from 'utils/getUrlBase'

const { Option } = Select

export default function FarmMenu() {
  const screens = Utils.getBreakPoint(useBreakpoint())
  const isMobile = !screens.includes('md')

  return !isMobile && <PropertySelect />
}

export const PropertySelect = ({ mobile }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const properties = useSelector(getProperties, isEqual)
  const { property } = useSelector(getCurrentFarm, isEqual)

  function handleChange({ property }) {
    dispatch(setLastProperty(property))
    history.push(getUrlBase())
  }

  return property ? (
    <Form className="d-flex p-3" initialValues={{ property }} onValuesChange={handleChange}>
      <Form.Item name={'property'} style={{ margin: 'auto 0', width: '100%' }}>
        <Select loading={!property} style={!mobile ? { width: 250 } : {}}>
          {properties?.map(p => (
            <Option key={p.id} value={p.slug} selected={p.slug === property}>
              {p.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  ) : null
}
