import React from 'react'
import { Drawer } from 'antd'
import { connect, useSelector } from 'react-redux'
import { NAV_TYPE_SIDE } from 'constants/ThemeConstant'
import { onMobileNavToggle } from 'redux/actions/Theme'
import Flex from 'components/Flex'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { getIsAdmin } from 'redux/reducers/App'
import { isEqual } from 'lodash'
import { PropertySelect } from './FarmMenu'
import { LogoInsideMobile } from './Logo'
import MenuContent from './MenuContent'

export const MobileNav = ({
  sideNavTheme,
  mobileNav,
  onMobileNavToggle,
  routeInfo,
  hideGroupTitle,
  localization = true,
}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization }
  const isAdmin = useSelector(getIsAdmin, isEqual)

  const onClose = () => {
    onMobileNavToggle(false)
  }

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      open={mobileNav}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <LogoInsideMobile />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>

        <div className="mobile-nav-menu">
          {!isAdmin ? <PropertySelect mobile /> : null}
          <MenuContent type={NAV_TYPE_SIDE} {...props} />
        </div>
      </Flex>
    </Drawer>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme, mobileNav } = theme
  return { navCollapsed, sideNavTheme, mobileNav }
}

export default connect(mapStateToProps, { onMobileNavToggle })(MobileNav)
