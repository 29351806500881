import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SideNav from 'components/layout-components/SideNav'
import TopNav from 'components/layout-components/TopNav'
import Loading from 'components/Loading'
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav'
import Footer from 'components/layout-components/Footer'
import AppViews from 'views/App/routes'
import { Layout, Grid } from 'antd'

import utils from 'utils'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { getContext, getIsAdmin } from 'redux/reducers/App'
import useFirebaseMessaging from '@useweb/firebase/useFirebaseMessaging'
import Storage from 'utils/storage'
import FirebaseForegroundMessage from 'store/services/Firebase/foregroundMessage'
import { endpoint } from 'store/services/endpoints'
import axiosInstance from 'store/services/axiosConfig'
import { isEqual } from 'lodash'
import { getPermissions } from 'redux/reducers/Permissions'

const { Content } = Layout
const { useBreakpoint } = Grid

export const AppLayout = () => {
  const { sideNavWidth = 80, currentTheme } = useSelector(state => state.theme, isEqual)
  const dispatch = useDispatch()
  const context = useSelector(getContext, isEqual)
  const { status, switcher, themes } = useThemeSwitcher()
  const isRoot = useSelector(getIsAdmin, isEqual)
  const screens = utils.getBreakPoint(useBreakpoint())
  const isMobile = !screens.includes('lg')

  useEffect(() => {
    switcher({ theme: themes[currentTheme] })
  }, [currentTheme])

  const getLayoutGutter = () => {
    if (isMobile) {
      return 0
    }
    return sideNavWidth
  }

  const firebaseMessaging = useFirebaseMessaging({
    onMessage: FirebaseForegroundMessage,
  })

  useEffect(() => {
    firebaseMessaging.init()
    dispatch(getPermissions())
  }, [])

  useEffect(() => {
    const { isReadyToUse, fcmRegistrationToken } = firebaseMessaging
    if (isReadyToUse && !Storage.get('fcmRegistrationToken')) {
      Storage.set('fcmRegistrationToken', fcmRegistrationToken)
      //BACKEND SAVE
      axiosInstance.post(endpoint.firebase.register({ token: fcmRegistrationToken }))
    }
  }, [firebaseMessaging.isReadyToUse])

  if (status === 'loading') {
    return <Loading cover="page" />
  }

  const getLayoutDirectionGutter = () => {
    return !isRoot ? { paddingLeft: getLayoutGutter() } : null
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} context={context} />
      {isRoot && !isMobile ? <TopNav localization /> : null}
      <Layout className="app-container">
        {!isRoot && !isMobile ? <SideNav /> : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isRoot ? 'layout-top-nav' : ''}`}>
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  )
}

export default React.memo(AppLayout)
