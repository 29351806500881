import { combineReducers } from 'redux'
import Auth from './Auth'
import Theme from './Theme'
import App from './App'
import Farm from './Farm'
import Permissions from './Permissions'

export const rootReducers = combineReducers({
  theme: Theme,
  auth: Auth,
  app: App,
  farm: Farm,
  permissions: Permissions,
})

export default rootReducers
