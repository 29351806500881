class Storage {
  static get(key) {
    try {
      const val = localStorage.getItem(`instabov_${key}`)
      return val && JSON.parse(val)
    } catch (err) {
      return null
    }
  }

  static set(key, value) {
    localStorage.setItem(`instabov_${key}`, JSON.stringify(value))
  }

  static remove(key) {
    localStorage.removeItem(`instabov_${key}`)
  }

  static clear() {
    localStorage.clear()
  }
}

export default Storage
