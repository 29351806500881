import { Form, Input, Col } from 'antd'
import { validateField } from 'components/FormBuilder/assets/validate'
import { Translate } from 'lang'
import toLower from 'lodash/toLower'
import { useEffect, useState } from 'react'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

/**
 * @function TextAreaForm - Form TextArea
 * * */
export const TextAreaForm = ({ value, allValues }) => {
  const {
    name,
    label,
    disabled,
    rules = [],
    placeholder,
    cols = { xs: 24 },
    size = { minRows: 4, maxRows: 6 },
    condition = () => true,
  } = value

  const [enabled, setEnabled] = useState(true)
  const [required, setRequired] = useState(false)

  useEffect(() => {
    const isEnabled = !disabled && condition(allValues)
    const isRequired = validateField(allValues, value.required)
    setEnabled(isEnabled)
    setRequired(isRequired && isEnabled)
  }, [allValues, setEnabled, setRequired])

  const defaultPlaceholder = Translate({
    messageKey: 'inform',
    gender: 'male',
    items: {
      item: toLower(label),
    },
  })

  return (
    <Col {...cols}>
      <Form.Item name={name} label={label} rules={[...rules, { required }]} style={style.form}>
        <Input.TextArea
          disabled={!enabled}
          placeholder={placeholder ?? defaultPlaceholder}
          autoSize={size}
        />
      </Form.Item>
    </Col>
  )
}
