import Moment from 'components/Moment'

/**
 * It takes a value prop and returns a formatted date
 */
const Timestamp = ({ value }) => {
  if (!value) return '-'
  return Moment.view(value)
}

export default Timestamp
