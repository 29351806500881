import React from 'react'
import { Space } from 'antd'
import { uniqueId } from 'lodash'

function CardDropdown({ items }) {
  return (
    <Space direction="vertical">
      {items?.map(({ label, value, separator = ': ' }) => (
        <div
          style={{ display: 'flex', gap: 10, alignItems: 'center', margin: '2px 0' }}
          key={uniqueId()}
        >
          <span>
            {label}
            {separator}
          </span>
          <span>{value}</span>
        </div>
      ))}
    </Space>
  )
}

export default CardDropdown
