import React, { useCallback, useState } from 'react'
import { Button, Form, Input, Divider, Alert } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { login } from 'redux/reducers/Auth'
import { useDispatch } from 'react-redux'

export const LoginForm = props => {
  let history = useHistory()
  let dispatch = useDispatch()

  const { showForgetPassword, extra } = props
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(false)

  const onLogin = useCallback(
    async values => {
      setLoading(true)
      setMessage(null)
      try {
        dispatch(
          login({
            ...values,
            cb: v => {
              setMessage(v)
              setLoading(false)
            },
          }),
        ).then(response => {
          if (response.meta.requestStatus === 'fulfilled') {
            history.push(`/`)
          }
        })
      } catch (err) {
        console.warn('unknown error')
      }
      setLoading(false)
    },
    [dispatch],
  )

  return (
    <>
      {message && <Alert type="error" showIcon message={message} />}
      <Form layout="vertical" name="login-form" onFinish={onLogin}>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Insira seu email', //TODO
            },
            {
              type: 'email',
              message: 'Insira um email valido!', //TODO
            },
          ]}
        >
          <Input
            placeholder="Digite seu e-mail" //TODO
            prefix={<MailOutlined className="text-primary" />}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Senha" //TODO
          rules={[
            {
              required: true,
              message: 'Insira sua senha', //TODO
            },
          ]}
        >
          <Input.Password
            placeholder="Digite sua senha" //TODO
            prefix={<LockOutlined className="text-primary" />}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Entrar
            {/* TODO */}
          </Button>
        </Form.Item>
        {showForgetPassword && (
          <>
            <Divider style={{ width: 100, minWidth: 'unset', margin: '24px auto' }} />
            <div className="d-flex justify-content-center">
              {/* TODO */}
              <span>Esqueceu sua senha?</span>
              <a className="ml-3" href="/forgot">
                Recuperar
              </a>
            </div>
          </>
        )}
        {extra}
      </Form>
    </>
  )
}

LoginForm.propTypes = {
  showForgetPassword: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

LoginForm.defaultProps = {
  showForgetPassword: false,
}

export default LoginForm
