import styled from 'styled-components'
import { Typography, Row, Col, Button } from 'antd'
import { Colors } from 'constants/Colors'
import FormatCell from 'components/FormatCell'
import { mdiAlertOutline, mdiPlusThick } from '@mdi/js'
import Icon from '@mdi/react'
import PregnantStatus from 'assets/svgs/pregnancy-status.svg'

const { Link } = Typography
const Wrapper = styled.div`
  font-weight: 400;
  line-height: 18px;
  padding: 8px;
  & > p {
    color: black;
    margin: 0;
  }
  & > strong {
    display: block;
    text-transform: uppercase;
  }
  & > .subtitle {
    color: #afafaf;
    display: block;
    text-transform: uppercase;
  }
  & > .content {
    margin: 10px 0;
  }
  & > .content > p {
    margin: 0;
  }
  & > .links {
    margin-top: 10px;
  }
  & > .links > a {
    display: block;
  }
`

/**
 *{
    title: 'title',
    subtitle: 'subtitle',
    content: [ { key, value } ],
    link: [ { key, value } ]
  }
 */
export default function InfoMap({ data }) {
  if (!data) return null
  const { content, title, subtitle, link, type } = data

  switch (type) {
    case 'device': {
      return (
        <Row>
          <Col span={13} style={styles.colLeft}>
            <FormatCell
              type="avatar"
              subtype="animal"
              title={data.earring}
              subtitle={data.name}
              isDeleted={false}
              has_device
            />
            {data.has_alert === true && (
              <Icon
                path={mdiAlertOutline}
                size={1.3}
                style={{
                  color: Colors.warning,
                }}
              />
            )}
          </Col>
          <Col flex={1} style={styles.colRight}>
            <Icon path={mdiPlusThick} size={0.95} style={styles.iconPlusThick(data.is_sick)} />
            {data.is_pregnant && (
              <img src={PregnantStatus} alt="status de gravidez" style={styles.pregnantStatusImg} />
            )}
          </Col>
          <Col span={14} style={styles.colMiddle}>
            <div style={styles.colMiddleInner}>
              {data?.cattle_status?.map(item => {
                return (
                  <FormatCell
                    key={item.type}
                    type="animal-status"
                    subType={item.type}
                    label={item.label}
                    value={item.value}
                    color={item.color}
                  />
                )
              })}
            </div>
            {link ? (
              <div style={styles.colMiddleLink}>
                <Link key={`link-${link}`} href={link}>
                  <Button type={'primary'} size="small">
                    Acessar animal
                  </Button>
                </Link>
              </div>
            ) : null}
          </Col>
          <Col flex={1} style={styles.colInfo}>
            {data?.info_animal?.map((item, index) => {
              return (
                <FormatCell
                  key={`info-animal-${index}`}
                  type="animal-info"
                  value={item.value}
                  subType={item.type}
                />
              )
            })}
          </Col>
        </Row>
      )
    }

    default:
      return (
        <Wrapper>
          {title ? <strong>{title}</strong> : null}
          {subtitle ? <p className="subtitle">{subtitle}</p> : null}
          {content ? (
            <div className="content">
              {content?.map(({ key, value }, index) => (
                <p key={`content-${index}`}>
                  {key} : {value}
                </p>
              ))}
            </div>
          ) : null}
          {link ? (
            <div className="links">
              {link.map(({ key, value }, index) => {
                return (
                  <Link key={`link-${index}`} href={value}>
                    {key}
                  </Link>
                )
              })}
            </div>
          ) : null}
        </Wrapper>
      )
  }
}

const styles = {
  colLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginBottom: 15,
  },
  colRight: {
    display: 'flex',
    alignItems: 'center',
    gap: 7,
    justifyContent: 'end',
    marginBottom: 15,
  },
  colMiddle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 10,
    justifyContent: 'space-around',
  },
  colMiddleInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  colMiddleLink: {
    marginTop: 10,
  },
  colInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconPlusThick: isSick => ({
    color: '#fff',
    backgroundColor: isSick ? '#D64A4A' : '#86BC6F',
    borderRadius: 60,
  }),
  pregnantStatusImg: {
    width: '28px',
    height: '28px',
  },
}
