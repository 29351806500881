import { Alert, Col } from 'antd'
import {
  ExclamationCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'

const styles = {
  root: {
    marginBottom: 10,
  },
}

/**
 * @function AlertForm - Mensagem de alerta
 * @param {string} message
 * @param {string} alertType
 * * */
const icons = {
  info: <ExclamationCircleOutlined style={{ color: '#9fc9fc' }} />,
  warning: <WarningOutlined style={{ color: '#faad14' }} />,
  error: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />,
  success: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
}
export const AlertForm = ({ value }) => {
  const { message, alertType = 'info', cols = { xs: 24 }, showIcon } = value

  return (
    <Col {...cols}>
      <div style={styles.root}>
        <Alert message={message} type={alertType} showIcon={showIcon} icon={icons[alertType]} />
      </div>
    </Col>
  )
}
