import React, { lazy, Suspense } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Loading from 'components/Loading'
import { mapRoutes } from 'store/services/routes'

const Root = lazy(() => import(`./B2B/routes`))
const Farm = lazy(() => import(`./B2C/routes`))
const User = lazy(() => import(`./UserAccount`))
const NotFound = lazy(() => import(`./404`))

export const AppViews = () => {
  const ROUTES = [
    {
      path: `/`,
      name: 'dashboard',
      exact: true,
      component: Root,
    },
    {
      path: `/user`,
      name: 'user',
      component: User,
    },
    {
      path: `/admin`,
      name: 'root',
      component: Root,
    },
    {
      path: `/farm`,
      name: 'farm',
      component: Farm,
    },
    {
      path: `/farm/:farm`,
      name: 'farm-farm',
      component: Farm,
    },
  ]
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {mapRoutes(ROUTES)}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  )
}

export default withRouter(AppViews)
