import { Form, Select, Col } from 'antd'
import { Translate } from 'lang'
import toLower from 'lodash/toLower'
import useAxios from 'store/hooks/useAxios'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { validateField } from 'components/FormBuilder/assets/validate'
import { uniqueId } from 'lodash'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

/**
 * @function SelectForm - Form Radio
 * @param {string} title - texto
 * @param {boolean} disabled
 * * */
export const SelectForm = ({ value, allValues = {} }) => {
  const axios = useAxios

  const {
    label,
    name,
    placeholder,
    options,
    defaultValue,
    disabled = false,
    rules = [],
    multiple,
    cols = { xs: 24 },
    controller,
    filters,
    manipulateValues,
    condition = () => true,
    visible = () => true,
    ...rest
  } = value
  const [enabled, setEnabled] = useState(true)
  const [required, setRequired] = useState(false)

  useEffect(() => {
    const isEnabled = !disabled && condition(allValues)
    const isRequired = validateField(allValues, value.required)
    setEnabled(isEnabled)
    setRequired(isRequired && isEnabled)
  }, [allValues, setEnabled, setRequired])

  const formatedRules = [
    {
      ...rules,
      ...(multiple ? { message: 'Por favor selecione uma opção', type: 'array' } : {}),
      required,
    },
  ]

  const defaultPlaceholder = Translate({
    messageKey: 'select',
    gender: rest.gender ? rest.gender : 'male',
    items: {
      item: toLower(label),
    },
  })

  const hide = () => {
    if (!visible(allValues)) {
      return { style: { display: 'none' } }
    }
    return {}
  }
  /* Request do Options form */
  let request = {}
  if (controller) {
    const str = qs.stringify({ limit: 9999, ...filters })
    request = axios({ controller: `${controller}?${str}` })
  }

  let list
  if (options) {
    list = options
  } else if (manipulateValues) {
    list = manipulateValues(request.data)
  } else {
    list = request.data
  }

  return (
    <Col {...cols} {...hide()}>
      <Form.Item name={name} label={label} rules={formatedRules} style={style.form} hasFeedback>
        <Select
          allowClear
          placeholder={placeholder ?? defaultPlaceholder}
          defaultValue={defaultValue}
          disabled={!enabled}
          mode={multiple ? 'multiple' : null}
          loading={!list}
        >
          {list?.map(option => (
            <Select.Option key={uniqueId(option.value)} {...option}>
              {option.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  )
}
