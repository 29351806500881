import { Col } from 'antd'
import { getInput } from '..'

export const Block = ({ value, form, initialValues, allValues }) => {
  const { inputs, cols = { xs: 24 }, visible = () => true } = value
  const hide = () => {
    if (!visible(allValues)) {
      return { style: { display: 'none' } }
    }
    return {}
  }

  return (
    <Col {...cols} {...hide()}>
      {getInput(inputs, initialValues, form, allValues)}
    </Col>
  )
}
