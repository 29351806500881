import {
  TOGGLE_COLLAPSED_NAV,
  NAV_TYPE_CHANGE,
  TOGGLE_MOBILE_NAV,
  SWITCH_THEME,
  SIDE_NAV_WIDTH,
} from '../constants/Theme'

export function toggleCollapsedNav(navCollapsed) {
  return {
    type: TOGGLE_COLLAPSED_NAV,
    navCollapsed,
  }
}

export function toggleSideNavWidth(sideNavWidth) {
  return {
    type: SIDE_NAV_WIDTH,
    sideNavWidth,
  }
}

export function onNavTypeChange(navType) {
  return {
    type: NAV_TYPE_CHANGE,
    navType,
  }
}

export function onMobileNavToggle(mobileNav) {
  return {
    type: TOGGLE_MOBILE_NAV,
    mobileNav,
  }
}

export function onSwitchTheme(currentTheme) {
  return {
    type: SWITCH_THEME,
    currentTheme,
  }
}
