import React from 'react'

const styles = {
  badgeColor: {
    width: 20,
    height: 20,
    borderRadius: '50%',
  },
}

const Badge = ({ type, value }) => {
  if (!value) return '-'
  switch (type) {
    case 'color':
      return <div style={{ ...styles.badgeColor, backgroundColor: value }} />

    default:
      break
  }
}

export default Badge
