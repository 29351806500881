import { camelCase, isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { getUserPermissions } from 'redux/reducers/Permissions'
import Utils from 'utils'

/**
 * Hook que retorna as permissões do usuário com base nas permissões fornecidas.
 * como utilizar uma permissão gestao-de-contas-farm::management
 * @param {Array} permissions - As permissões necessárias. ex.: gestao-de-contas-farm::management, podendo ser: [management, reader, no-access]
 * @returns {Array} - As permissões do usuário formatadas em um objeto camelCase ex.: gestaoDeContasFarmManagement.
 */

export const usePermissions = permissions => {
  const userPermissions = useSelector(getUserPermissions, isEqual)

  //Verification
  const arr = permissions.map(key => {
    // Check isAdmin, All true
    if (Array.isArray(userPermissions) && userPermissions.includes('is_admin'))
      return { [camelCase(key)]: true }

    const [module, permission] = key.split(':')
    const userPermission = userPermissions[module]

    //Do not have permission key, false
    if (!userPermission) return { [camelCase(key)]: false }

    if (userPermission === 'management') {
      return {
        [camelCase(key)]: true,
      }
    }
    return {
      [camelCase(key)]: userPermission === permission,
    }
  })
  return Utils.objectToArray(arr)
}
