import Icon from '@mdi/react'
import { Col, Row, Tooltip, Typography } from 'antd'
import selectIcon from 'utils/selectIcon'

const BatteryVariation = ({ value: batteryArr }) => {
  const colors = {
    minimum_battery: '#ee6665',
    variation_battery: '#72c0de',
    maximum_battery: '#91cb75',
  }
  return (
    <Row justify="center" align="middle">
      {batteryArr?.map(item => (
        <Col span={8}>
          <Tooltip title={item.value !== '-' ? `${item.label}` : null}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 3,
                margin: '0 2px',
                cursor: 'default',
              }}
            >
              <Icon path={selectIcon(item.type)} size={0.8} color={colors[item.type]} />
              <Typography.Text>{item.value}</Typography.Text>
            </div>
          </Tooltip>
        </Col>
      ))}
    </Row>
  )
}

export default BatteryVariation
