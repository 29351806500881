import React, { useState, useEffect } from 'react'
import Loading from 'components/Loading'
import { Card, Row, Col } from 'antd'
import { LOGO_V } from 'constants/ThemeConstant'
import LoginForm from '../Form/LoginForm'
import ForgotForm from '../Form/ForgotForm'
import NewPasswordForm from '../Form/NewPassword'

const backgroundStyle = {
  backgroundImage: 'url(/instabov/login.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}

export default function Authentication({ type }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 250)

    return () => clearTimeout(timer)
  }, [])

  return loading ? (
    <Loading cover="page" />
  ) : (
    <div className="auth-container">
      <div className="h-100" style={backgroundStyle}>
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={7}>
              <Card>
                <div className="my-4">
                  <div className="text-center">
                    <img className="img-fluid mb-4" src={LOGO_V} alt="" />
                  </div>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                      {
                        {
                          login: <LoginForm otherSignIn={false} showForgetPassword />,
                          forgot: (
                            <ForgotForm
                              topMessage={
                                'Você receberá um e-mail com orientações para recuperar a sua senha'
                              }
                            />
                          ),
                          reset: <NewPasswordForm topMessage={'Informe a sua nova senha.'} />,
                        }[type]
                      }
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
