import { Translate } from 'lang'
import { uniqueId } from 'lodash'
import React from 'react'

export default function NotificationText({ infos }) {
  return (
    <div>
      {infos
        ?.filter(i => i.type === 'string')
        .map(({ text, value }) => (
          <p style={{ margin: '5px 0' }} key={uniqueId()}>
            <b>{Translate({ messageKey: text })}</b>: {value}
          </p>
        ))}
    </div>
  )
}
