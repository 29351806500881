import { Modal, Result } from 'antd'
import { Translate } from 'lang'
import React from 'react'

export function infoModal(data, status, code, afterOk, messages) {
  const OkText = 'Fechar'

  const Code422BackendMessage = Array.isArray(data) ? (
    data?.map((error, index) => (
      <React.Fragment key={`error-${index}`}>
        <Translate messageKey={error.message} />
        <br />
        {error.field ? (
          <Translate
            messageKey={'error'}
            items={{
              item: Translate({ messageKey: error.field }),
              rule: Translate({
                messageKey: error?.rule,
                ...(error.args
                  ? {
                      items: {
                        item: `${error?.args[error?.rule]}`,
                      },
                    }
                  : {}),
              }),
            }}
          />
        ) : null}
      </React.Fragment>
    ))
  ) : (
    <>{data?.errors && data?.errors[0]?.message}</>
  )

  const result = {
    200: {
      status: 'success',
      title: 'Sucesso',
      subTitle: messages?.success ?? 'Sua solicitação foi realizada.',
    },
    422: {
      status: 'warning',
      subTitle: messages?.failed ?? Code422BackendMessage,
    },
    error: {
      status: 'error',
      title: 'Erro',
      subTitle: data
        ? Array.isArray(data) &&
          data?.map((error, i) => <p key={`error-message-${i}`}>{error.message}</p>)
        : Translate({ messageKey: 'generic_error' }),
    },
  }

  let secondsToGo = code === 200 ? 5 : 30
  const modal = Modal.info({
    icon: null,
    centered: true,
    content: <Result {...(result[code] ?? result.error)} style={{ padding: 0 }} />,
    okText: `${secondsToGo} ${OkText}`,
    onOk() {
      clearInterval(timeout)
      Modal.destroyAll()
      if (code === 200) afterOk && afterOk()
    },
  })
  const timer = setInterval(() => {
    secondsToGo -= 1
    modal.update({
      okText: `${secondsToGo} ${OkText}`,
    })
  }, 1000)

  const timeout = setTimeout(() => {
    clearInterval(timer)
    Modal.destroyAll()
    if (code === 200) afterOk && afterOk()
  }, secondsToGo * 1001)
}
