import { Translate } from 'lang'

const confirm = Translate({ messageKey: 'confirm' })
const cancel = Translate({ messageKey: 'cancel' })
const remove = Translate({ messageKey: 'remove' })

const modalProps = title => ({
  title,
  centered: true,
  okText: confirm,
  cancelText: cancel,
  icon: null,
})

modalProps.delete = {
  width: '30%',
  centered: true,
  okType: 'danger',
  okText: remove,
  cancelText: cancel,
  closable: false,
}

export default modalProps
