import React, { useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { darkMode, stylesConfig } from 'components/Maps/ManipulateMap/darkmode'
import { containerStyle } from './style'

const APIKEY = process.env.REACT_APP_API_MAPS_KEY
const libraries = ['drawing', 'visualization']

function Maps(props) {
  const {
    dark,
    children,
    center,
    onDblClick,
    onClick = () => {},
    options,
    zoom = 17,
    style,
    handleOnLoad,
    mapTypeId,
  } = props
  const mapRef = useRef(null)
  const [zoomMap, setZoomMap] = useState(zoom)
  const [position, setPosition] = useState(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: APIKEY,
    libraries,
  })

  function handleZoomChanged() {
    setZoomMap(this.getZoom())
  }

  function handleCenter() {
    if (!mapRef.current) return
    const newPos = mapRef.current.getCenter().toJSON()
    setPosition(newPos)
  }

  /* If zoom change */
  React.useEffect(() => {
    setZoomMap(zoom)
    return () => {}
  }, [zoom])

  return isLoaded ? (
    <GoogleMap
      onLoad={map => {
        handleOnLoad(map)
        window.google.maps.event.addListener(map, 'click', () => {
          document.querySelectorAll('.gm-style-iw-a').forEach(el => el.remove())
        })
        mapRef.current = map
      }}
      onDragEnd={handleCenter}
      onZoomChanged={handleZoomChanged}
      mapContainerStyle={{ ...containerStyle, ...style }}
      center={position ?? center}
      onDblClick={onDblClick}
      options={{
        ...options,
        styles: [...stylesConfig, ...(dark ? darkMode : [])],
        mapTypeId,
        zoom: zoomMap,
      }}
      onClick={onClick}
    >
      {children}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default Maps

Maps.defaultProps = {
  center: {
    lat: -29.6894983,
    lng: -53.7923441,
  },
  dark: false,
  handleOnLoad: () => {},
  mapTypeId: 'hybrid',
  options: {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
  },
}
