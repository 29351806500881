import { Colors } from 'constants/Colors'
import UserManager from 'utils/userManager'

const theme = UserManager.get('theme') ?? 'light'

export const SIDE_NAV_NOT_COLLAPSED_WIDTH = 250
export const SIDE_NAV_COLLAPSED_WIDTH = 80
export const SIDE_NAV_LIGHT = 'SIDE_NAV_LIGHT'
export const SIDE_NAV_DARK = 'SIDE_NAV_DARK'
export const NAV_TYPE_SIDE = 'SIDE'
export const NAV_TYPE_TOP = 'TOP'
export const DIR_LTR = 'ltr'
export const DIR_RTL = 'rtl'

/**
 * Instabov const
 */

export const FAVICON = '/favicon.ico'
export const LOGO = '/instabov/logo.png'
export const LOGO_V = '/instabov/logo_vertical.png'
export const LOGO_TEXT = '/instabov/logo_text.png'
export const THEME = theme
export const PRIMARY_COLOR = theme === 'dark' ? Colors.primary_dark : Colors.primary
