import { Colors } from 'constants/Colors'
import styled from 'styled-components'

export const ScrollableContent = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.primary_hover};
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${Colors.primary};
  }
  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 4px;
  }
`
