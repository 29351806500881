import { mdiAccountEdit, mdiCogOutline, mdiLogoutVariant, mdiBackburger } from '@mdi/js'
import { Translate } from 'lang'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { getIsSystemUser } from 'redux/reducers/Auth'

/** SubMenu Off */
export const Profile = ({ context, farm, permissions }) => {
  const PREFIX = context === 'farm' ? `/farm/${farm}` : ''
  const isSystemUser = useSelector(getIsSystemUser, isEqual)

  return [
    {
      title: Translate({ messageKey: 'profile' }),
      icon: mdiAccountEdit,
      path: `${PREFIX}/user/profile`,
      enabled: true,
    },
    {
      title: Translate({
        messageKey: 'setting',
        howMany: 'many',
        items: {
          de: 'de',
          item: Translate({ messageKey: 'account' }),
        },
      }),
      enabled: context === 'farm' && permissions.configuracoesDaFazendaReader,
      icon: mdiCogOutline,
      path: `${PREFIX}/account/settings`,
    },
    {
      title: 'Voltar para lista de contas',
      icon: mdiBackburger,
      path: `/`,
      enabled: context === 'farm' && isSystemUser,
    },
    {
      title: Translate({ messageKey: 'sign_out' }),
      icon: mdiLogoutVariant,
      path: `/logout`,
      enabled: true,
    },
  ]
}
