import { notification } from 'antd'
import AlertIcons from 'components/Notifications/Manipulate/AlertIcons'

export default function FirebaseForegroundMessage(message) {
  const { pathname } = window.location
  const isFarm = pathname.split('/')?.[1] === 'farm'

  // Dispara evento para atualização da badge
  window.dispatchEvent(new Event('new-alert'))

  if (isFarm) {
    const type = message?.data?.type || 'warning'

    return notification.open({
      message: message.notification.title,
      description: message.notification.body,
      onClick: () => {
        window.location.hash = `notification=${message.data.id}`
        notification?.destroy()
      },
      icon: <AlertIcons type={type} size="small" />,
      placement: 'bottomRight',
      style: { width: 400 },
      duration: 10,
    })
  }
}
