import { Form, Input, Col, Row } from 'antd'
import { Translate } from 'lang'
import toLower from 'lodash/toLower'
import Maps from 'components/Maps'
import React, { useCallback, useEffect, useState } from 'react'
import Markers from 'components/Maps/Markers'
import Geocode from 'react-geocode'
import axios from 'axios'
import { isEqual } from 'lodash'
import { PolygonDefaultOptions, Polygons } from 'components/Maps/ManipulateMap/utils'
import Polylines from 'components/Maps/Polyline'

const APIKEY = process.env.REACT_APP_API_MAPS_KEY

Geocode.setApiKey(APIKEY)
const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

export const MapsForm = ({ value, form, initialValues, allValues }) => {
  const {
    rules = [],
    required,
    cols = { xs: 24 },
    center = { lat: 0, lng: 0 },
    markerType,
    limits,
    property_limits,
    header = true,
  } = value
  const [localization, setLocalization] = useState()
  const [tnz, setTnz] = useState()
  const [flag, setFlag] = useState(false)
  const [selectLocalization, setSelectLocalization] = useState()

  useEffect(() => {
    if ((!selectLocalization || flag) && initialValues?.lat && initialValues?.lng) {
      setLocalization({
        lat: Number(initialValues.lat),
        lng: Number(initialValues.lng),
      })
    } else {
      setLocalization(center)
    }
  }, [flag, selectLocalization])

  useEffect(() => {
    if (localization) {
      getTimeZone(localization)
      form.setFieldsValue(localization)
    }
  }, [localization])

  useEffect(() => {
    if (tnz) {
      form.setFieldsValue({ time_zone: tnz })
    }
  }, [tnz])
  const type = form.getFieldValue('type') ?? markerType

  useEffect(() => {
    const localization = form.getFieldValue('localization')
    if (!isEqual(selectLocalization, localization)) {
      setSelectLocalization(localization)
    }
  }, [allValues])

  useEffect(() => {
    if (selectLocalization) {
      let address = []

      Object.keys(selectLocalization).forEach(key => {
        if (selectLocalization[key]) {
          address.push(selectLocalization[key].split('-')[1])
        }
      })

      // Get latitude & longitude from address.
      Geocode.fromAddress(address.join(' - ')).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location
          console.table('Searching address:', address)
          setLocalization({ lat, lng })
          setFlag(true)
        },
        error => {
          console.error(error)
        },
      )
    }
  }, [selectLocalization])

  const getTimeZone = useCallback(async ({ lat, lng }) => {
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=${Math.floor(
      Date.now() / 1000,
    )}&key=${APIKEY}`
    try {
      await axios.get(url).then(response => {
        setTnz(response.data.rawOffset / 3600)
      })
    } catch (e) {
      console.error(e)
      return 0
    }
  }, [])

  const onDblClick = useCallback(async map => {
    const value = { lat: map.latLng.lat(), lng: map.latLng.lng() }
    setLocalization(value)
  }, [])

  const placeholder = label =>
    Translate({
      messageKey: 'inform',
      gender: 'female',
      items: {
        item: toLower(label),
      },
    })

  const onChange = useCallback(async event => {
    setLocalization(prevState => {
      const lat = event.target.name === 'lat' ? Number(event.target.value) : prevState?.lat ?? 0
      const lng = event.target.name === 'lng' ? Number(event.target.value) : prevState?.lng ?? 0
      return { lat, lng }
    })
  }, [])

  const onPolygonClick = useCallback(event => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    setLocalization({ lat, lng })
  }, [])

  const options = () => ({
    ...PolygonDefaultOptions,
    fillColor: 'red',
    strokeColor: 'yellow',
  })

  const LotsLimits = React.useMemo(
    () => <Polygons lots={limits} options={options} onDblClick={onPolygonClick} />,
    [limits],
  )

  const PropertyLimits = React.useMemo(
    () => <Polylines key={'limits'} paths={property_limits} />,
    [property_limits],
  )

  return (
    <Col {...cols}>
      <Row gutter={16} key={'input-localization'} style={{ display: !header ? 'none' : 'flex' }}>
        <Col xs={12} key={'lat'}>
          <Form.Item
            name={'lat'}
            label={Translate({ messageKey: 'Latitude' })}
            rules={[{ required }, ...rules]}
            style={style.form}
          >
            <Input
              type="number"
              name={'lat'}
              placeholder={placeholder(Translate({ messageKey: 'Latitude' }))}
              onChange={onChange}
            />
          </Form.Item>
        </Col>
        <Col xs={12} key={'lng'}>
          <Form.Item
            name={'lng'}
            label={Translate({ messageKey: 'Longitude' })}
            rules={[{ required }, ...rules]}
            style={style.form}
          >
            <Input
              type="number"
              name={'lng'}
              placeholder={placeholder(Translate({ messageKey: 'Longitude' }))}
              onChange={onChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} key={'map-marker'}>
        <Col xs={24}>
          <Maps
            center={localization}
            onDblClick={onDblClick}
            zoom={localization?.lat !== 0 ? 15 : 2}
          >
            {LotsLimits}
            {PropertyLimits}
            {localization?.lat !== 0 && <Markers markers={{ ...localization, type }} />}
          </Maps>
        </Col>
      </Row>
    </Col>
  )
}
