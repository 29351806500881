// import { Profile } from './Profile'
import { useSelector } from 'react-redux'
import { getCurrentFarm } from 'redux/reducers/Farm'
import { getContext } from 'redux/reducers/App'
import { isEqual } from 'lodash'
import { usePermissions } from 'store/hooks/usePermissions'
import { Farm } from './Farm'
import { Root } from './Root'

function filterEnabledItems(menuItems) {
  const filteredItems = []

  for (const item of menuItems) {
    if (item.enabled || item.submenu) {
      if (item.submenu && item.submenu.length > 0) {
        const filteredSubmenu = filterEnabledItems(item.submenu)
        if (filteredSubmenu.length > 0) {
          const itemWithFilteredSubmenu = { ...item, submenu: filteredSubmenu }
          filteredItems.push(itemWithFilteredSubmenu)
        }
      } else {
        filteredItems.push(item)
      }
    }
  }

  return filteredItems
}

const MenuItems = () => {
  const context = useSelector(getContext, isEqual)
  const { farm, property } = useSelector(getCurrentFarm, isEqual)

  const permissions = usePermissions([
    // Ambiente Root
    'system:reader',
    'gerenciamento-root:reader',
    'gestao-de-hardware:reader',
    'gestao-de-contas-farm:reader',
    // Ambiente Farm
    'system-farm:reader',
    'gestao-de-rebanho:reader',
    'gestao-de-piquetes:reader',
    'gestao-de-dispositivos:reader',
  ])

  const prefix = `/${context}/${farm}/property/${property}`
  const menu = context === 'farm' ? Farm({ prefix, permissions }) : Root({ permissions })

  return filterEnabledItems(menu)
}

export default MenuItems
