const getUrlBase = () => {
  const { currentFarm } = window.globalStore.getState().farm

  const lastPropertySlug = currentFarm.lastProperty
  const mainPropertySlug = currentFarm.mainProperty.slug

  const lastProperty = currentFarm.properties.find(property => property.slug === lastPropertySlug)

  const targetPropertySlug = lastProperty ? lastPropertySlug : mainPropertySlug

  return `/farm/${currentFarm.slug}/property/${targetPropertySlug}`
}

export default getUrlBase
