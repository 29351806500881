import PropTypes from 'prop-types'
import { Form, Radio, Col, Space } from 'antd'
import { useEffect, useState } from 'react'
import '../assets/radio.css'
import { validateField } from 'components/FormBuilder/assets/validate'

function Wrapper({ direction, children }) {
  return direction === 'column' ? <Space direction="vertical">{children}</Space> : children
}

/**
 * @function RadioForm - Form Radio
 * @param {string} label - texto para o label
 * @param {string} name - name para o formulário
 * @param {object} options - objeto de opções {value: 'any, text: 'any}
 * @param {string} defaultValue - valor referente ao value do options
 * @param {boolean} disabled
 * * */
export const RadioForm = ({ value, allValues }) => {
  const {
    label,
    name,
    options,
    disabled = false,
    rules = [],
    direction = 'row',
    condition = () => true,
    visible = () => true,
    cols = { xs: 24 },
  } = value

  const [enabled, setEnabled] = useState(true)
  const [required, setRequired] = useState(false)

  useEffect(() => {
    const isEnabled = !disabled && condition(allValues)
    const isRequired = validateField(allValues, value.required)
    setEnabled(isEnabled)
    setRequired(isRequired && isEnabled)
  }, [allValues, setEnabled, setRequired])

  const hide = () => {
    if (!visible(allValues)) {
      return { style: { display: 'none' } }
    }
    return {}
  }

  return (
    <Col {...cols} {...hide()}>
      <Form.Item
        label={label}
        name={name}
        rules={[...rules, { required }]}
        className={`radio-${direction}`}
      >
        <Radio.Group disabled={!enabled}>
          <Wrapper direction={direction}>
            {options.map(option => (
              <Radio key={`key-${option.value}`} value={option.value}>
                {option.text}
              </Radio>
            ))}
          </Wrapper>
        </Radio.Group>
      </Form.Item>
    </Col>
  )
}

RadioForm.propTypes = {
  value: PropTypes.object.isRequired,
}
