/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Layout } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme'
import utils from 'utils'
import { useLocation } from 'react-router-dom'
import FarmMenu from 'components/layout-components/FarmMenu'
import GlobalSearch from 'components/GlobalSearch'
import NavNotification from 'components/Notifications'
import { isEqual } from 'lodash'
import FarmConnectionStatus from 'components/ConnectionFarm'
import Logo from './Logo'
import NavProfile from './NavProfile'

const { Header } = Layout

export const HeaderNav = props => {
  const { currentTheme } = useSelector(state => state.theme, isEqual)

  const { context, navCollapsed, mobileNav, toggleCollapsedNav, onMobileNavToggle, isMobile } =
    props
  const { pathname } = useLocation()

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }
  const isProperty = pathname.split('/')[3] === 'property'
  const isNavTop = context === 'root'

  const mode = () => {
    return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
  }

  const navMode = mode()

  return (
    <Header className={`app-header ${navMode}`}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo />
        <div className="nav" style={{ flex: 1 }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle()
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
            {/* GLOBALSEARCH */}
            {isProperty ? <GlobalSearch /> : null}
          </div>
          <div id="header-top-farm-middle" />
          <div className="nav-right">
            {!isNavTop && (
              <>
                {isProperty ? <FarmMenu /> : null}
                <FarmConnectionStatus />
                <NavNotification />
              </>
            )}
            <NavProfile />
          </div>
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, mobileNav } = theme
  return { navCollapsed, navType, mobileNav }
}

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav)
