import { createContext } from 'react'

export const StoreToken = createContext({
  instabov_token: null,
  setToken: () => {},
})

export const User = createContext({
  user: null,
  setUser: () => {},
})

export const Property = createContext({
  property: null,
  setProperty: () => {},
})

export const Environment = createContext({
  Env: null,
  setEnvironment: () => {},
})
