import React, { useContext } from 'react'
import { Menu, Dropdown, Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import Icon from '@mdi/react'
import { User } from 'store/context'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { getCurrentFarm } from 'redux/reducers/Farm'
import { getContext } from 'redux/reducers/App'
import { Colors } from 'constants/Colors'
import { isEqual } from 'lodash'
import { usePermissions } from 'store/hooks/usePermissions'
import { Profile } from './MenuItems/Profile'

const getIcon = icon => (
  <Icon path={icon} size={0.8} style={{ marginBottom: -5, marginRight: 10 }} />
)
const style = {
  avatar: {
    color: Colors.primary,
    backgroundColor: Colors.primary_light,
  },
}
const avatar = username => {
  return (
    <Avatar style={style.avatar} icon={username ? null : <UserOutlined />}>
      {username ? username[0] : null}
    </Avatar>
  )
}

export const NavProfile = () => {
  const { farm } = useSelector(getCurrentFarm, isEqual)
  const context = useSelector(getContext, isEqual)

  const permissions = usePermissions(['configuracoes-da-fazenda:reader'])

  const { user } = useContext(User)
  const ProfileMenu = Profile({ context, farm, permissions })

  const menuItens = ProfileMenu.filter(item => item.enabled !== false).map(item => {
    return {
      key: item.title,
      label: (
        <Link to={item.path}>
          <div style={{ width: '100%', color: '#484848' }}>
            {getIcon(item.icon)}
            <span className="font-weight-normal">{item.title}</span>
          </div>
        </Link>
      ),
    }
  })

  const profileMenu = [
    {
      key: 'profileMenu',
      type: 'group',
      label: (
        <div className="nav-profile">
          <div className="nav-profile-header">
            <div className="d-flex align-items-center">
              {avatar(user?.name)}
              <div style={{ paddingLeft: 10 }}>
                <p className="mb-0">{user?.name}</p>
                <small>{user?.email}</small>
              </div>
            </div>
          </div>
        </div>
      ),
      children: menuItens,
    },
  ]

  const ImageItem = [
    {
      key: '1',
      label: avatar(user?.name),
    },
  ]
  return (
    <Dropdown
      placement="bottomRight"
      menu={{
        items: profileMenu,
      }}
      trigger={['click']}
    >
      <Menu items={ImageItem} className="d-flex align-item-center" mode="horizontal" />
    </Dropdown>
  )
}

export default NavProfile
