import React from 'react'
import { Form, Col, Upload, Button } from 'antd'
import { Translate } from 'lang'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

/**
 * @function FileForm - Formulário de Upload de Arquivo
 * @param {object} value - Objeto com as propriedades do input de arquivo
 * @param {object} allValues - Todos os valores do formulário
 * */
export const FileForm = ({ value, allValues, form }) => {
  const {
    name,
    label,
    disabled,
    rules = [],
    required,
    placeholder,
    visible = () => true,
    cols = { xs: 24 },
  } = value

  const defaultPlaceholder = Translate({
    messageKey: 'Selecione os arquivos',
  })

  const hide = () => {
    if (!visible(allValues)) {
      return { style: { display: 'none' } }
    }
    return {}
  }

  const handleOnChange = async e => {
    form.setFieldsValue({ [name]: e })
  }

  return (
    <Col {...cols} {...hide()}>
      <Form.Item
        name={name}
        valuePropName={name}
        label={label}
        rules={[...rules, { required }]}
        style={{ ...style.form }}
      >
        <Upload disabled={disabled} multiple beforeUpload={() => false} onChange={handleOnChange}>
          <Button disabled={disabled}>{placeholder ?? defaultPlaceholder}</Button>
        </Upload>
      </Form.Item>
    </Col>
  )
}
