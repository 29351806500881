import { THEME_CONFIG } from 'configs/AppConfig'
import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_NOT_COLLAPSED_WIDTH } from 'constants/ThemeConstant'
import {
  TOGGLE_COLLAPSED_NAV,
  CHANGE_LOCALE,
  SIDE_NAV_STYLE_CHANGE,
  NAV_TYPE_CHANGE,
  TOP_NAV_COLOR_CHANGE,
  TOGGLE_MOBILE_NAV,
  SWITCH_THEME,
  SIDE_NAV_WIDTH,
} from '../constants/Theme'

const initTheme = {
  ...THEME_CONFIG,
}

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        sideNavWidth: action.navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_NOT_COLLAPSED_WIDTH,
        navCollapsed: action.navCollapsed,
      }
    case SIDE_NAV_WIDTH:
      return {
        ...state,
        sideNavWidth: action.sideNavWidth,
      }
    case SIDE_NAV_STYLE_CHANGE:
      return {
        ...state,
        sideNavTheme: action.sideNavTheme,
      }
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      }
    case NAV_TYPE_CHANGE:
      return {
        ...state,
        navType: action.navType,
      }
    case TOP_NAV_COLOR_CHANGE:
      return {
        ...state,
        topNavColor: action.topNavColor,
      }
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav,
      }
    case SWITCH_THEME:
      return {
        ...state,
        currentTheme: action.currentTheme,
      }
    default:
      return state
  }
}

export default theme
