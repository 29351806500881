/* eslint-disable no-unused-vars */
/* prettier-ignore */
export const endpoint = {
  user:                                                 (data = {}) => `user/show`, 
  permissions:                                          (data = {}) => `user/modules`, 
  firebase:{
    register:                                           (data = {}) => `user/token-notification/${data.token}`,
  },
  auth: {
    login:                                              (data = {}) => `auth/login`,
    logout:                                             (data = {}) => `auth/logout`,
    forgot:                                             (data = {}) => `auth/forgot`,
    reset:                                              (data = {}) => `auth/reset`,
  },
  dashboard:{
    show:                                               (data = {}) => `admin/dashboard`,
  },
  properties:{
    show:                                               (data = {}) => `${data.context}/dashboard/${data.farm}`,
    index:                                              (data = {}) => `${data.context}/${data.farm}/properties/${data.property}`,
  },
  profile:{
    self:                                               (data = {}) => `profile`,
    show:                                               (data = {}) => `profile/show`,
  },
  users:{
    show:                                               (data = {}) => `${data.context}/users/${data.user}`, //REMOVE
  },
  root:{
    application:{
      show:                                             (data = {}) => `admin/account/application_ttn`,
    },
    plans:{
      show:                                             (data = {}) => `admin/plans`,
      self:                                             (data = {}) => `admin/plans/${data.id}`
    },
    users:{
      show:                                             (data = {}) => `admin/users`,
      self:                                             (data = {}) => `admin/users/${data.id}`
    },
    farm_account:{
      show:                                             (data = {}) => `admin/account/farms`,
      self:                                             (data = {}) => `admin/account/farms/${data.id}`,
    },
    functionalities:{
      show:                                             (data = {}) => `admin/functionalities`,
    },
    modules:{
      show:                                             (data = {}) => `admin/modules/${data.resource}`,
      self:                                             (data = {}) => `admin/modules/${data.resource}/${data.id}`,
    },
    roles:{
      show:                                             (data = {}) => `admin/roles/${data.resource}`,
      self:                                             (data = {}) => `admin/roles/${data.resource}/${data.id}`,
    },
    devices:{
      show:                                             (data = {}) => `admin/devices`,
      logs:                                             (data = {}) => `admin/devices-logs/${data.device}`,
      self:                                             (data = {}) => `admin/devices/${data.device}`,
      detail:                                           (data = {}) => `admin/devices/${data.device}/show`,
    },
    gateways:{
      show:                                             (data = {}) => `admin/gateways`,
      self:                                             (data = {}) => `admin/gateways/${data.gateway}`,
      detail:                                           (data = {}) => `admin/gateways/details/${data.gateway}`,
      archives:{
        show:                                           (data = {}) => `admin/gateways/${data.gateway}/file`,
        self:                                           (data = {}) => `admin/gateways/${data.gateway}/file/uploads`,
        delete:                                         (data = {}) => `admin/gateways/${data.gateway}/file/${data.file_name}`,
      }
    }
  },
  farm:{
    self:                                               (data = {}) => `farm/dashboard/${data.farm}`,
    search:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/search`,
    forms:{
      cattle_batch_update:                              (data = {}) => `farm/${data.farm}/properties/${data.property}/forms/cattle-plot-update/${data.animal}`,
      cattle_delete:                                    (data = {}) => `farm/${data.farm}/properties/${data.property}/forms/delete-cattle/${data.animal}`,
      cattle_weighing:                                  (data = {}) => `farm/${data.farm}/properties/${data.property}/forms/weighting-create/${data.animal}`,
      childbirth:                                       (data = {}) => `farm/${data.farm}/properties/${data.property}/forms/childbirth-create/${data.animal}`,
      childbirth_edit:                                  (data = {}) => `farm/${data.farm}/properties/${data.property}/forms/childbirth-edit/${data.animal}`,
    },
    roles:{
      show:                                             (data = {}) => `farm/${data.farm}/roles`,
    },
    access:{
      map:                                              (data = {}) => `farm/${data.farm}/properties/${data.property}/map`,
      dashboard:                                        (data = {}) => `farm/${data.farm}/properties/${data.property}/dashboard`,
      chart:{
        bar:                                            (data = {}) => `farm/${data.farm}/properties/${data.property}/dashboard/graph/bar`,
        pie:                                            (data = {}) => `farm/${data.farm}/properties/${data.property}/dashboard/graph/pie`,
      }
    },
    breeds:{
      show:                                             (data = {}) => `farm/breeds`,
    },
    users:{
      show:                                             (data = {}) => `farm/${data.farm}/users`,
      self:                                             (data = {}) => `farm/${data.farm}/users/${data.user}`,
    },
    properties:{
      show:                                             (data = {}) => `farm/${data.farm}/properties`,
      self:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}`,
      center_area:                                      (data = {}) => `farm/${data.farm}/properties/${data.property}/center_area`,
    },
    batch:{
      show:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/plots`,
      self:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/plots/${data.batch}`,
      logs:{
        show:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/plots/${data.batch}/logs`,
        self:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/plots/log/${data.batch}`,
      },
      info:{
        show:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/plots/${data.batch}/card`,
      },
      graphs:{
        hour:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/plots/${data.batch}/graph/hour-by-hour`,
        day:                                            (data = {}) => `farm/${data.farm}/properties/${data.property}/plots/${data.batch}/graph/24-hours`,
      }
    },
    cattle:{
      show:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles`,
      self:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.herd}`,
      move_batch:                                       (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.herd}/plot-movement`,
      details:                                          (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/details`,
      map:                                              (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/map`,
      structures:                                       (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/structures-history`,
      childbirth:                                       (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/childbirth`,
      cattle_weighing:                                  (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/weight-history`,
      alerts:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/alerts`,
      history:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/life-history`,
      graphs:{
        hour:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/graph/hour-by-hour`,
        day:                                            (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/graph/24-hours`,
        displacement:                                   (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/graph/one-day`,
        structures:                                     (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/structures-history/graph`,
        weight:                                         (data = {}) => `farm/${data.farm}/properties/${data.property}/cattles/${data.animal}/weight-history-list`,
      }
    },
    piquete:{
      show:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/lots`,
      self:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/lots/${data.lot}`,
      form:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/lots/list`,
      getPlots:                                         (data = {}) => `farm/${data.farm}/properties/${data.property}/lots/plots`,
      heatmap:{
        show:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/lots/${data.lot}/plots/${data.time}`,
      },
      forms:{
        new:                                            (data = {}) => `farm/${data.farm}/properties/${data.property}/lots/forms`,
        edit:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/lots/forms/${data.lot}`,
      }
    },
    devices:{
      list:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/devices`,
      show:                                             (data = {}) => `farm/${data.farm}/devices`,
      logs:                                             (data = {}) => `farm/${data.farm}/devices/logs/${data.device}`,
      self:                                             (data = {}) => `farm/${data.farm}/devices/${data.device}`,
      detail:                                           (data = {}) => `farm/${data.farm}/devices/details/${data.device}`,
      download:                                         (data = {}) => `farm/${data.farm}/devices/details/${data.device}/download`,
      graph:                                            (data = {}) => `farm/${data.farm}/devices/details/${data.device}/graph`,
    },
    structures:{
      show:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/structures`,
      self:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/structures/${data.structure}`,
      forms:{
        create:                                         (data = {}) => `farm/${data.farm}/properties/${data.property}/structures/forms`,
        update:                                         (data = {}) => `farm/${data.farm}/properties/${data.property}/structures/forms/${data.structure}`,
      }
      
    },
    measure:{
      show:                                             (data = {}) => `farm/${data.farm}/settings/units`,
      self:                                             (data = {}) => `farm/${data.farm}/settings/units`,
    },
    settings:{
      hardware:                                         (data = {}) => `farm/${data.farm}/settings/hardware`,
      measure:                                          (data = {}) => `farm/${data.farm}/settings/units`,
      growth:                                           (data = {}) => `farm/${data.farm}/settings/growth-phases`,
      alerts:                                           (data = {}) => `farm/${data.farm}/settings/point-alerts`,
      forms:{
        hardware_form:                                  (data = {}) => `farm/${data.farm}/forms/settings/hardware`,
        hdop_form:                                      (data = {}) => `farm/${data.farm}/forms/settings/hardware/package-accuracy`,
      }
    },
    boudaries:{
      show:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/boundaries`,
      self:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/boundaries/${data.boundary}`,
    },
    notifications:{
      show:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/notifications`,
      unread:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/notifications/unread`,
      self:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/notifications/${data.notification}`,
      read:{
        list:                                           (data = {}) => `farm/${data.farm}/properties/${data.property}/notifications/mark-as-read`,
        all:                                            (data = {}) => `farm/${data.farm}/properties/${data.property}/notifications/mark-as-read/all`,
      },
      form:{
        zero_steps_alert:                               (data = {}) => `farm/${data.farm}/properties/${data.property}/notifications/${data.notification}`,
      }
    },
    status:{
      show:                                             (data = {}) => `farm/${data.farm}/properties/${data.property}/status`,
    }
  }
}
