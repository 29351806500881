import { mdiAutorenew, mdiSyncAlert, mdiCheck, mdiMinusCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { Tooltip } from 'antd'
import { Colors } from 'constants/Colors'
import { Translate } from 'lang'

const IconsList = {
  success: {
    icon: mdiCheck,
    color: Colors.green,
  },
  pending: {
    icon: mdiAutorenew,
    color: Colors.waiting,
  },
  error: {
    icon: mdiSyncAlert,
    color: Colors.danger,
  },
  no_movement: {
    icon: mdiMinusCircleOutline,
    color: Colors.gray_medium,
  },
}

const StatusIcon = ({ value, style }) => {
  if (!value) return '-'
  return (
    <Tooltip title={Translate({ messageKey: `status_${value}` })}>
      <div>
        <Icon path={IconsList[value].icon} color={IconsList[value].color} size={1} style={style} />
      </div>
    </Tooltip>
  )
}
export default StatusIcon
