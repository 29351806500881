import { createSlice } from '@reduxjs/toolkit'

const splitLanguage = lang => lang.split(/[-_]/)[0]

const initialState = {
  language: 'pt-BR',
  context: 'root',
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentLanguage(state, action) {
      const { language } = action.payload
      state.language = language

      //Set document language
      document.documentElement.lang = splitLanguage(language)
    },
    setContext(state, action) {
      state.context = action?.payload
    },
    resetContext(state) {
      state.context = initialState?.context
    },
  },
})

export const getLanguage = state => state?.app?.language
export const getContext = state => state?.app?.context
export const getIsAdmin = state => state?.app?.context === 'root'

export const { setCurrentLanguage, setContext, resetContext } = appSlice.actions

export default appSlice.reducer
