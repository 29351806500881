import { Tag } from 'antd'
import { Colors } from 'constants/Colors'
import { Translate } from 'lang'
import { uniqueId } from 'lodash'
import React from 'react'

export default function NotificationTags({ infos }) {
  return (
    <div className="tags">
      {infos
        ?.filter(i => i.type === 'tag' && i.value)
        .map(tag => {
          return tag.text === 'gender'
            ? {
                value: Translate({ messageKey: tag.value }),
                color: Colors?.[tag.value],
              }
            : tag
        })
        .map(e => (
          <Tag key={uniqueId()} color={e.color ?? Colors.gray_medium}>
            {e.value}
          </Tag>
        ))}
    </div>
  )
}
