import Storage from 'utils/storage'

class CurrentLanguage {
  static get() {
    const user = Storage.get('user')
    return user?.locale ?? 'pt-BR'
  }

  static set(value) {
    const user = Storage.get('user')
    if (typeof value === 'object') {
      return Storage.set('user', { ...user, ...value })
    }
    return Storage.set('user', { ...user, locale: value })
  }
}

export default CurrentLanguage
