import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { getCurrentFarm } from 'redux/reducers/Farm'

const StringLink = ({ value, to, slug, farm, property, id, name }) => {
  const { pathname } = useLocation()
  let link = pathname

  switch (to) {
    case 'batch':
      link += `/${slug}`
      break

    case 'device':
      link += `/${slug}/${value}`
      break

    case 'root-device':
      link += `/${farm}/${slug}/${value}`
      break

    case 'gateway':
      link += `/${slug}/${name}`
      break

    case 'animal':
      link = `/farm/${farm}/property/${property}/herd/animals/${id}`
      break

    default:
      break
  }
  return link
}

const Link = props => {
  if (!props.value) return null
  const { farm, property } = useSelector(getCurrentFarm, isEqual)

  return (
    <RouterLink to={StringLink({ farm, property, ...props })}>
      {props.children || props.value}
    </RouterLink>
  )
}

Link.string = StringLink

export default Link
