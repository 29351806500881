import { Colors } from 'constants/Colors'
import switchFn from 'utils/switchFn'

const shadow = 'drop-shadow(0px 1px 0.3px rgb(0 0 0 / 0.6))'

export const MarkersTypes = {
  'farm': () => ({
    iconFill: 'white',
    fill: Colors.info,
    path: 'm5.96,10.82v-2.78h1.85v2.78h2.31v-3.7h1.39L6.88,2.95,2.26,7.12h1.39v3.7h2.31Z',
  }),
  'shelter-shadow': () => ({
    iconFill: 'black',
    fill: Colors.waiting,
    path: 'm10.18,10.66l-2.76-2.76.48-.48,2.76,2.76-.48.48Zm-5.98-.48c-.36-.39-.64-.85-.82-1.35-.18-.48-.27-.99-.27-1.51,0-.55.1-1.09.3-1.59.43-1.06,1.26-1.89,2.32-2.32.99-.39,2.1-.41,3.1-.04.5.18.95.46,1.34.82l-5.97,5.98Zm.03-.93l.72-.73c-.11-.15-.22-.32-.34-.5-.11-.18-.22-.37-.31-.57-.09-.2-.17-.41-.24-.62-.06-.21-.11-.43-.13-.66-.19.51-.25,1.05-.19,1.58.05.52.22,1.03.48,1.49h0Zm1.23-1.21l2.58-2.6c-.3-.24-.63-.44-.98-.59-.3-.13-.62-.23-.95-.28-.27-.05-.54-.05-.8,0-.2.03-.39.12-.54.26-.14.15-.22.34-.24.54-.03.26-.03.53.03.79.06.32.16.63.3.92.16.35.37.67.6.97h0Zm3.05-3.08l.76-.72c-.45-.29-.97-.47-1.5-.51-.54-.05-1.08.03-1.59.23.21.02.42.06.62.13.21.06.41.14.61.23.2.09.39.19.58.3.18.11.36.23.53.36h0Z',
  }),
  'trough': () => ({
    iconFill: 'black',
    fill: Colors.waiting,
    path: 'm6.87,9.86c.09,0,.19-.02.27-.06.06-.04.1-.11.09-.18,0-.07-.03-.14-.09-.19-.09-.05-.18-.07-.28-.07-.34,0-.66-.11-.95-.29-.35-.23-.57-.61-.61-1.02-.01-.06-.05-.12-.1-.16-.05-.04-.11-.06-.17-.06-.07,0-.15.03-.19.09-.04.05-.06.12-.04.19.06.53.35,1.01.79,1.33.38.26.82.4,1.28.41Zm.01,1.4c-.95.02-1.87-.35-2.53-1.03-.67-.68-1.04-1.61-1.01-2.56.05-.86.35-1.69.88-2.38.76-1.04,1.66-1.97,2.66-2.78,1.01.81,1.9,1.74,2.66,2.78.53.69.83,1.52.88,2.38.03.95-.34,1.88-1.01,2.56-.66.68-1.58,1.05-2.53,1.03Zm0-.66c.78.02,1.52-.28,2.06-.84.55-.56.84-1.32.82-2.09-.05-.71-.3-1.39-.74-1.96-.61-.86-1.33-1.64-2.14-2.32-.81.68-1.53,1.46-2.14,2.32-.43.57-.69,1.25-.74,1.96-.02.78.27,1.53.82,2.09.54.55,1.28.86,2.06.84h0Z',
  }),
  'feeder': () => ({
    iconFill: 'black',
    fill: Colors.waiting,
    path: 'm5.03,10.8v-3.6c-.36-.07-.69-.25-.94-.51-.26-.27-.39-.62-.39-.97v-2.74h.63v2.74h.69v-2.74h.63v2.74h.69v-2.74h.63v2.74c0,.36-.13.71-.39.97-.24.26-.57.44-.94.51v3.6h-.63Zm4.39,0v-3.13h-1.22v-2.98c-.06-.89.67-1.66,1.63-1.71.07,0,.15,0,.22,0v7.82h-.64Z',
  }),
  'salt-trough': () => ({
    iconFill: 'black',
    fill: Colors.waiting,
    path: 'm6.88,11.26c-.95.02-1.87-.35-2.53-1.04-.67-.69-1.04-1.63-1.01-2.59h.66c-.02.79.27,1.55.82,2.12,1.14,1.13,2.98,1.13,4.12,0,.55-.57.84-1.33.82-2.12h.66c.03.97-.34,1.9-1.01,2.59-.66.69-1.58,1.06-2.53,1.04Zm-3.1-4.54c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Zm1.99,0c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Zm2.21,0c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Zm1.99,0c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Zm-5.2-1.22c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Zm4.21,0c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Zm-2.1-.11c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Zm-1.11-1.11c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Zm2.21,0c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Zm-1.11-1.11c-.09,0-.17-.03-.24-.1-.06-.06-.1-.15-.09-.24,0-.09.03-.17.1-.24.06-.06.15-.1.24-.09.09,0,.17.03.24.1.06.06.1.15.09.24,0,.09-.03.17-.1.24-.06.06-.15.1-.24.09h0Z',
  }),
  'gateway': () => ({
    iconFill: 'black',
    fill: Colors.secondary,
    path: 'm6.88,4.96c.3,0,.55.1.76.3.21.2.32.46.32.78,0,.45-.22.78-.65.99v3.77h-.87v-3.77c-.43-.2-.65-.53-.65-.99,0-.32.11-.58.32-.78s.46-.3.76-.3m2.03-.95c.54.54.83,1.22.87,2.03,0,.78-.29,1.46-.87,2.04l-.51-.53c.43-.39.65-.9.65-1.51,0-.58-.22-1.08-.65-1.51l.51-.52m-4.59-.53c-.69.69-1.03,1.54-1.03,2.56s.35,1.86,1.03,2.55l-.51.53c-.83-.88-1.26-1.91-1.26-3.08s.43-2.19,1.26-3.07l.51.51m5.62-.51c.83.88,1.26,1.9,1.26,3.07s-.43,2.24-1.26,3.08l-.51-.53c.69-.69,1.03-1.54,1.03-2.55s-.35-1.87-1.03-2.56l.51-.51m-5.09,1.03l.51.52c-.43.4-.65.9-.65,1.51,0,.58.22,1.08.65,1.51l-.51.53c-.58-.58-.87-1.26-.87-2.04s.29-1.45.87-2.03Z',
  }),
  'male': ({ fill, filter, borderColor }) => {
    return {
      viewBox: '0 0 12 12',
      path: `
        ${
          borderColor
            ? `<path fill="none" 
          stroke="${borderColor?.replace('#', '%23')}" 
          stroke-width="3" 
          d="m6,10.2c-.14.26-.48.36-.74.22-.09-.05-.17-.12-.22-.22L1.68,3.42C1.5,3.08,1.62,2.67,1.95,2.49,2.05,2.44,2.17,2.4,2.29,2.4h7.42c.38,0,.69.3.69.69,0,.12-.04.23-.09.34L6,10.2Z" 
        />`
            : ''
        }
        <path fill="${fill?.replace('#', '%23')}" 
          filter="${borderColor ? 'none' : filter}" 
          stroke="white" 
          stroke-width="0.3" 
          d="m6,9.54c-.11.21-.36.28-.57.17-.06-.04-.14-.1-.17-.17L2.29,3.42c-.18-.34-.06-.75.27-.93.1-.05.22-.09.33-.09h6.22c.38,0,.69.3.69.69,0,.12-.04.23-.09.34L6,9.54Z" 
        />`,
    }
  },
  'female': ({ fill, filter, borderColor }) => {
    return {
      viewBox: '0 0 12 12',
      path: `
      	${
          borderColor
            ? `
        <circle
        	fill="none"
        	stroke="${borderColor?.replace('#', '%23')}"
        	stroke-width="1.5"
        	cx="6"
        	cy="6"
        	r="4.2"
      	/>`
            : ''
        }
      	<circle
        	fill="${fill?.replace('#', '%23')}"
        	filter="${borderColor ? 'none' : filter}"
        	stroke="white"
        	stroke-width="0.3"
        	cx="6"
        	cy="6"
        	r="3.2"
      	/>`,
    }
  },
  'lake-dam-weir': () => ({
    iconFill: 'black',
    fill: Colors.waiting,
    path: 'm7.96,11.17c-1.15,0-2.21-.42-2.83-1.13-.39-.44-.57-.96-.53-1.49s.32-.92.69-1.36c.25-.3.31-.51.28-.6-.04-.11-.24-.2-.36-.22-.79,0-1.48-.28-1.85-.73-.2-.23-.29-.55-.25-.87.12-.79,1.09-1.38,2.28-1.38s2.13.61,2.14,1.43c0,.29-.1.57-.28.79-.22.26-.26.44-.24.48,0,0,.08.19.81.21,1.75.05,3.16.99,3.34,2.22.08.55-.08,1.1-.44,1.51-.57.71-1.6,1.13-2.75,1.13h0Zm-2.57-7.12c-.91,0-1.61.44-1.67.83-.01.13.03.25.11.34.24.3.79.5,1.4.5h.05c.07.01.68.13.86.65.12.36-.01.79-.4,1.25-.31.37-.51.64-.53.97-.01.37.12.73.37.99.51.58,1.39.92,2.38.92s1.83-.34,2.28-.91c.23-.26.34-.62.3-.98-.13-.91-1.31-1.62-2.75-1.67-.76-.02-1.21-.22-1.36-.61-.13-.34-.01-.75.35-1.17.08-.09.13-.22.13-.34,0-.37-.63-.78-1.52-.78h0Zm4.17.1l-.49-1.25-.44,1.25h.31v.39h.31v-.39h.31Zm1.17,1.8l-.68-1.71-.6,1.71h.43v.54h.42v-.54h.43Zm-6.54,2.41l-.68-1.71-.6,1.71h.43v.54h.42v-.54h.43Zm.53,2.18l-.55-1.39-.49,1.39h.35v.44h.34v-.44h.35Zm3.91-5.06l-.45-1.14-.4,1.14h.29v.36h.28v-.36h.29,0Zm-.41,4.69c-.14,0-.26-.12-.26-.27,0-.15.11-.27.26-.27h0c.78,0,1.46-.4,1.46-.86,0-.12-.05-.24-.12-.33-.09-.12-.07-.29.04-.38.11-.09.27-.07.36.04h0c.15.19.23.43.23.67,0,.78-.87,1.4-1.98,1.4h0Z',
  }),
  'default': () => ({
    iconFill: 'black',
    fill: Colors.muted,
    path: 'M7,10c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5',
  }),
}

export const getDefaultPin = (color, width, height) => {
  return `data:image/svg+xml;utf-8, 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 14 21" width="${width}" height="${height}">
      <path fill="${color?.replace(
        '#',
        '%23',
      )}" d="M7,0C3.13,0,0,3.13,0,7c0,5.25,7,13,7,13,0,0,7-7.75,7-13C14,3.13,10.87,0,7,0Z" stroke="white" stroke-width="0.3"/>
      <path fill="white" d="M7,10c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5-1.12,2.5-2.5,2.5"/>
  </svg>`
}

export const pinAnimal = (fill, width, height) => {
  return `data:image/svg+xml;utf-8, 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.01 13.77"  width="${width}" height="${height}">
    <path fill="${fill?.replace('#', '%23')}" filter="${shadow}" stroke="white" stroke-width="0.3"
    d="m5.51,13.77c-1.85-1.57-3.23-3.03-4.14-4.38C.46,8.04,0,6.79,0,5.64,0,3.92.55,2.55,1.66,1.53,3.82-.51,7.2-.51,9.35,1.53c1.11,1.02,1.66,2.39,1.66,4.11,0,1.15-.46,2.4-1.37,3.74-.91,1.35-2.29,2.81-4.14,4.38Z"/>
    <path fill="white" d="m4.86,7.4c.09,0,.16.07.16.16s-.07.16-.16.16-.16-.07-.16-.16.07-.16.16-.16h0m.99,0c.09,0,.16.07.16.16s-.07.16-.16.16-.16-.07-.16-.16.07-.16.16-.16h0m-1.15-2.31c.18,0,.33.15.33.33s-.15.33-.33.33-.33-.15-.33-.33.15-.33.33-.33h0m1.32,0c.18,0,.33.15.33.33s-.15.33-.33.33-.33-.15-.33-.33.15-.33.33-.33h0m1.32,2.3c0,.73-.89,1.32-1.98,1.32s-1.98-.59-1.98-1.32c.01-.31.16-.6.4-.79-.26-.34-.4-.76-.4-1.19l.04-.4c-.19.05-.38.05-.57,0-.33-.09-.84-.47-.77-.73s.71-.31,1.04-.21c.2.06.39.19.52.35l.19-.27c-.25-.67.09-1.41.76-1.66.03-.01.06-.02.1-.03l-.03.05h0c-.22.33-.25.74-.08,1.1.49-.21,1.04-.21,1.53,0,.17-.35.14-.77-.08-1.1l-.03-.05c.69.2,1.09.91.89,1.6,0,.03-.02.06-.03.1l.19.27c.14-.16.32-.28.52-.35.34-.1.96-.05,1.04.21s-.43.64-.77.73c-.19.05-.38.05-.57,0l.04.4c0,.43-.14.84-.4,1.19.24.19.38.48.4.79m-1.98-.66c-.73,0-1.32.3-1.32.66s.59.66,1.32.66,1.32-.3,1.32-.66-.59-.66-1.32-.66m0-.66c.35,0,.69.06,1.01.18.2-.24.31-.53.31-.84.01-.73-.57-1.33-1.3-1.34-.73-.01-1.33.57-1.34,1.3,0,.01,0,.03,0,.04,0,.31.11.61.31.84.32-.12.67-.19,1.01-.18h0Z"/>
  </svg>`
}
export const pinPositionOffAnimal = (fill, outFill, width, height) => {
  return `data:image/svg+xml;utf-8, 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="${width}" height="${height}">
  <path fill="${fill?.replace(
    '#',
    '%23',
  )}" d="M12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4Z" />
    <path fill="${outFill?.replace(
      '#',
      '%23',
    )}" filter="${shadow}" d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
  </svg>`
}

export const pinImage = (type, active, marker, width, height) => {
  const MarkersSwitch = switchFn(MarkersTypes, 'default')

  if (type === 'pinAnimal') {
    return pinAnimal(Colors.primary, width, height)
  }
  if (type === 'pin') {
    return getDefaultPin(marker.color ?? Colors.primary, width, height)
  }

  if (type === 'pinAnimalPositionOff') {
    return pinPositionOffAnimal(
      marker.color ?? Colors.primary,
      marker.secundaryColor ?? Colors.primary,
      width,
      height,
    )
  }

  if (type === 'device') {
    const { path, viewBox } = MarkersSwitch(marker.sex, {
      fill: active ? marker.color : Colors.gray_medium,
      filter: shadow,
      borderColor: marker.map_status,
    })
    return `data:image/svg+xml;utf-8,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="${
      viewBox || '0 0 9.41 8.49'
    }"  width="${width}" height="${height}">
      ${path}
    </svg>`
  }

  const { fill, iconFill, path } = MarkersSwitch(type, active)

  return `data:image/svg+xml;utf-8, 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.77 13.77"  width="${width}" height="${height}">
    <circle fill="${fill?.replace(
      '#',
      '%23',
    )}" cx="6.88" cy="6.88" r="6.88" filter="${shadow}" stroke="white" stroke-width="0.3"/>
    <path fill="${iconFill?.replace('#', '%23')}" d="${path}"/>
  </svg>`
}
