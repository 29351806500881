import { Form, Input, Col } from 'antd'
import { Translate } from 'lang'
import toLower from 'lodash/toLower'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

/**
 * @function PasswordForm - Form Radio
 * @param {string} title - texto
 * @param {boolean} disabled
 * * */
export const PasswordForm = ({ value }) => {
  const { name, label, disabled, rules = [], required, placeholder, cols = { xs: 24 } } = value

  const defaultPlaceholder = Translate({
    messageKey: 'inform',
    gender: 'female',
    items: {
      item: toLower(label),
    },
  })

  return (
    <Col {...cols}>
      <Form.Item name={name} label={label} rules={[...rules, { required }]} style={style.form}>
        <Input.Password placeholder={placeholder ?? defaultPlaceholder} disabled={disabled} />
      </Form.Item>
    </Col>
  )
}
