import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import HttpService from 'store/services/requestApi'
import { endpoint } from 'store/services/endpoints'
import tryCache from 'utils/trycache'
import Utils from 'utils'
import { isDev } from 'utils/environment'

const defaultState = {
  list: [],
  expires: 0,
}

export const getPermissions = createAsyncThunk('permissions', async (_, thunkAPI) => {
  const state = thunkAPI.getState()
  if (!tryCache.isExpired(state?.permissions?.expires)) {
    // Retorna permissões em cache
    return state.permissions
  }

  try {
    let url = endpoint.permissions()
    const { data } = await HttpService.get(url)
    const expires = tryCache.expiresIn(isDev ? 0.1 : 5)

    return {
      list: Utils.objectToArray(data, 'slug', 'permission'),
      expires,
    }
  } catch (e) {
    throw new Error(e.message)
  }
})

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: defaultState,
  reducers: {
    resetPermissions() {
      return defaultState
    },
  },
  extraReducers: builder => {
    builder.addCase(getPermissions.fulfilled, (_, action) => action?.payload)
  },
})

export const { resetPermissions } = permissionsSlice.actions

// State selectors
export const getUserPermissions = state => state?.permissions?.list

export default permissionsSlice.reducer
