import { Button, Drawer, Row, message } from 'antd'
import Loading from 'components/Loading'
import NotificationMap from 'components/Notifications/Details/Map'
import NotificationTags from 'components/Notifications/Details/Utils/Tags'
import NotificationText from 'components/Notifications/Details/Utils/Text'
import NotificationTitle from 'components/Notifications/Details/Utils/Title'
import NotificationAlert from 'components/Notifications/Manipulate/Alert'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentFarm } from 'redux/reducers/Farm'
import axiosInstance from 'store/services/axiosConfig'
import { endpoint } from 'store/services/endpoints'
import styled from 'styled-components'
import Modal from 'components/Modal'
import { Translate } from 'lang'
import MovementBatchForm from 'components/Notifications/Details/Forms/lots'
import NotificationMessage from 'components/Notifications/Details/Utils/Message'
import { isEmpty, isEqual } from 'lodash'
import { usePermissions } from 'store/hooks/usePermissions'
import ModalForm from 'components/Modal/ModalForm'

const Box = styled(`div`)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`

export default function NotificationDetails({ notification, onClose }) {
  const { farm, property } = useSelector(getCurrentFarm, isEqual)
  const { gestaoDeRebanhoManagement } = usePermissions(['gestao-de-rebanho:management'])

  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [id, setId] = useState(notification)
  const [actionsDialog, setActionsDialog] = useState({ visible: false })
  const [responseModal, setResponseModal] = useState()

  useEffect(() => {
    if (window.location.hash) {
      const [key, value] = window.location.hash.split('=')
      if (key === '#notification' && value) {
        setId(value)
      }
    }
  }, [window.location.hash])

  const handleClose = () => {
    setId(null)
    setOpen(false)
    onClose && onClose()
    if (window.location.hash && window.location.hash.includes('#notification')) {
      window.location.hash = ''
    }
  }

  const controller = endpoint.farm.notifications.self({ farm, property, notification: id })
  const controllerModal = endpoint.farm.notifications.form.zero_steps_alert({
    farm,
    property,
    notification: id,
  })

  useEffect(() => {
    if (id || refetch) {
      setOpen(true)
      axiosInstance
        .get(controller)
        .then(({ data }) => {
          data?.map(setData(data))
          setRefetch(false)
        })
        .catch(() => {
          setRefetch(false)
        })
    }
  }, [id, refetch])

  const handleAction = notification => {
    const url = `/farm/${farm}/property/${property}/herd/animals/${data?.animals?.[0]?.animal}`
    switch (notification.type) {
      case 'plot_alert':
        const controller = {
          batch: endpoint.farm.batch.logs.self({
            farm,
            property,
            batch: notification?.lots?.[0].slug,
          }),
          list: endpoint.farm.piquete.getPlots({ farm, property }),
        }
        setActionsDialog({
          visible: true,
          width: 'sm',
          title: (
            <>
              <p style={{ margin: 0 }}>
                {Translate({
                  messageKey: 'change_of',
                  items: {
                    item: Translate({ messageKey: 'lots' }),
                  },
                })}
              </p>
              <span style={{ fontSize: '12px', padding: 0, fontWeight: 'normal' }}>
                {data.title}
              </span>
            </>
          ),

          content: (
            <MovementBatchForm
              controller={controller}
              notification_id={id}
              setActionsDialog={setActionsDialog}
              plot={data.title}
              refetch={() => setRefetch(true)}
            />
          ),
          onClose: () => {
            setActionsDialog({ visible: false })
          },
        })
        break

      case 'external_alert':
      case 'possible_illness':
      case 'zero_steps_alert':
      case 'possible_birth':
      case 'fall_device_alert':
        window.location.replace(url)
        break

      default:
        break
    }
  }

  // Função para criar um botão com texto e estilo definidos
  const createButton = (text, onClick, disabled, type = 'default') => (
    <Button type={type} disabled={disabled} style={{ marginTop: 'auto' }} onClick={onClick}>
      {text}
    </Button>
  )

  // Função para criar o botão de ação baseado no estado de resposta
  const createActionButton = (isAnswered, setOpenModal) =>
    createButton(
      Translate({ messageKey: isAnswered ? 'edit_information' : 'record_information' }),
      () => setOpenModal(true),
      false,
      isAnswered ? 'default' : 'primary',
    )

  // Função para criar o botão de ação específico para o caso de gestão de rebanho
  const createManagementButton = (handleAction, data) =>
    createButton(
      Translate({
        messageKey: 'change_of',
        items: { item: Translate({ messageKey: 'lots' }) },
      }),
      () => handleAction(data),
      false,
    )

  const footer = {
    external_alert: disabled => (
      <Row sx={24} style={{ justifyContent: 'space-around' }}>
        {createButton(
          Translate({ messageKey: 'go_to_animal' }),
          () => handleAction(data),
          disabled,
        )}
        {createActionButton(data?.is_answered, setOpenModal)}
      </Row>
    ),
    plot_alert: () => gestaoDeRebanhoManagement && createManagementButton(handleAction, data),
    zero_steps_alert: () => (
      <Row sx={24} style={{ justifyContent: 'space-around' }}>
        {createButton(Translate({ messageKey: 'go_to_animal' }), () => handleAction(data))}
        {createActionButton(data?.is_answered, setOpenModal)}
      </Row>
    ),
    possible_illness: () => (
      <Row sx={24} style={{ justifyContent: 'space-around' }}>
        {createButton(Translate({ messageKey: 'go_to_animal' }), () => handleAction(data))}
        {!data?.is_answered && createActionButton(data?.is_answered, setOpenModal)}
      </Row>
    ),
    possible_birth: () => (
      <Row sx={24} style={{ justifyContent: 'space-around' }}>
        {createButton(Translate({ messageKey: 'go_to_animal' }), () => handleAction(data))}
        {!data?.is_answered && createActionButton(data?.is_answered, setOpenModal)}
      </Row>
    ),
    fall_device_alert: () => (
      <Row sx={24} style={{ justifyContent: 'space-around' }}>
        {createButton(Translate({ messageKey: 'go_to_animal' }), () => handleAction(data))}
        {!data?.is_answered && createActionButton(data?.is_answered, setOpenModal)}
      </Row>
    ),
  }

  const onSubmitModal = () => {
    axiosInstance.put(controllerModal, responseModal).then(() => {
      message.success(
        Translate({
          messageKey: 'response_sent_successfully',
        }),
      )
    })
  }

  function isAlertConnection(type) {
    return ['disconnect_alert', 'connection_alert'].includes(type)
  }

  return (
    <Drawer onClose={handleClose} open={open} width={500}>
      {isEmpty(data) ? (
        <Loading cover="content" />
      ) : (
        <Box>
          <NotificationAlert {...data} withBackground={false} />
          {isAlertConnection(data.type) ? null : <NotificationMap {...data} />}
          <NotificationTags {...data} />
          <NotificationTitle {...data} />
          <NotificationText {...data} />
          <NotificationMessage {...data} />
          {footer[data?.type]?.(!isEmpty(data?.infos?.filter(i => i.type === 'message')))}
          {data.infos ? (
            <ModalForm
              open={openModal}
              handleCancel={() => setOpenModal(false)}
              handleSubmit={onSubmitModal}
              response={responseModal}
              setResponse={setResponseModal}
              title={
                <>
                  <p style={{ margin: 0 }}>
                    {data?.notification_form_title_alert ?? 'Registrar Opções'}
                  </p>
                  <span style={{ fontSize: '12px', padding: 0, fontWeight: 'normal' }}>
                    {data.title}
                  </span>
                </>
              }
              data={data}
              controller={controllerModal}
              refresh={setRefetch}
            />
          ) : null}
          <Modal actionsDialog={actionsDialog} />
        </Box>
      )}
    </Drawer>
  )
}
