export default function getCenter(paths) {
  if (!paths) return null
  // Verifica se 'paths' é um array
  const coordinates = Array.isArray(paths) ? paths.map(path => path.coordinates).flat() : paths

  // Verifica se não há coordenadas disponíveis
  if (coordinates.length === 0) {
    // Retorna coordenadas padrão { lat: 0, lng: 0 }
    return { lat: 0, lng: 0 }
  }

  // Calcula a soma das coordenadas usando o método 'reduce'
  const sumOfCoordinates = coordinates.reduce(
    (prev, current) => {
      return {
        lng: prev.lng + current.lng,
        lat: prev.lat + current.lat,
      }
    },
    { lat: 0, lng: 0 },
  )

  // Calcula as coordenadas centrais dividindo a soma pelo número de coordenadas
  const centerCoordinates = {
    lat: sumOfCoordinates.lat / coordinates.length,
    lng: sumOfCoordinates.lng / coordinates.length,
  }

  // Retorna as coordenadas centrais
  return centerCoordinates
}
