import { Form, Input, Col } from 'antd'
import { Translate } from 'lang'
import toLower from 'lodash/toLower'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
}

/**
 * @function TextForm - Form Radio
 * @param {string} title - texto
 * @param {boolean} disabled
 * * */
export const TextForm = ({ value, allValues }) => {
  const {
    name,
    label,
    disabled,
    rules = [],
    required,
    placeholder,
    visible = () => true,
    cols = { xs: 24 },
  } = value
  const defaultPlaceholder = Translate({
    messageKey: 'inform',
    gender: 'male',
    items: {
      item: toLower(label),
    },
  })

  const hide = () => {
    if (!visible(allValues)) {
      return { style: { display: 'none' } }
    }
    return {}
  }

  return (
    <Col {...cols} {...hide()}>
      <Form.Item
        name={name}
        label={label}
        rules={[...rules, { required }]}
        style={{ ...style.form }}
      >
        <Input placeholder={placeholder ?? defaultPlaceholder} disabled={disabled} />
      </Form.Item>
    </Col>
  )
}
