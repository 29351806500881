import { Form, Col, Switch } from 'antd'
import { validateField } from 'components/FormBuilder/assets/validate'
import { useEffect, useState } from 'react'
import '../assets/switch.css'

/**
 * @function SwitchForm - Form Switch
 * @param {string} label - texto
 * @param {boolean} checked
 * @param {boolean} disabled
 * @param {required} disabled
 * * */
export const SwitchForm = ({ value, allValues }) => {
  const { name, label, rules = [], cols = { xs: 24 }, condition = () => true, disabled } = value

  const [enabled, setEnabled] = useState(true)
  const [required, setRequired] = useState(false)

  useEffect(() => {
    const isEnabled = !disabled && condition(allValues)
    const isRequired = validateField(allValues, value.required)
    setEnabled(isEnabled)
    setRequired(isRequired && isEnabled)
  }, [allValues, setEnabled, setRequired])

  return (
    <Col {...cols}>
      <Form.Item
        name={name}
        label={label}
        rules={[...rules, { required }]}
        valuePropName={'checked'}
        disabled={!enabled}
        className="switch-row"
      >
        <Switch style={{ marginRight: 20 }} />
      </Form.Item>
    </Col>
  )
}
