import React from 'react'
import PropTypes from 'prop-types'
import { LOGO, LOGO_TEXT } from 'constants/ThemeConstant'

const Loading = props => {
  const { align, cover, logoText } = props
  return (
    <div className={`loading text-${align} cover-${cover}`}>
      <div>
        <div style={{ textAlign: 'center' }}>
          <img src={LOGO} style={{ animation: `spin 2s linear infinite` }} alt="Instabov logo" />
        </div>
        {logoText && (
          <div>
            <img src={LOGO_TEXT} alt="Instabov text" />
          </div>
        )}
      </div>
    </div>
  )
}

Loading.propTypes = {
  cover: PropTypes.string,
  align: PropTypes.string,
}

Loading.defaultProps = {
  align: 'center',
  cover: 'inline',
}

export default Loading
