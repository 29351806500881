import React, { useEffect, useMemo, useState } from 'react'
import Maps from 'components/Maps'
import Markers from 'components/Maps/Markers'
import Polylines from 'components/Maps/Polyline'
import { PolygonDefaultOptions, Polygons } from 'components/Maps/ManipulateMap/utils'
import Loading from 'components/Loading'
import { isEqual, uniqueId } from 'lodash'
import { Colors } from 'constants/Colors'
import { useSelector } from 'react-redux'
import { getCurrentProperty } from 'redux/reducers/Farm'
import getCenter from 'components/Maps/ManipulateMap/getCenter'

const mapHeight = 300

function NotificationMap({ animals, limits, lots, type }) {
  const currentProperty = useSelector(getCurrentProperty, isEqual)

  const { lat, lng } = animals[0].positions.current ?? currentProperty

  const [center, setCenter] = useState({ lat, lng })
  const options = lot => ({
    ...PolygonDefaultOptions,
    fillColor: lot.color,
  })

  useEffect(() => {
    if (type === 'external_alert' || type === 'internal_alert') {
      setCenter(animals?.[0]?.positions?.current)
    }
    if (type === 'plot_alert') {
      setCenter(getCenter(lots))
    }

    return () => {}
  }, [type])

  const MarkersAnimals = useMemo(() => {
    if (animals) {
      return animals?.map(animal => {
        return (
          <React.Fragment key={uniqueId()}>
            <Markers markers={[{ ...animal, ...animal?.positions?.current, type: 'pinAnimal' }]} />
            <Markers
              markers={[
                { ...animal, ...animal?.positions?.old, type: 'pin', color: Colors.warning },
              ]}
            />
          </React.Fragment>
        )
      })
    }
  }, [animals])

  const FarmBoundary = useMemo(() => {
    if (limits) {
      return <Polylines paths={limits} />
    }
  }, [limits])

  const Piquete = useMemo(() => {
    if (lots) {
      return <Polygons lots={lots} options={lot => options(lot)} />
    }
  }, [lots])

  return (
    <div style={{ minHeight: mapHeight }}>
      {!limits ? (
        <div>
          <Loading cover="content" />
        </div>
      ) : (
        <Maps style={{ minHeight: mapHeight }} center={center} zoom={16}>
          {Piquete}
          {MarkersAnimals}
          {FarmBoundary}
        </Maps>
      )}
    </div>
  )
}

export default NotificationMap
