import React from 'react'
import { APP_NAME } from 'configs/AppConfig'
import { Translate } from 'lang'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  font-size: 11px;
  height: 30px !important;
`

export default function Footer() {
  const version = `Version: ${process.env.REACT_APP_VERSION ?? 1}`

  return (
    <>
      <StyledFooter className="footer">
        <span>
          Copyright &copy; {`${new Date().getFullYear()}`}{' '}
          <span className="font-weight-semibold">{`${APP_NAME}`}</span>
          {'. '}
          <Translate messageKey={'copyright'} />
        </span>
        <span>{version}</span>
      </StyledFooter>
    </>
  )
}
