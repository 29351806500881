import { useEffect, useState } from 'react'
import { Col, Form } from 'antd'
import DrawingMap from 'components/Maps/Drawing'

export const DrawingForm = ({ value, form, initialValues, height, name, center }) => {
  const { cols = { xs: 24 }, bottom } = value
  const [color, setColor] = useState(null)
  const [coordinates, setCoordinates] = useState(null)

  useEffect(() => {
    const timer = setInterval(() => setColor(form.getFieldValue('color')), 100)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (coordinates) {
      if (name) {
        form.setFieldsValue({ [name]: coordinates })
      } else {
        form.setFieldsValue({ coordinates })
      }
    }
  }, [coordinates])

  return (
    <Col {...cols}>
      <Form.Item name={name ?? 'coordinates'}>
        <DrawingMap
          color={color}
          setCoordinates={setCoordinates}
          paths={initialValues?.coordinates}
          additional_lots={initialValues?.additional_lots}
          property_limits={initialValues?.property_limits}
          name={initialValues?.name}
          bottom={bottom}
          height={height}
          center={center}
        />
      </Form.Item>
    </Col>
  )
}
