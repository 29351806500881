const LSKEY = 'try-cache'

/**
 * Test if it's a valid key
 *
 * @param {string} str - The key string
 *
 * @returns {boolean} The test result
 * */
function isValidKey(str) {
  return typeof str === 'string' && str.length > 0
}

/**
 * Calculates de expiration time in ms
 *
 * @param {number} minutes - Expiration amount
 *
 * @returns {number} Time in millis
 * */
export function expiresIn(minutes = 0) {
  let base = Date.now()
  if (minutes) base += minutes * 60 * 1000
  return base
}

/**
 * Tests if given time (ms) already expired
 *
 * @param {number} expiration - Expiration date in ms
 *
 * @returns {boolen} Result of the test
 * */
export function isExpired(expiration) {
  if (typeof expiration !== 'number') return true
  return expiration < Date.now()
}

/**
 * Gets a cached item from localStorage
 *
 * @param {string} key - The cached item key
 *
 * @returns {any} The value cached or null
 * */
export function get(key) {
  if (!isValidKey(key)) return

  const [expire, item] = JSON.parse(localStorage.getItem(`${LSKEY}:${key}`)) ?? []

  if (typeof item === 'undefined') return

  return isExpired(expire) ? null : item
}

/**
 * Clears a cached item in localStorage
 *
 * @param {string} key - The cached item key
 *
 * @returns {undefined} Nothing
 * */
export function clear(key) {
  if (!isValidKey(key)) return

  localStorage.removeItem(`${LSKEY}:${key}`)
}

/**
 * Gets all tryCache stored keys
 *
 * @returns {Array} List of keys
 * */
export function getKeys() {
  const { length } = localStorage
  const keys = []

  for (let i = 0; i < length; i++) {
    const key = localStorage.key(i)
    if (key && key.startsWith(LSKEY)) {
      keys.push(key)
    }
  }
  return keys
}

/**
 * Clears all expired items in localStorage
 *
 * @returns {number} Number of removed items
 * */
export function clearExpired() {
  const keys = getKeys()

  const toRemove = keys.filter(key => {
    const [expires] = JSON.parse(localStorage.getItem(key))
    return isExpired(expires)
  })
  toRemove.forEach(key => localStorage.removeItem(key))
  return toRemove.length
}

/**
 * Clears all cached items in localStorage
 *
 * @returns {number} Number of removed items
 * */
export function clearAll() {
  const keys = getKeys()
  keys.forEach(key => localStorage.removeItem(key))
  return keys.length
}

/**
 * Sets a cached item in localStorage
 *
 * @param {string} key - The cached item key
 * @param {any} value - Value to be cached
 * @param {number} minutes - Expiration time
 *
 * @returns {any} The value cached
 * */
export function set(key, value, minutes) {
  if (!isValidKey(key)) return
  try {
    localStorage.setItem(`${LSKEY}:${key}`, JSON.stringify([expiresIn(minutes), value]))
    return value
  } catch (e) {
    console.info('Local Storage is full, cleaning and trying again')
    clearAll()
    set(key, value, minutes)
  }
}

const tryCache = {
  set,
  get,
  getKeys,
  clear,
  clearAll,
  clearExpired,
  expiresIn,
  isExpired,
}

export default tryCache
