import axios from 'axios'
import getUrlBase from 'utils/getUrlBase'
import Storage from 'utils/storage'
import UserManager from 'utils/userManager'

// Configuração da instância do Axios com interceptadores
const axiosInstance = axios.create()

// request interceptor to add token to request headers
axiosInstance.interceptors.request.use(
  async config => {
    const token = JSON.parse(localStorage.getItem('instabov_token'))

    if (!config.url.includes('http') && token) {
      config.headers = {
        'authorization': `Bearer ${token}`,
        'accept-language': UserManager.get('locale') ?? 'pt-BR',
      }
      config.baseURL = process.env.REACT_APP_API_URL
    }
    return config
  },
  error => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
  response => {
    response.code = 200
    response.status = 'success'
    return response
  },
  error => {
    switch (error?.response?.status) {
      case 401:
        Storage.clear()
        window.location.replace('/')
        break

      case 400:
        if (error?.response?.data?.message === 'user does not Root') {
          window.location.replace(getUrlBase())
        } else {
          return Promise.reject({
            ...error?.response,
            data: error?.response?.data,
            code: error?.response?.status,
            status: 'error',
          })
        }
        break

      default:
        const reason = error?.response?.data?.errors?.[0]?.message
        if (reason) {
          Promise.reject(reason)
        }
        return {
          ...error?.response,
          data: error?.response?.data,
          code: error?.response?.status,
          status: 'error',
        }
    }
    return Promise.reject(error?.response)
  },
)

export default axiosInstance
