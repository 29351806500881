import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Storage from './storage'

export default function useStorage(key) {
  const history = useHistory()
  const [state, setState] = useState(() => Storage.get(key))

  useEffect(() => {
    history.listen(() => setTimeout(async () => setState(Storage.get(key)), 100))
  }, [history])

  const set = useCallback(
    newValue => {
      Storage.set(key, newValue)
      setState(newValue)
    },
    [key],
  )

  const remove = useCallback(() => {
    Storage.remove(key)
    setState(undefined)
  }, [key])

  return [state, set, remove]
}
