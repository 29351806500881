import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
  SIDE_NAV_COLLAPSED_WIDTH,
} from 'constants/ThemeConstant'

export const APP_NAME = 'Instabov'
export const APP_PREFIX_PATH = '/app'

export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  sideNavWidth: SIDE_NAV_COLLAPSED_WIDTH,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  mobileNav: false,
  currentTheme: 'dark',
  direction: DIR_LTR,
}
