import styled from 'styled-components'

const Wrapper = styled.div`
  border-radius: 2px;
  margin: 10px 60px 10px 10px;
  padding: 0 10px;
  background: white;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
`

export default function Bottom({ children }) {
  return <Wrapper>{children}</Wrapper>
}
