import { useEffect } from 'react'
import axios from 'store/services/axiosConfig'
import AXIOS, { configure } from 'axios-hooks'
import LRU from 'lru-cache'
import qs from 'qs'

const cache = new LRU({ max: 100, ttl: 500 })

configure({ axios, cache })

const useAxios = ({ controller, method = 'GET', payload, useCache = true }) => {
  const axiosRequest = AXIOS
  let params = payload
  let url = controller

  if (method?.toLowerCase() === 'get' && payload) {
    params = qs.stringify(payload)
    url = `${controller}?${params}`
  }

  if (url) {
    const abortController = new AbortController()
    const [{ data, loading, error }, refetch] = axiosRequest(
      { data: params, url, method, signal: abortController.signal },
      { useCache },
    )

    useEffect(() => {
      return () => {
        abortController.abort()
      }
    }, [url, method, params])

    const { meta, summary, filters } = data ?? {}

    let formattedData = meta ? data?.data : data
    if (formattedData === '') {
      formattedData = null
    }
    return {
      data: formattedData,
      filters,
      meta,
      summary,
      loading,
      error,
      refetch,
    }
  }
  return {}
}

export default useAxios
