import React, { useState } from 'react'
import { Button, Col, Row, List, Modal } from 'antd'
import { EyeOutlined, CopyOutlined } from '@ant-design/icons'

const ShowMore = ({ value, header }) => {
  const [open, setOpen] = useState(false)
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  if (!value) return '-'

  return (
    <>
      <Button shape="circle" icon={<EyeOutlined />} onClick={showDrawer} />
      <Modal title={header} footer={null} open={open} onCancel={onClose}>
        <Row>
          <Col span={24}>
            <List
              dataSource={value}
              renderItem={({ name, type, value }) => {
                return (
                  <List.Item>
                    <p
                      style={{
                        overflowWrap: 'anywhere',
                      }}
                    >
                      <strong style={{ textTransform: 'uppercase' }}>{`${name}: `}</strong>
                      {type === 'link' ? <a href={value}>{value}</a> : value}
                    </p>
                    {type === 'copy' ? (
                      <Button
                        type="ghost"
                        size="small"
                        shape="circle"
                        icon={<CopyOutlined />}
                        onClick={() => navigator.clipboard.writeText(value)}
                      />
                    ) : null}
                  </List.Item>
                )
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default ShowMore
